@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7-video-card {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative; }
  @media (min-width: 850px) {
    .template--7-video-card {
      flex-flow: row wrap; } }
  .template--7-video-card:hover {
    cursor: pointer; }
    .template--7-video-card:hover .background-hover-effect {
      left: 0; }
    .template--7-video-card:hover .player-hover {
      opacity: 1;
      visibility: visible; }
      .template--7-video-card:hover .player-hover:before {
        width: 100%;
        opacity: .8; }
  .template--7-video-card .video-infos {
    width: 75%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 1rem;
    z-index: 5;
    position: relative;
    background-color: var(--c-primary);
    color: #fff; }
    @media (min-width: 850px) {
      .template--7-video-card .video-infos {
        width: 300px;
        position: absolute;
        right: 20%;
        bottom: -20px; } }
    .template--7-video-card .video-infos .video-subtitle {
      padding: 15px 0;
      margin: 0;
      z-index: 1;
      font-family: "Cabin", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
      text-align: center; }
      @media (min-width: 850px) {
        .template--7-video-card .video-infos .video-subtitle {
          padding: 0; } }
    .template--7-video-card .video-infos .video-btn {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 20px;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      z-index: 1; }
    .template--7-video-card .video-infos .icon-play-float {
      margin-right: 8px; }

.template--7-video-image {
  width: 75%;
  position: relative; }
  @media (min-width: 850px) {
    .template--7-video-image {
      width: 682px; } }
  .template--7-video-image:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4); }
  .template--7-video-image img {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover; }
    @media (min-width: 400px) {
      .template--7-video-image img {
        height: 300px; } }
    @media (min-width: 850px) {
      .template--7-video-image img {
        height: 418px; } }
  .template--7-video-image .player-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    transition: all .5s ease-in-out;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    opacity: 0;
    visibility: hidden; }
    .template--7-video-image .player-hover .icon-play-float-video {
      position: relative;
      z-index: 3; }
    .template--7-video-image .player-hover:before {
      content: '';
      width: 1%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      background: var(--c-secondary);
      mix-blend-mode: multiply;
      transition: all .5s ease-in-out; }
