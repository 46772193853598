@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7-contact {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 1rem;
  margin: 0 auto; }
  .template--7-contact .items-contact {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 30px 0; }
    @media (min-width: 650px) {
      .template--7-contact .items-contact {
        flex-flow: row wrap; } }
    @media (min-width: 800px) {
      .template--7-contact .items-contact {
        width: 60%; } }
    .template--7-contact .items-contact .item-contact {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      margin-bottom: 20px; }
      .template--7-contact .items-contact .item-contact:last-child {
        margin-bottom: 0; }
      @media (min-width: 650px) {
        .template--7-contact .items-contact .item-contact {
          margin-bottom: 0px; } }
      .template--7-contact .items-contact .item-contact p {
        margin: 0;
        margin-left: 10px;
        font-size: 18px;
        font-weight: normal;
        color: #6F6F6F; }
  .template--7-contact .message-form {
    padding: 15px;
    text-align: center;
    border-radius: 20px; }
    .template--7-contact .message-form.error {
      border: 1px solid red;
      color: red; }
    .template--7-contact .message-form.success {
      border: 1px solid green;
      color: green; }
