.focus, .body-modal .input-group input:focus, .body-modal .input-group label + input:focus, .custom-select:focus {
  outline: none;
  box-shadow: -2px 1px 1px rgba(0, 0, 0, 0.3); }

.body-modal .input-group {
  display: flex;
  flex-direction: column;
  padding: 7px 0px;
  color: #505152; }
  .body-modal .input-group label {
    font-size: .8em;
    font-weight: 400;
    text-transform: capitalize; }
    .body-modal .input-group label .fa {
      font-size: 1.2em; }
    @media (max-width: 768px) {
      .body-modal .input-group label {
        font-size: .6em; } }
  .body-modal .input-group input {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    color: #505152; }

.modal-configurations-content {
  height: 100%;
  overflow: auto;
  padding-bottom: 20px; }

.sector-divider {
  width: 100%;
  margin: 10px 0px;
  text-align: left;
  font-weight: bold;
  color: #505152; }
  .sector-divider:not(:first-child) {
    margin-top: 10px; }

.custom-select {
  height: 35px !important;
  padding: 10px;
  margin-top: 5px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  color: #505152; }

.no-edit input {
  background: #f2f2f2;
  cursor: copy; }

.configurations-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding-left: 5px; }
  @media (max-width: 400px) {
    .configurations-buttons {
      flex-flow: column wrap; }
      .configurations-buttons .button {
        width: 100%;
        margin-bottom: 20px; }
      .configurations-buttons .button.outline.warning {
        margin-right: 0; } }
  .configurations-buttons .button {
    background-color: #f57010;
    border: 1px solid #f57010 !important; }
    .configurations-buttons .button:hover {
      background-color: #f57010 !important;
      border: 1px solid #f57010; }
    .template-2 .configurations-buttons .button {
      background-color: #0071a5;
      border: 1px solid #0071a5 !important; }
      .template-2 .configurations-buttons .button:hover {
        background-color: #0071a5 !important;
        border: 1px solid #0071a5; }
    .template-3 .configurations-buttons .button {
      background-color: #ab1f24;
      border: 1px solid #ab1f24 !important; }
      .template-3 .configurations-buttons .button:hover {
        background-color: #ab1f24 !important;
        border: 1px solid #ab1f24; }
    .template-4 .configurations-buttons .button {
      background-color: #098846;
      border: 1px solid #098846 !important; }
      .template-4 .configurations-buttons .button:hover {
        background-color: #098846 !important;
        border: 1px solid #098846; }

/* Menu */
.page-coach-container .btn-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.page-coach-container .fab-wrapper {
  display: block; }

/* Menu ABA*/
.menu-configurations {
  width: 100%;
  padding: 0px 0 20px; }
  .menu-configurations ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin: 0;
    border-bottom: 2px solid #ececec; }
  .menu-configurations li {
    padding: 5px 5px 10px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: #585858; }
    .menu-configurations li:last-child {
      margin-right: 0; }
    .menu-configurations li:after {
      content: '';
      width: 1px;
      height: 5px;
      display: block;
      position: absolute;
      left: 50%;
      bottom: -5px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-50%);
      background: #f57010;
      transition: all .3s ease-in-out; }
      .template-2 .menu-configurations li:after {
        background: #0071a5; }
      .template-3 .menu-configurations li:after {
        background: #ab1f24; }
      .template-4 .menu-configurations li:after {
        background: #098846; }
    .menu-configurations li:hover:after, .menu-configurations li.tab-active:after {
      width: 100%;
      opacity: 1;
      visibility: visible;
      left: 0;
      transition: all .3s ease-in-out; }

.body-modal .input-group.switch, .body-modal .input-group.changeGround {
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  padding: 10px 0px; }

.selectImgBack {
  position: relative; }

.edit-changeGround {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  right: -9px;
  background: #f57010;
  border-radius: 50%;
  border: 2px solid #fff; }
  .edit-changeGround i {
    color: #fff;
    font-size: 15px; }
  .template-2 .edit-changeGround {
    background: #0071a5; }
  .template-3 .edit-changeGround {
    background: #ab1f24; }
  .template-4 .edit-changeGround {
    background: #098846; }

/* switch */
.selectImgBack {
  width: 100px;
  height: 100px;
  margin-left: 15px;
  cursor: pointer; }
  .selectImgBack img {
    width: 100%;
    height: 80%;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.4); }

.location-info-container,
.personal-info-container,
.social-net-container,
.areas-expertise-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 10px 0px 10px 0px; }

.location-info-container .input-group {
  width: 33%; }
  @media (max-width: 768px) {
    .location-info-container .input-group {
      width: 100%; } }

.personal-info-container .input-group,
.social-net-container .input-group {
  min-width: 49%; }
  @media (max-width: 768px) {
    .personal-info-container .input-group,
    .social-net-container .input-group {
      min-width: 100%; } }

.checkbox-input-group {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0px;
  color: #F17127; }

.checkbox-input-group .checkbox-input {
  width: 20%;
  margin: 10px 0; }
  .checkbox-input-group .checkbox-input label {
    text-transform: capitalize; }
