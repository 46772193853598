@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--coach-numbers {
  width: 1440px;
  max-width: 100%;
  padding: 1rem;
  margin: 20px auto 100px; }
  @media (min-width: 650px) {
    .template--5--coach-numbers {
      justify-content: center; } }
  @media (min-width: 990px) {
    .template--5--coach-numbers {
      width: 80%;
      padding: 4vh 0; } }
  .template--5--coach-numbers .item-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    margin: 0 20px 20px;
    color: #696969; }
    .template--5--coach-numbers .item-info:last-child {
      margin-bottom: 0; }
    @media (min-width: 650px) {
      .template--5--coach-numbers .item-info {
        margin-bottom: 0; } }
    .template--5--coach-numbers .item-info .icon-item {
      width: 80px;
      height: 80px; }
      .template--5--coach-numbers .item-info .icon-item::before {
        content: '';
        position: absolute;
        left: 0;
        border-left: 40px solid var(--c-primary);
        border-bottom: 40px solid var(--c-primary);
        border-right: 40px solid transparent;
        border-top: 40px solid transparent;
        opacity: 0.7; }
      .template--5--coach-numbers .item-info .icon-item svg {
        z-index: 2; }
    .template--5--coach-numbers .item-info .coach-infos {
      padding: 0 0 0 20px;
      text-align: left; }
      .template--5--coach-numbers .item-info .coach-infos p {
        margin: 0; }
      .template--5--coach-numbers .item-info .coach-infos .number-info {
        font-size: 46px;
        font-weight: 700; }
      .template--5--coach-numbers .item-info .coach-infos .title-info {
        width: 170px;
        font-size: 18px;
        line-height: 18px; }
    .template--5--coach-numbers .item-info:last-child .coach-infos {
      padding: 0 0 0 20px; }

.template--5-numbers-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
  @media (min-width: 1440px) {
    .template--5-numbers-content {
      width: 90%; } }
  @media (min-width: 1700px) {
    .template--5-numbers-content {
      width: 70%; } }
