.posts--home {
  padding-bottom: 30px; }
  .posts--home .title-wrapper {
    width: 100%;
    margin: 6% 0 3.5% 0;
    align-self: flex-start;
    text-align: center; }
    @media (max-width: 768px) {
      .posts--home .title-wrapper {
        height: 20%;
        min-height: 90px;
        padding: 20px;
        margin: 0px;
        padding: 10px; } }
  .posts--home .title {
    width: 100%;
    padding-top: 20px;
    margin-top: 0px;
    border-top: none;
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    text-align: center; }
    .posts--home .title:before {
      content: '';
      width: 50px;
      height: 3px;
      margin: 0 auto 20px;
      display: block;
      background-color: #f57010; }
  .posts--home .btn-more {
    padding: 8px 20px;
    border: 1px solid #f57010;
    border-radius: 10px;
    background: #f57010;
    cursor: pointer;
    color: #fff;
    font-size: 18px; }
    .posts--home .btn-more:hover {
      background: transparent;
      color: #f57010; }
    .posts--home .btn-more:hover, .posts--home .btn-more:focus, .posts--home .btn-more:active {
      outline: none; }

.post--list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.post--item {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 30px; }
  @media (min-width: 500px) {
    .post--item {
      flex-flow: row nowrap; } }
  @media (min-width: 700px) {
    .post--item {
      width: 48%;
      flex-flow: column nowrap; } }
  @media (min-width: 990px) {
    .post--item {
      width: 45%;
      flex-flow: row nowrap; } }

.post--image {
  overflow: hidden;
  border-radius: 5px; }
  .post--image img {
    display: block;
    object-fit: cover;
    border-radius: 5px; }

.post---content {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 10px; }
  @media (min-width: 500px) {
    .post---content {
      width: calc(100% - 160px);
      justify-content: flex-start;
      align-items: flex-start; } }
  @media (min-width: 700px) {
    .post---content {
      width: 100%;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 990px) {
    .post---content {
      width: calc(100% - 160px);
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0px; } }
  @media (min-width: 1024px) {
    .post---content {
      padding-left: 20px; } }
  .post---content h5 {
    margin: 0 0 10px;
    font-size: 23px;
    font-weight: bold;
    color: #484848;
    text-align: center;
    line-height: 23px; }
    @media (min-width: 500px) {
      .post---content h5 {
        text-align: left; } }
    @media (min-width: 700px) {
      .post---content h5 {
        text-align: center; } }
    @media (min-width: 990px) {
      .post---content h5 {
        text-align: left; } }
  .post---content p {
    margin: 0;
    font-size: 18px;
    color: #969696;
    text-align: center;
    line-height: 18px; }
    @media (min-width: 500px) {
      .post---content p {
        text-align: left; } }
    @media (min-width: 700px) {
      .post---content p {
        text-align: center; } }
    @media (min-width: 990px) {
      .post---content p {
        text-align: left; } }
  .post---content button {
    margin-top: 10px;
    padding: 8px 20px;
    border: 1px solid #f57010;
    border-radius: 10px;
    cursor: pointer;
    color: #f57010;
    font-size: 12px; }
    .post---content button:hover {
      background: #f57010;
      color: #fff; }
    .post---content button:hover, .post---content button:focus, .post---content button:active {
      outline: none; }
