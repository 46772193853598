.start-section {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 50px;
  min-height: 45vh; }
  .start-section p, .start-section span {
    color: white;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    margin: 0; }
  .start-section span {
    color: #FE7800; }
  .start-section .main-paragraph {
    font-size: 24px;
    padding-right: 10%; }
    @media (max-width: 415px) {
      .start-section .main-paragraph {
        max-width: 310px; } }
  .start-section .sub-paragraph {
    margin-top: 20px;
    padding-right: 34%;
    font-size: 12px; }
  .start-section .btn-start {
    margin-top: 40px;
    height: 45px;
    width: 70%;
    max-width: 200px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    background-color: #FE7800;
    border-radius: 9px;
    cursor: pointer; }
    .start-section .btn-start:hover {
      background-color: #eE6800; }
  @media (min-width: 768px) {
    .start-section {
      height: 100vh;
      width: 55%;
      padding: 12% 10% 0 6%; }
      .start-section .main-paragraph {
        font-size: clamp(38px, 2.5vw, 48px); }
      .start-section .sub-paragraph {
        font-size: clamp(18px, 1.3vw, 24px); }
      .start-section .btn-start {
        font-size: 16px; } }
