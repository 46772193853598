.blog-search-background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    background-color: #FE7800;

    .blog-search-bar {
        position: relative;

        width: 60%;
        max-width: 800px;
        height: 45px;
        margin: 25px 0;
    
        border-radius: 5px;

        input {
            padding: 5px 15px;
            background: #F5F5F5;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            border: none;
    
            color: #282828;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
    
            outline: none;
    
            &:focus-within {
                box-shadow: 0px 0px 0px 2px #282828;
            }
    
            &::placeholder {
                color: #BCBCBC;
            }
    
            &:disabled {
                cursor: not-allowed;
            }
        }

        button {
            cursor: pointer;
    
            .icon-search {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
            }
    
    
            &:disabled {
                cursor: not-allowed;
    
                .icon-search {
                    svg {
                        path {
                            stroke: #BCBCBC;
                        }
                    }
                }
            }
        }
    
        @media(max-width: 750px) {
            width: 100%;
            height: 30px;

            input {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }

    @media(max-width: 750px) {
        width: 100%;
        height: 50px;
        padding: 10px 20px;
    }
}