@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--curriculum .curriculum-info {
  display: flex;
  padding: 0 30px;
  color: #696969; }
  .template--6--curriculum .curriculum-info p {
    width: 100%;
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    color: #6F6F6F;
    text-align: center; }
