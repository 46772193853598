@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.contact-wrapper {
  width: 100%;
  position: relative; }
  @media (max-width: 768px) {
    .contact-wrapper {
      background-size: cover;
      background-image: none;
      background-color: var(--c-primary); } }

.contact-content {
  width: 100%;
  background: var(--c-primary);
  background-repeat: no-repeat;
  clip-path: ellipse(87% 66% at 50% 69%); }
  @media (max-width: 768px) {
    .contact-content {
      clip-path: none; } }

.contact-wrapper .content {
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto;
  padding-top: 50px; }
  @media (max-width: 768px) {
    .contact-wrapper .content {
      width: 90vw;
      position: relative;
      z-index: 2; } }
  .contact-wrapper .content h2 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #fff; }

.contact-wrapper .contact-form {
  width: 100%;
  position: relative; }

.contact-form .one-input,
.contact-form .two-input {
  margin: 12px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--c-primary); }
  @media (max-width: 768px) {
    .contact-form .one-input,
    .contact-form .two-input {
      flex-flow: column wrap;
      justify-content: space-between; } }

.two-input .input-wrapper {
  width: 48%; }
  @media (max-width: 768px) {
    .two-input .input-wrapper {
      width: 100%; }
      .two-input .input-wrapper:not(:last-child) {
        margin-bottom: 10px; } }
  .two-input .input-wrapper input {
    width: 78%; }

.contact-form .one-input .last {
  flex-flow: column wrap !important; }

.contact-form .one-input .msg {
  height: 90px; }

.content .contact-form .msg textarea {
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  color: #414243;
  font-size: 12px; }
  @media (max-width: 768px) {
    .content .contact-form .msg textarea {
      height: 7em; } }
  .content .contact-form .msg textarea:focus {
    outline: none; }

.contact-form .button {
  margin-bottom: 60px;
  cursor: pointer;
  background: transparent; }
  @media (max-width: 768px) {
    .contact-form .button {
      margin-bottom: 50px; } }
  .contact-form .button:hover {
    background: #fff !important;
    border: 1px solid #fff;
    color: var(--c-primary) !important;
    text-shadow: 0 0 0; }

/* Error input */
.contact-form .error-message-email {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  font-size: 16px;
  color: #D80027;
  text-align: center; }

.contact-form .icon-exclamation {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: block;
  background: url("../../../../img/danger.png");
  background-size: cover; }

/* MENSAGEM DE SUCESSO */
.contact-form .message-sendSucess {
  width: 100%;
  height: 96%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 5px;
  font-size: 20px;
  color: #414243; }

.contact-form .message-sendSucess .success-msg {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #32C75E; }

.contact-form .icon-correct {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: block;
  background: url("../../../../img/correct.png");
  background-size: cover; }

/* MENSAGEM DE ERRO */
.message-sendError {
  width: 100%;
  height: 96%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 5px;
  font-size: 20px;
  color: #414243; }

.message-sendError .error-msg {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 30px;
  font-weight: 600;
  color: #e04f5f;
  text-transform: uppercase; }

.icon-error {
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 10px;
  background: url("../../../../img/close.png");
  background-size: cover; }

.input-wrapper .fa {
  color: var(--c-primary); }
