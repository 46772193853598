@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--effect-message {
  width: 90%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  z-index: 1; }
  @media (min-width: 420px) {
    .template--7--effect-message {
      width: 70%; } }
  @media (min-width: 550px) {
    .template--7--effect-message {
      width: 50%; } }
  @media (min-width: 990px) {
    .template--7--effect-message {
      width: 40%; } }
  .template--7--effect-message:before {
    content: '';
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--c-primary); }
    @media (min-width: 550px) {
      .template--7--effect-message:before {
        width: 60%; } }

.template--7--em-content {
  width: 90%;
  padding: 3vw;
  position: relative;
  z-index: 1; }
  .template--7--em-content .subtitle {
    position: relative;
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase; }
    @media (min-width: 550px) {
      .template--7--em-content .subtitle {
        font-size: 18px; } }
    @media (min-width: 1030px) {
      .template--7--em-content .subtitle {
        font-size: 20px; } }
    @media (min-width: 1200px) {
      .template--7--em-content .subtitle {
        font-size: 25px; } }
    .template--7--em-content .subtitle:before {
      content: '';
      width: 30%;
      height: 1px;
      position: absolute;
      top: -20px;
      left: 0;
      background: #fff; }
    .template--7--em-content .subtitle:after {
      content: '';
      width: 1px;
      height: 85%;
      position: absolute;
      top: 0;
      left: -20px;
      background: #fff; }
  .template--7--em-content .autor {
    font-size: 18px;
    font-weight: 500;
    color: #fff; }
