.coaches-list-search {
  width: 95%;
  background-color: #FE7800;
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 9px;
  max-width: 380px; }
  .coaches-list-search .title {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 39px; }
    .coaches-list-search .title h1 {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 1.4rem; }
  .coaches-list-search .container-all-form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .coaches-list-search .container-all-form .input-coach-name {
      display: flex;
      flex-direction: column; }
      .coaches-list-search .container-all-form .input-coach-name input {
        padding: 2px 10px;
        height: 38px;
        border-radius: 4px;
        border: 2px solid #FFFFFF;
        outline: none;
        padding: 7px;
        color: #1f1d1d;
        font-size: 1rem; }
        .coaches-list-search .container-all-form .input-coach-name input:focus {
          border: 2px solid dodgerblue; }
        .coaches-list-search .container-all-form .input-coach-name input::placeholder {
          font-size: 16px; }
    .coaches-list-search .container-all-form .button-search-coach {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 800;
      font-size: 1rem;
      width: 85%;
      line-height: 28px;
      display: flex;
      background: #15161B;
      border-radius: 10px;
      color: #FFFFFF;
      padding: 10px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-top: 20px; }
      .coaches-list-search .container-all-form .button-search-coach:hover {
        transform: scale(1.05); }
    .coaches-list-search .container-all-form .item-no-margin {
      margin-top: 5px; }
  @media (min-width: 800px) {
    .coaches-list-search {
      position: relative;
      left: 30px;
      width: 100%; } }
  @media (min-width: 192px) {
    .coaches-list-search .title h1 {
      font-size: 1.8rem; } }
  @media (min-width: 300px) {
    .coaches-list-search .container-all-form .button-search-coach {
      font-size: 1.2rem;
      width: 85%; }
    .coaches-list-search .item-no-margin {
      margin-top: 0px; } }

@media (min-width: 750px) {
  .double {
    display: none; } }

.responsive {
  position: sticky;
  top: 10px;
  z-index: 3;
  margin: -40px auto;
  min-width: 90%; }
  .responsive .container-all-form.close {
    height: 0px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s; }
  .responsive .container-all-form.open {
    transition: all 1s;
    opacity: 1;
    height: 100%;
    max-height: 600px; }
  .responsive .title .search-coach {
    border-bottom: 1px solid #fafafa;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
    .responsive .title .search-coach div > svg {
      cursor: pointer; }
    .responsive .title .search-coach .input-coach-name-responsive {
      margin-right: 20px; }
      .responsive .title .search-coach .input-coach-name-responsive input {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 10px;
        outline: none;
        color: #fff;
        font-weight: 700;
        font-size: 1.3rem; }
        .responsive .title .search-coach .input-coach-name-responsive input::placeholder {
          color: #fff;
          font-weight: 700;
          font-size: 1.3rem; }
  .responsive .title .responsive-arrow {
    padding: 20px 0 0 20px; }
    .responsive .title .responsive-arrow .invert {
      transition: all 1s;
      transform: rotate(180deg); }
    .responsive .title .responsive-arrow .no-invert {
      transition: all 1s; }
    .responsive .title .responsive-arrow div > svg {
      cursor: pointer; }
