.categories {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 86px;
    background-color: #F5F5F5;
    padding: 0 15%;

    .category {
        width: 100%;

        .slick-list {
            width: 100%;

            @media (max-width: 750px) {
                padding-left: 15px;
            }
        }

        .slick-slide {
            max-width: 180px;
            width: 100%;
            padding: 0 5px;

            @media (max-width: 880px) {
                width: 130px;
            }
        }

        .slick-track {
            display: flex;
            justify-content: center;
        }

        .icon-blog-next-arrow {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            right: -35px;
            display: block;
            padding: 0;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            cursor: pointer;
            color: transparent;
            border: none;
            outline: none;
            background: transparent;
        }

        .icon-blog-prev-arrow  {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            left: -35px;
            display: block;
            padding: 0;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            cursor: pointer;
            color: transparent;
            border: none;
            outline: none;
            background: transparent;
        }

        .slick-disabled {
          opacity: 0.2;  
        }

        .category-style {
            color: #FFF;
            background-color: #FE7800;
        }

        a {
            width: 100%;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            padding: 10px 10px;
            color: #3D3D3D;
            border-radius: 30px;
            background-color: #FFF;
            text-transform: capitalize;

            &:hover {
                color: #FFF;
                background-color: #FE7800;
            }

            @media (max-width: 880px) {
                font-size: 12px;
                padding: 8px 8px;
            }
        }
    }

    margin-bottom: 25px;

    @media (max-width: 750px) {
        height: 50px;
        padding: 0;
    }
}
