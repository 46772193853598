@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--gallery {
  padding: 40px 0rem 70px;
  background: linear-gradient(to bottom, #fbfbfb 20%, white 80%); }
  .template--5--gallery .title-section-content {
    margin: 0; }
  .template--5--gallery .slick-list {
    width: 100%;
    padding: 20px 0 0; }
  .template--5--gallery .slick-slide {
    width: 460px;
    height: 300px;
    padding: 20px; }
    .template--5--gallery .slick-slide:first-child, .template--5--gallery .slick-slide:last-child {
      padding: 20px; }
    .template--5--gallery .slick-slide > div,
    .template--5--gallery .slick-slide .generic--slider-item,
    .template--5--gallery .slick-slide .template--5-photo-card {
      width: 100%;
      height: 100%; }
  .template--5--gallery .slick-dots li button:before {
    color: var(--c-primary); }

.template--5--gallery-container {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem; }
