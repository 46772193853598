.personal-data {
  width: auto; }
  @media (min-width: 769px) {
    .personal-data {
      width: 400px; } }

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #FFFFFF; }

.form-item {
  display: flex;
  justify-content: space-between;
  flex-direction: row; }
  .form-item .input-contact label, .form-item .input-contact span, .form-item .phone-input label, .form-item .phone-input span {
    color: #FFFFFF;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: start;
    left: 0px;
    padding: 5px 0px; }
  .form-item .input-contact span, .form-item .phone-input span {
    padding: 15px 0px; }
  .form-item .input-contact input, .form-item .phone-input input {
    height: 40px;
    background: transparent;
    border: 1px solid #FE7800;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: 'normal';
    font-size: '14px'; }
  .form-item .input-contact .error, .form-item .phone-input .error {
    color: #e60808;
    font-size: 12px; }
  .form-item .error input {
    border: 1px solid #ff0000; }
    .form-item .error input:hover {
      border-color: #ff0000; }

.form-item-phone {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 75px; }
  .form-item-phone label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: start;
    left: 0px;
    margin-top: 10px;
    color: #FFFF; }
  .form-item-phone .input-contact span {
    color: #000;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: start;
    left: 0px;
    padding: 5px 0px;
    margin-left: 5px; }
  .form-item-phone .input-contact .form-control {
    width: 100%;
    padding-right: 10px;
    height: 40px; }
  .form-item-phone .input-contact .flag-dropdown {
    background-color: transparent;
    border: none; }
    .form-item-phone .input-contact .flag-dropdown .selected-flag {
      background-color: transparent; }
  .form-item-phone .input-contact .flag {
    border-radius: 0px; }
  .form-item-phone .input-contact .country-list {
    background: white;
    color: #202020;
    font-weight: 500;
    border-radius: 5px;
    height: 130px; }
    @media (max-width: 340px) {
      .form-item-phone .input-contact .country-list {
        width: 210px; } }
  .form-item-phone .input-contact .country {
    display: flex;
    align-items: baseline;
    padding: 2px 9px; }
    .form-item-phone .input-contact .country:hover {
      background-color: #030303;
      color: #FFF; }
      .form-item-phone .input-contact .country:hover .dial-code, .form-item-phone .input-contact .country:hover .country-name {
        color: #fff; }
    .form-item-phone .input-contact .country .dial-code {
      color: #000; }
  .form-item-phone .input-contact input {
    background: transparent;
    border: 1px solid #FE7800;
    border-radius: 5px;
    outline: none;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: 'normal';
    font-size: '14px'; }
