.container-request-session {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  position: relative; }
  .container-request-session h1 {
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 1.1rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700; }
  .container-request-session .form-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .container-request-session .form-item .input-contact label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 0.7rem;
      text-align: start;
      color: rgba(0, 0, 0, 0.4); }
    .container-request-session .form-item .input-contact.double-item {
      width: 48%; }
    .container-request-session .form-item .input-contact input,
    .container-request-session .form-item .input-contact textarea {
      background: #eeeeee;
      border: none;
      padding: 10px;
      border-radius: 5px;
      outline: none; }
  .container-request-session .selectors-group {
    color: black; }
    .container-request-session .selectors-group h2 {
      width: 100%;
      color: #000;
      text-align: center;
      font-size: 0.9rem;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      margin: 20px 0px; }
    .container-request-session .selectors-group .radiobox-group {
      display: flex;
      align-items: center;
      gap: 15px; }
    .container-request-session .selectors-group .experimental-req-day,
    .container-request-session .selectors-group .experimental-req-period,
    .container-request-session .selectors-group .experimental-req-session {
      margin-top: 5px; }
      .container-request-session .selectors-group .experimental-req-day label,
      .container-request-session .selectors-group .experimental-req-period label,
      .container-request-session .selectors-group .experimental-req-session label {
        text-align: left;
        color: #00000066; }
      .container-request-session .selectors-group .experimental-req-day > div > div,
      .container-request-session .selectors-group .experimental-req-period > div > div,
      .container-request-session .selectors-group .experimental-req-session > div > div {
        border: none;
        background-color: #eeeeee;
        font-size: 14px; }
        .container-request-session .selectors-group .experimental-req-day > div > div > div,
        .container-request-session .selectors-group .experimental-req-period > div > div > div,
        .container-request-session .selectors-group .experimental-req-session > div > div > div {
          border: none;
          border-radius: 8px;
          background-color: #eeeeee; }
  .container-request-session .button-contanct {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px; }
    .container-request-session .button-contanct button {
      background: #FE7800;
      border-radius: 10px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 42px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      width: 90%;
      text-align: center;
      justify-content: center;
      cursor: pointer; }
      .container-request-session .button-contanct button.disable {
        cursor: not-allowed;
        background-color: #bbbbbb; }

.phone-input-session {
  text-align: start;
  width: 100%;
  margin-top: 5px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600; }
  .phone-input-session .form-control {
    width: 100%;
    background-color: #EEEEEE;
    color: #202020;
    border: none; }
  .phone-input-session .flag-dropdown {
    background-color: #EEEEEE;
    border: none; }
    .phone-input-session .flag-dropdown .selected-flag {
      background-color: #EEEEEE; }
  .phone-input-session label {
    margin-left: 5px;
    margin-bottom: 20px;
    line-height: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    text-align: start;
    color: #9D9D9D; }
  .phone-input-session .flag {
    border-radius: 0px; }
  .phone-input-session .country-list {
    color: #202020;
    font-weight: 500; }
  .phone-input-session .country-list .country {
    display: flex;
    padding: 5px 0 0 0;
    background-color: #eee; }
    .phone-input-session .country-list .country .flag {
      margin-left: 10px;
      margin-top: 3;
      padding: 10px 10px 0 0; }
    .phone-input-session .country-list .country .country-name {
      padding: 0; }
    .phone-input-session .country-list .country .dial-code {
      color: #202020; }
    .phone-input-session .country-list .country:hover {
      background-color: #030303;
      color: #FFF; }
      .phone-input-session .country-list .country:hover .dial-code {
        color: #FFF; }
  .phone-input-session .react-tel-input .flag-dropdown.open .selected-flag {
    background: #eee; }
  .phone-input-session .country-list .country.highlight {
    background: #ffffff; }
    .phone-input-session .country-list .country.highlight:hover {
      background-color: #030303;
      color: #FFF; }
      .phone-input-session .country-list .country.highlight:hover .dial-code {
        color: #FFF; }
