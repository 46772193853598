.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 55vh;
  background: linear-gradient(148.74deg, #FE7800 -6.85%, #DE3A06 80.05%);
  overflow: hidden; }
  .info-section p {
    color: white;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    margin: 0; }
  .info-section span {
    font-weight: 800;
    padding: 0 10px; }
  .info-section .sub-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 115px; }
    .info-section .sub-section .main-paragraph {
      display: contents;
      padding: 0 13%;
      text-align: center;
      font-size: 24px; }
    .info-section .sub-section .sub-paragraph {
      padding: 5px 15px;
      margin-top: 10px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      background-color: #15161B; }
  @media (min-width: 768px) {
    .info-section {
      height: 100vh;
      width: 45%; }
      .info-section .sub-section .main-paragraph {
        font-size: 40px;
        font-size: clamp(32px, 2.2vw, 40px);
        padding: 0 5%;
        display: contents; }
      .info-section .sub-section .sub-paragraph {
        font-size: clamp(18px, 1.3vw, 24px); } }
