.search-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, transparent 60%, #15161B 60%); }
  .search-section-container .search-section-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: white; }
  @media (max-width: 750px) {
    .search-section-container .search-section-title {
      display: none; } }

.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 215px;
  padding: 20px 40px 50px;
  border-radius: 9px;
  background-color: #FE7800; }
  .search-form .search-title-mobile {
    display: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin: 10px 0; }
  .search-form label {
    color: white;
    padding-left: 10px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    width: 100%; }
  .search-form .search-actions {
    position: relative;
    height: 50px;
    min-width: 28%; }
    .search-form .search-actions .btn-search {
      position: absolute;
      bottom: -5px;
      height: 100%;
      width: 100%;
      padding: 10px 15px;
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: clamp(14px, 1.2vw, 22px);
      background-color: #15161B;
      border-radius: 9px;
      cursor: pointer;
      transition: scale 0.2s; }
      .search-form .search-actions .btn-search:hover {
        scale: 1.05; }
  @media (max-width: 1000px) {
    .search-form {
      width: 95%;
      padding: 10px; } }
  @media (max-width: 750px) {
    .search-form {
      width: 90%;
      padding: 20px 25px 30px; }
      .search-form .search-title-mobile {
        display: block; }
      .search-form .search-actions {
        width: 70%;
        height: 45px;
        margin: 10px auto 0; }
        .search-form .search-actions .btn-search {
          font-size: 12px; }
      .search-form .first-row {
        margin-bottom: 0; } }

.first-row, .second-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .first-row .select-wrapper-container, .second-row .select-wrapper-container {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    width: 100%; }
  .first-row .search-item label, .second-row .search-item label {
    font-size: clamp(12px, 1vw, 16px); }
  @media (max-width: 750px) {
    .first-row, .second-row {
      flex-direction: column; }
      .first-row .search-item, .second-row .search-item {
        margin: 0 0 10px 0; }
        .first-row .search-item label, .second-row .search-item label {
          font-size: 11px;
          padding-left: 5px; } }

.first-row {
  margin-bottom: 20px; }
  .first-row .input-coach-name {
    display: flex;
    flex-direction: column; }
    .first-row .input-coach-name input {
      padding: 2px 10px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      outline: none;
      padding: 7px;
      color: #1f1d1d;
      font-size: clamp(12px, 1vw, 16px);
      width: 100%; }
      .first-row .input-coach-name input:focus {
        border: 2px solid dodgerblue; }
      .first-row .input-coach-name input::placeholder {
        font-size: clamp(12px, 1vw, 16px); }

.second-row {
  align-items: flex-end; }
  .second-row .container-helper {
    right: unset;
    left: 5px; }
  @media (max-width: 750px) {
    .second-row .container-helper {
      left: unset;
      right: 5px; } }
