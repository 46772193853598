@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--curriculum {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto 5vh;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #696969; }
  .template--5--curriculum .description {
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    text-align: center; }
    @media (min-width: 650px) {
      .template--5--curriculum .description {
        width: 80%; } }
