@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.testimonies-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 30px;
  color: #505152; }
  .testimonies-container .title-wrapper {
    width: 100%;
    margin-bottom: 30px; }
    @media (max-width: 800px) {
      .testimonies-container .title-wrapper {
        padding: 20px;
        margin-bottom: 0; } }
  .testimonies-container .title {
    width: 100%;
    padding-top: 14px;
    margin-top: 0px;
    border-top: none;
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    text-align: center;
    display: grid; }
    .testimonies-container .title:before {
      content: '';
      width: 50px;
      height: 3px;
      margin: 0 auto 20px;
      display: block;
      background-color: var(--c-primary); }
  @media (max-width: 800px) {
    .testimonies-container {
      padding: 40px 20px 40px; } }

/* -- Init Slider --  */
.testimonies-slider-all .carousel-slider {
  flex-flow: column wrap; }

.testimonies-container .carousel-container .slider-wrapper {
  width: 50%;
  margin: 0;
  padding-bottom: 0px; }
  @media (max-width: 1000px) {
    .testimonies-container .carousel-container .slider-wrapper {
      width: 60%; } }
  @media (max-width: 650px) {
    .testimonies-container .carousel-container .slider-wrapper {
      width: 80%; } }
  @media (max-width: 400px) {
    .testimonies-container .carousel-container .slider-wrapper {
      width: 100%; } }

.testimonies-container .carousel-container .carousel-slider {
  justify-content: center; }

/* Setas slider */
.testimonies-container .carousel-container .control-arrow {
  height: auto;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent !important; }
  .testimonies-container .carousel-container .control-arrow:before {
    content: '';
    width: 20px;
    height: 40px;
    display: block;
    margin: 0;
    padding: 0;
    background-image: url("../../../../img/arrow-icon.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    color: #000;
    font-size: 80px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: lighter; }
  .testimonies-container .carousel-container .control-arrow.control-next {
    right: 200px; }
    @media (max-width: 1000px) {
      .testimonies-container .carousel-container .control-arrow.control-next {
        right: 120px; } }
    @media (max-width: 800px) {
      .testimonies-container .carousel-container .control-arrow.control-next {
        right: 0; } }
    @media (max-width: 650px) {
      .testimonies-container .carousel-container .control-arrow.control-next {
        right: 20px; } }
    @media (max-width: 400px) {
      .testimonies-container .carousel-container .control-arrow.control-next {
        display: none; } }
  .testimonies-container .carousel-container .control-arrow.control-prev {
    left: 200px; }
    .testimonies-container .carousel-container .control-arrow.control-prev:before {
      transform: rotate(180deg); }
    @media (max-width: 1000px) {
      .testimonies-container .carousel-container .control-arrow.control-prev {
        left: 120px; } }
    @media (max-width: 800px) {
      .testimonies-container .carousel-container .control-arrow.control-prev {
        left: 0; } }
    @media (max-width: 650px) {
      .testimonies-container .carousel-container .control-arrow.control-prev {
        left: 20px; } }
    @media (max-width: 400px) {
      .testimonies-container .carousel-container .control-arrow.control-prev {
        display: none; } }

/* Bullets */
.testimonies-container .carousel-container .control-dots {
  position: relative;
  padding-left: 0;
  bottom: 0px;
  left: 0;
  margin: 0;
  border: 0; }
  .testimonies-container .carousel-container .control-dots .dot {
    width: 12px;
    height: 12px;
    margin: 10px 5px 0 0;
    background-color: #939598 !important;
    box-shadow: 0 0 0 #fff;
    border: 0; }
    .testimonies-container .carousel-container .control-dots .dot:last-child {
      margin: 10px 0px 0 0; }

/* -- Finish Slider -- */
.testimony-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px; }
  .testimony-content input,
  .testimony-content textarea {
    padding: 5px;
    border: 1px solid #d0d2d1;
    border-radius: 4px; }
    .testimony-content input:active, .testimony-content input:focus,
    .testimony-content textarea:active,
    .testimony-content textarea:focus {
      outline: none; }

.testimony-text {
  width: 100%;
  display: block;
  margin-bottom: 20px !important;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #656565;
  text-align: center;
  text-overflow: ellipsis; }
  .testimony-text textarea {
    width: 100% !important;
    height: 5em;
    display: block; }
  @media (max-width: 800px) {
    .testimony-text {
      max-width: 90%; } }

.testimony-author {
  margin: 24px 5px 5px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 900;
  color: #484848;
  text-transform: uppercase; }
  .testimony-author input {
    text-transform: uppercase;
    font-weight: bold; }

.testimony-author-job {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #656565; }

.testimony-toolbar {
  position: absolute;
  right: 15%;
  bottom: 10px;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.5); }
  @media (max-width: 800px) {
    .testimony-toolbar {
      right: 0; } }

.icon-testimony {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: url("../../../../img/icon-testimonial.png");
  background-repeat: no-repeat;
  background-size: cover; }

.icon-add-testimony {
  width: 51px;
  height: 47px;
  display: block;
  background: url("../../../../img/icon-add-testimony.png");
  background-repeat: no-repeat; }

.testimony-placeholder {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed #d0d2d1;
  cursor: pointer; }
  .testimony-placeholder span {
    margin-top: 5px;
    color: #d6d6d6;
    font-size: 14px; }
