.resume-footer {
  width: 100%;
  margin-top: 40px;
  background-color: #f2f2f2; }
  .resume-footer .resume-disable {
    top: 20px;
    margin-top: 0px; }
  @media (max-width: 768px) {
    .resume-footer {
      height: 20%; }
      .resume-footer .resume-footer-content {
        padding: 35px; } }

.resume-footer-desk {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: auto;
  padding: 40px 0px; }
  .resume-footer-desk .info-card {
    flex: 1; }
  @media (max-width: 768px) {
    .resume-footer-desk {
      display: none; } }

.resume-footer-mobile {
  display: none; }
  .resume-footer-mobile .control-dots {
    position: relative;
    margin: 0;
    bottom: 0 !important; }
    .resume-footer-mobile .control-dots .dot {
      margin: 0 5px 0; }
      .resume-footer-mobile .control-dots .dot:last-child {
        margin: 0; }
  @media (max-width: 500px) {
    .resume-footer-mobile .control-arrow.control-next, .resume-footer-mobile .control-arrow.control-prev {
      display: none; } }
  @media (max-width: 768px) {
    .resume-footer-mobile .control-arrow {
      height: 100%; } }
  .resume-footer-mobile .carousel.carousel-slider {
    flex-flow: column wrap;
    bottom: 0; }
  @media (max-width: 768px) {
    .resume-footer-mobile {
      width: 100%;
      display: flex; } }
  .resume-footer-mobile > div:first-child {
    width: 100%; }

.info-card.notShow {
  display: none; }
