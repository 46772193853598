@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.biography-contact--6 {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  padding: 20px 0;
  color: #6f6f6f;
  border-top: 1px solid var(--c-primary); }
  @media (min-width: 990px) {
    .biography-contact--6 {
      width: calc(250px);
      flex-flow: column nowrap;
      justify-content: flex-start;
      padding: 0 0 0 35px;
      border-top: 1px solid transparent;
      border-left: solid 1px var(--c-primary); } }
  .biography-contact--6 .expertises {
    margin-bottom: 10px;
    text-align: left; }
    @media (max-width: 750px) {
      .biography-contact--6 .expertises {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--c-primary);
        text-align: center; } }
    .biography-contact--6 .expertises p {
      margin: 5px 0; }
  .biography-contact--6 .item-contact {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 25px; }
  .biography-contact--6 h4 {
    margin: 0; }
  .biography-contact--6 p {
    margin: 0 10px;
    font-size: 18px; }
