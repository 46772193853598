.contact-us-section {
  width: 100%;
  background-color: #FE7800; }
  @media (max-width: 1000px) {
    .contact-us-section .contact-us-container {
      width: 80%; } }
  @media (max-width: 767px) {
    .contact-us-section .contact-us-container {
      margin-top: 50px;
      flex-direction: column; }
    .contact-us-section .contact-us-img {
      order: 1; } }

.contact-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 65%; }

.contact-us-img {
  width: clamp(350px, 40vw, 490px); }

.contact-us-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .contact-us-main-content .contact-us-title, .contact-us-main-content .contact-us-text {
    font-family: 'Montserrat';
    color: white; }
  .contact-us-main-content .contact-us-title {
    margin: 0;
    font-size: clamp(30px, 2vw, 36px);
    font-weight: bold; }
  .contact-us-main-content .contact-us-text {
    margin-bottom: 30px;
    font-size: clamp(20px, 1.5vw, 24px); }

.btn-contact-us {
  padding: 0; }
  .btn-contact-us .contact-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 50px;
    background-color: #00B300;
    box-shadow: -10px 19px 57px 3px rgba(0, 0, 0, 0.15); }
    .btn-contact-us .contact-anchor:hover {
      background-color: #00a000; }
    .btn-contact-us .contact-anchor .contact-whatsapp-icon {
      width: clamp(26px, 1.9vw, 31px);
      margin-right: 10px; }
    .btn-contact-us .contact-anchor span {
      font-family: 'Montserrat', sans-serif;
      font-size: clamp(15px, 1.2vw, 18px);
      font-weight: 600;
      color: white; }
