.post-info-section {
    width: 100%;
    display: flex;
    margin: 15px 0px;

    .blog-post-author {
        color: #000;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        margin-right: 5px;
    }

    .post-category {
        color: #FE7800;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        margin: 0 5px;
    }

    .post-date {
        color: #282828;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        margin-left: 3px;
    }

    @media (max-width: 360px) {
        .blog-post-author, .post-category, .post-date {
            font-size: 10px;
        }
    }

    @media (max-width: 280px) {
        .blog-post-author, .post-category, .post-date {
            font-size: 9px;
        }
    }
}