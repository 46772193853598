@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.App {
  width: 100%;
  background: white;
  position: relative;
  font-family: "Nunito Sans", sans-serif; }

.carousel.carousel-slider .control-arrow {
  height: 300px; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
