@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--biography .biography-contact {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1rem;
  background-color: var(--c-primary);
  color: #fff; }
  @media (min-width: 650px) {
    .template--5--biography .biography-contact {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start; } }
  @media (min-width: 990px) {
    .template--5--biography .biography-contact {
      padding: 30px 40px;
      flex-flow: column nowrap;
      justify-content: flex-start; } }
  .template--5--biography .biography-contact p {
    margin: 0px 0 0px 0;
    word-break: break-word;
    font-size: 17px;
    text-align: center; }
    @media (min-width: 990px) {
      .template--5--biography .biography-contact p {
        margin: 5px 0 20px 0; } }
