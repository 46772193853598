@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--galery {
  margin: 80px auto;
  max-width: 1400px;
  background-size: contain; }
  .template--6--galery .generic--slider {
    padding: 0; }
  .template--6--galery .slick-list {
    width: 100%; }
  .template--6--galery .slick-track {
    width: 100%;
    height: 100%; }
  .template--6--galery .generic--slider-item,
  .template--6--galery .slick-slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center; }
    .template--6--galery .generic--slider-item:hover, .template--6--galery .generic--slider-item:focus, .template--6--galery .generic--slider-item:visited,
    .template--6--galery .slick-slide:hover,
    .template--6--galery .slick-slide:focus,
    .template--6--galery .slick-slide:visited {
      outline: none; }
  .template--6--galery .slick-slide > div {
    width: 100%;
    height: 100%; }
  .template--6--galery .slick-dots li button:before {
    color: var(--c-primary);
    filter: brightness(0.8); }

.template--6--galery-container {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto; }
