@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.title-section-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 30px 0; }
  .title-section-content h3 {
    margin: 10px;
    font-family: "Cabin", sans-serif;
    font-style: italic;
    font-weight: 800;
    font-size: 40px;
    color: #696969; }
  .title-section-content span {
    font-family: "Cabin", sans-serif;
    font-style: italic;
    font-weight: 800;
    font-size: 40px;
    color: #A98446; }
