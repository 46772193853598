.session-request-section {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 120px; }
  @media (min-width: 650px) {
    .session-request-section {
      padding: 50px 1rem 120px; } }
  .session-request-section .session-request-form {
    max-width: 800px;
    margin: auto; }
  .session-request-section .session-request-form-container {
    display: flex;
    flex-direction: column;
    gap: 25px; }
    .session-request-section .session-request-form-container .request-input-field {
      display: flex;
      flex-direction: column;
      flex: 1; }
      .session-request-section .session-request-form-container .request-input-field input {
        height: 40px;
        padding: 12px;
        border: 3px solid transparent;
        border-radius: 5px;
        font-size: 18px;
        color: #696969;
        outline: none; }
        .session-request-section .session-request-form-container .request-input-field input::placeholder {
          font-size: 14px;
          color: #aaaaaa;
          font-weight: bold; }
      .session-request-section .session-request-form-container .request-input-field .span-email-error {
        color: red;
        font-size: 14px;
        padding: 10px 0px 0px 10px; }
    .session-request-section .session-request-form-container .request-input-error input {
      border: 3px solid red;
      border-radius: 5px; }
    .session-request-section .session-request-form-container .form-row-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 25px; }
      .session-request-section .session-request-form-container .form-row-group .request-input-field {
        flex: 1; }
      .session-request-section .session-request-form-container .form-row-group .react-tel-input .selected-flag:hover {
        background-color: #fff; }
      .session-request-section .session-request-form-container .form-row-group .react-tel-input .form-control {
        background-color: #fff; }
      @media (min-width: 520px) {
        .session-request-section .session-request-form-container .form-row-group {
          flex-direction: row; } }
    .session-request-section .session-request-form-container .phone-row-container {
      flex: 1; }
      .session-request-section .session-request-form-container .phone-row-container input {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        color: #696969; }
      .session-request-section .session-request-form-container .phone-row-container .flag-dropdown {
        border-right: 0;
        background-color: #fff;
        border: 1px solid #eee;
        border-right: 0; }
      .session-request-section .session-request-form-container .phone-row-container .selected-flag .flag {
        border-radius: 0; }
      .session-request-section .session-request-form-container .phone-row-container .country-list li {
        display: flex;
        align-items: center;
        gap: 10px; }
        .session-request-section .session-request-form-container .phone-row-container .country-list li div, .session-request-section .session-request-form-container .phone-row-container .country-list li span {
          border-radius: 0;
          margin: 0; }
    .session-request-section .session-request-form-container .select-row-group {
      display: flex;
      flex-direction: column;
      gap: 25px; }
      @media (min-width: 520px) {
        .session-request-section .session-request-form-container .select-row-group {
          flex-direction: row; } }
    .session-request-section .session-request-form-container .session-request-select-day label,
    .session-request-section .session-request-form-container .session-request-select-turn label,
    .session-request-section .session-request-form-container .session-request-select-type label {
      display: none; }
    .session-request-section .session-request-form-container .session-request-select-day > div > div,
    .session-request-section .session-request-form-container .session-request-select-turn > div > div,
    .session-request-section .session-request-form-container .session-request-select-type > div > div {
      border: 1px solid #eee;
      border-radius: 5px; }
    .session-request-section .session-request-form-container .session-request-submit {
      width: 100%;
      max-width: 300px;
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 5px;
      color: #fff;
      margin: 30px auto 0;
      padding: 6px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      transition: transform 0.2s; }
      .session-request-section .session-request-form-container .session-request-submit:hover:not(:disabled) {
        transform: scale(102%); }
      .session-request-section .session-request-form-container .session-request-submit:disabled {
        cursor: not-allowed;
        opacity: 0.5; }

.confirmationMensageSuccses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 250px;
  padding: 15px;
  border-radius: 10px;
  margin: auto; }
  .confirmationMensageSuccses .x-close {
    position: absolute;
    margin-bottom: 250px;
    margin-left: 200px;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.5s; }
    .confirmationMensageSuccses .x-close:hover {
      transform: scale(1.1); }
  .confirmationMensageSuccses p {
    text-align: center; }

.loaderComponent {
  margin: auto;
  position: relative;
  bottom: 300px;
  width: 200px !important;
  padding: 5px;
  border-radius: 10px; }
