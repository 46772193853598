.container-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .container-list .container-general {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    min-height: 78px; }
    .container-list .container-general .container-results {
      margin-top: 10px;
      text-align: center; }
      .container-list .container-general .container-results .div-search {
        display: none; }
      .container-list .container-general .container-results span.result-label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 44px;
        text-align: center;
        color: #000000; }
  .container-list span.result-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 29px;
    text-align: center;
    color: rgba(0, 0, 0, 0.35); }
  .container-list .no-result {
    width: 90%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(12px, 1vw, 16px);
    margin: 0; }
  @media (min-width: 600px) {
    .container-list {
      padding: 40px 5% 45px; }
      .container-list .container-general .container-results {
        margin-top: 0px; }
        .container-list .container-general .container-results .div-search {
          display: none !important; } }
  @media (min-width: 750px) {
    .container-list {
      padding: 10px 0px 80px 45px; }
      .container-list .container-general {
        width: 93%; }
        .container-list .container-general .container-results {
          display: flex;
          flex-direction: row;
          justify-content: initial;
          align-items: center;
          width: 100%; }
          .container-list .container-general .container-results .div-search {
            display: flex !important;
            margin-right: 20px; }
        .container-list .container-general span.result-info {
          border-left: 3px solid #FE7800;
          padding-left: 5px;
          margin-bottom: 5px;
          width: 100%;
          text-align: start; }
      .container-list .no-result {
        text-align: left; } }
