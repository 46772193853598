@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.input-wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px; }
  .input-wrapper input {
    width: 85%;
    height: 100%;
    padding: 5px;
    border: none;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 200;
    font-size: 12px;
    color: #575757; }
    .input-wrapper input:focus {
      outline: none; }
  .input-wrapper:not(:last-child) {
    margin-bottom: 10px; }
  @media (max-width: 768px) {
    .input-wrapper {
      width: 100%;
      height: fit-content; } }

.fa {
  padding: 5px;
  font-size: 25px;
  color: inherit; }
  @media (max-width: 768px) {
    .fa {
      font-size: 100%; } }
