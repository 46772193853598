.social-line .home-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px; }
  .social-line .home-container.bottom {
    display: none; }
    @media (max-width: 768px) {
      .social-line .home-container.bottom {
        display: block; } }

.social-line .fa {
  padding: 5px 5px;
  font-size: 16px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
  .social-line .fa:hover {
    color: #fff;
    transform: scale(1.2); }

.social-whatsapp {
  padding-right: 12px;
  margin-right: 8px;
  border-right: 1px solid #fff;
  font-size: 14px;
  color: #fff; }
