.react-responsive-modal-modal {
  max-width: 90%;
  width: auto;
  height: auto;
  padding: 0px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0; }
  @media (max-width: 768px) {
    .react-responsive-modal-modal {
      width: 100%;
      height: 90vh;
      overflow: auto; } }

.header-modal {
  width: 100%;
  display: flex;
  padding: 1em;
  background-color: #f57010;
  border-radius: 4px 4px 0px 0px;
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase; }
  .header-modal .title {
    flex: 6;
    display: flex;
    align-items: center; }
  @media (max-width: 768px) {
    .header-modal {
      font-size: 1em; } }
  @media (max-width: 320px) {
    .header-modal .header-modal > span i {
      font-size: 20px; } }
  .header-modal.template-2 {
    background-color: #0071a5; }
  .header-modal.template-3 {
    background-color: #ab1f24; }
  .header-modal.template-4 {
    background-color: #098846; }

.body-modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1em;
  border: 0;
  border-radius: 0px 0px 4px 4px; }

.footer-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  float: right; }
  @media (max-width: 768px) {
    .footer-modal {
      padding: .7em; } }

.closeIcon {
  float: right;
  position: relative;
  top: -15px;
  right: -10px;
  flex: 1;
  font-size: 30px;
  text-align: right; }
  .closeIcon .fa {
    cursor: pointer; }
    @media (max-width: 768px) {
      .closeIcon .fa {
        font-size: 20px; } }
