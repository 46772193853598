@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--navbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  color: #4e4e4e;
  font-size: 14px;
  z-index: 2; }
  @media (max-width: 768px) {
    .template--6--navbar {
      padding: 0 4vw; } }
  .template--6--navbar h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: #4E4E4E; }
    @media (min-width: 1167px) {
      .template--6--navbar h2 {
        font-size: 26px; } }
  .template--6--navbar .left-header {
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: space-between; }
  .template--6--navbar .select-language-hamburguer-template-6 {
    width: 120px; }
  .template--6--navbar .header--menu-burguer {
    opacity: 1;
    visibility: visible;
    height: 30px;
    display: grid;
    align-items: center; }
  .template--6--navbar .line {
    width: 30px;
    display: flex;
    height: 5px;
    background-color: white;
    border-radius: 5px; }
    @media (max-width: 860px) {
      .template--6--navbar .line {
        display: flex;
        background-color: bisque; } }
  .template--6--navbar .items--nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center; }
    @media (max-width: 768px) {
      .template--6--navbar .items--nav {
        display: none; } }
    .template--6--navbar .items--nav li {
      padding: 4px 10px; }
      @media (min-width: 1167px) {
        .template--6--navbar .items--nav li {
          padding: 10px 10px; } }
      .template--6--navbar .items--nav li p {
        position: relative;
        margin: 0;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        color: #585858; }
        @media (min-width: 1167px) {
          .template--6--navbar .items--nav li p {
            font-size: 20px; } }
        .template--6--navbar .items--nav li p:before {
          content: '';
          width: 0px;
          height: 2px;
          position: absolute;
          right: 0;
          bottom: -2px;
          transition: all .3s ease-in-out;
          background: var(--c-primary); }
      .template--6--navbar .items--nav li:hover {
        cursor: pointer; }
        .template--6--navbar .items--nav li:hover p {
          color: var(--c-primary); }
          .template--6--navbar .items--nav li:hover p:before {
            width: 20px;
            transition: all .3s ease-in-out; }
  .template--6--navbar .header--menu-burguer {
    position: relative;
    z-index: 200; }
    .template--6--navbar .header--menu-burguer .line {
      background: var(--c-primary); }
  .template--6--navbar .list-of-social-medias:after {
    display: none; }
