*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

@media (max-width: 860px) {
  .wrapper {
    display: block; }
    .wrapper .menu {
      width: 30px;
      position: absolute;
      right: 30px;
      bottom: 0px;
      z-index: 200;
      -webkit-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      -webkit-box-shadow: 0 0 0 0 #fff, 0 0 0 0 #fff;
      box-shadow: 0 0 0 0 #fff, 0 0 0 0 #fff;
      cursor: pointer; }
    .wrapper .hamburger {
      width: 30px;
      height: 3px;
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      background: #fff;
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out; }
      .wrapper .hamburger:before, .wrapper .hamburger:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background: #fff;
        -webkit-transition: .5s ease-in-out;
        transition: .5s ease-in-out; }
      .wrapper .hamburger:before {
        top: -10px; }
      .wrapper .hamburger:after {
        bottom: -10px; } }

@media (max-width: 860px) {
  .wrapper input {
    display: none; }
  .wrapper input:checked + .menu {
    bottom: initial;
    right: 30px;
    z-index: 10001;
    background: transparent; }
    .wrapper input:checked + .menu .hamburger {
      background: var(--c-primary);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
      .wrapper input:checked + .menu .hamburger:after, .wrapper input:checked + .menu .hamburger:before {
        bottom: 0;
        background: var(--c-primary);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); }
      .wrapper input:checked + .menu .hamburger:after {
        bottom: 0; }
      .wrapper input:checked + .menu .hamburger:before {
        top: 0; }
  .wrapper input:disabled + menu .hamburger {
    background: #575757; }
    .wrapper input:disabled + menu .hamburger:after, .wrapper input:disabled + menu .hamburger:before {
      background: #575757; }
  .wrapper input:checked + .menu + ul {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    opacity: 1;
    background: #fff; } }

@media (max-width: 850px) and (orientation: landscape) {
  .wrapper ul {
    padding: 70px 20px 20px !important;
    justify-content: flex-start !important; } }

@media (min-width: 849px) {
  .wrapper input {
    display: none; } }

@media (max-width: 860px) {
  .wrapper ul {
    width: 0vw;
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: center;
    top: 0%;
    left: 0%;
    padding: 20px;
    margin: 0px;
    overflow: scroll;
    z-index: 200;
    opacity: 0;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    -webkit-transition: .25s 0s ease-in-out;
    transition: .25s 0s ease-in-out; }
  .wrapper li {
    margin-bottom: 1em;
    list-style-type: none;
    text-align: center; }
  .wrapper a {
    display: block;
    margin-bottom: 5px;
    font-size: 25px;
    color: var(--c-primary);
    text-decoration: none; } }

.wrapper .social-li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2%;
  position: relative;
  top: 10%; }
  .wrapper .social-li .items {
    padding: 15px; }
    .wrapper .social-li .items .fa {
      padding: 0; }
