@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

:root {
  --c-primary: #BF1A2F;
  --c-secondary: #262D52; }

* {
  font-family: "Nunito Sans", sans-serif; }

.template--7 {
  width: 100%; }
  .template--7 .btn-basic {
    width: 50%;
    margin: 20px auto;
    padding: 15px 40px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background: var(--c-secondary); }
    .template--7 .btn-basic:hover {
      cursor: pointer;
      background: var(--c-primary); }
    .template--7 .btn-basic:disabled {
      background: #ccc !important;
      cursor: not-allowed; }
  .template--7 .title-section-content h3 {
    margin: 0;
    color: var(--c-secondary); }
  .template--7 input:-webkit-autofill,
  .template--7 input:-webkit-autofill:hover,
  .template--7 input:-webkit-autofill:focus,
  .template--7 input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important; }
