.btn-container {
  position: fixed;
  bottom: 0;
  right: 0px;
  margin: 1em;
  z-index: 4; }
  .btn-container.show .fab-wrapper:not(:last-child) {
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: 15px auto 0; }
  @media (max-width: 768px) {
    .btn-container {
      margin: .1em; } }

.btn-container .fab-wrapper {
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out; }
  .btn-container .fab-wrapper:not(:last-child) {
    cursor: initial;
    margin: 20px auto 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px); }
