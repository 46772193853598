.confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #0c101a;
  color: #fafafa;
  height: 100vh; }
  .confirm-container h1 {
    margin: 0; }
  .confirm-container .confirm-loader {
    background-color: #0c101a; }

.confirm-message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  padding: 0 10px; }
  .confirm-message-content h1 {
    font-size: 44px;
    color: #FE7800;
    text-align: center; }
  .confirm-message-content p {
    font-size: 24px;
    text-align: center;
    color: white; }
