@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--videos {
  padding: 0px 0 100px; }
  .template--7--videos .flex-carousel-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }

.template--7--videos-container {
  width: 1440px;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto; }
  .template--7--videos-container .carousel-main .slick-slide {
    padding: 0; }
    .template--7--videos-container .carousel-main .slick-slide:hover, .template--7--videos-container .carousel-main .slick-slide:focus, .template--7--videos-container .carousel-main .slick-slide:active {
      outline: none; }
  .template--7--videos-container .slick-list {
    width: 100%; }
  .template--7--videos-container .arrowPrev,
  .template--7--videos-container .arrowNext {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
  .template--7--videos-container .arrowPrev {
    left: 0px; }
    @media (min-width: 500px) {
      .template--7--videos-container .arrowPrev {
        left: 0px; } }
    .template--7--videos-container .arrowPrev svg {
      transform: translateY(-50%) rotate(90deg); }
      @media (max-width: 400px) {
        .template--7--videos-container .arrowPrev svg {
          width: 30px; } }
  .template--7--videos-container .arrowNext {
    right: 0px; }
    @media (min-width: 500px) {
      .template--7--videos-container .arrowNext {
        right: 0px; } }
    .template--7--videos-container .arrowNext svg {
      transform: translateY(-50%) rotate(-90deg); }
      @media (max-width: 400px) {
        .template--7--videos-container .arrowNext svg {
          width: 30px; } }
  .template--7--videos-container .slick-slide:focus, .template--7--videos-container .slick-slide:active, .template--7--videos-container .slick-slide:visited {
    outline: none;
    box-shadow: none; }

/* Slider */
.generic--slider {
  width: 100%;
  padding: 0; }
  .generic--slider .slider-wrapper {
    width: 90%; }
  .generic--slider .generic--slider-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center; }
    @media (max-width: 850px) {
      .generic--slider .generic--slider-item {
        flex-flow: column wrap; } }
