@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--effect-message {
  width: 60%;
  min-width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  transform: translate(-50%, -50%);
  color: white; }
  .template--5--effect-message .phrase {
    margin: 0;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase; }
    @media (min-width: 600px) {
      .template--5--effect-message .phrase {
        font-size: 18px; } }
    @media (min-width: 990px) {
      .template--5--effect-message .phrase {
        font-size: 20px; } }
    @media (min-width: 1300px) {
      .template--5--effect-message .phrase {
        font-size: 24px; } }
  .template--5--effect-message .autor {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 10px;
    position: relative;
    color: white;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .template--5--effect-message .autor:before {
      content: '';
      width: 100px;
      height: 2px;
      position: relative;
      margin-right: 8px;
      background-color: var(--c-primary); }
