@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--curriculum {
  width: 1140px;
  max-width: 100%;
  padding: 1rem;
  margin: 40px auto 0; }
  .template--7--curriculum .curriculum-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    @media (max-width: 768px) {
      .template--7--curriculum .curriculum-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        align-content: center; } }
    .template--7--curriculum .curriculum-content .curriculum-info {
      width: 90%;
      padding: 0; }
      @media (max-width: 1013px) {
        .template--7--curriculum .curriculum-content .curriculum-info {
          width: 100%; } }
    .template--7--curriculum .curriculum-content .curriculum-contact {
      width: calc(250px); }
