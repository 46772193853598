.page-409 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #0c101a; }

.page-409-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  overflow: hidden; }

.title-409 {
  font-size: 44px;
  font-family: 'Montserrat', sans-serif;
  color: #FE7800;
  text-align: center; }

.text-409 {
  font-size: 24px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white; }

.btn-back-to-home {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: #FE7800;
  font-weight: bold; }
