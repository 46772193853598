.modal-coach {
  display: flex;
  align-items: flex-start;
  overflow-y: auto; }
  .modal-coach .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    background: linear-gradient(148.74deg, #FE7800 -6.85%, #DE3A06 80.05%);
    box-shadow: -10px 19px 57px 3px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    top: 55px;
    overflow: visible;
    max-width: 500px;
    cursor: auto; }
    .modal-coach .main-container .div-btn {
      height: 51px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 1.1rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      color: #000000; }
      .modal-coach .main-container .div-btn .tab_item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 51px;
        border-radius: 10px 10px 0 0;
        margin-bottom: 10px;
        border-bottom: 3px solid white;
        cursor: pointer;
        transition: border-color 0.2s; }
        .modal-coach .main-container .div-btn .tab_item .item {
          font-size: clamp(14px, 1.3vw, 18px);
          font-weight: bold;
          color: #c8cfd6; }
        .modal-coach .main-container .div-btn .tab_item:hover {
          border-color: #c8cfd6; }
      .modal-coach .main-container .div-btn .tab_item_selected {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 51px;
        border-radius: 10px 10px 0 0;
        margin-bottom: 10px;
        border-bottom: 3px solid white;
        cursor: pointer;
        transition: border-color 0.2s;
        border-color: #FE7800; }
        .modal-coach .main-container .div-btn .tab_item_selected .item {
          color: #000000; }
    .modal-coach .main-container .container-close {
      position: absolute;
      top: -44px;
      right: -2px;
      width: 40px;
      padding: 0;
      height: 45px;
      cursor: pointer; }
    .modal-coach .main-container .container-close-dark {
      display: none !important; }
    .modal-coach .main-container .container-coach {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-items: center;
      max-width: 1350px;
      width: 90%; }
      .modal-coach .main-container .container-coach .modal-coach-icon-ok {
        position: absolute;
        top: -2px;
        right: 9px;
        width: 100%;
        display: flex;
        justify-content: flex-end; }
      .modal-coach .main-container .container-coach img {
        margin-top: 25px;
        margin-bottom: 10px;
        border-radius: 100%;
        width: 65%;
        max-width: 155px;
        max-height: 155px;
        object-fit: fill; }
      .modal-coach .main-container .container-coach span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px; }
      .modal-coach .main-container .container-coach .rating-stars {
        margin-bottom: 10px; }
      .modal-coach .main-container .container-coach .container-flag {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 0.6rem;
        text-align: center;
        color: #FFFFFF;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 7px;
        max-width: 300px;
        margin-bottom: 10px; }
      .modal-coach .main-container .container-coach button {
        max-width: 300px;
        background: #15161B;
        border-radius: 10px;
        color: #FFFFFF;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 8px;
        width: 90%;
        cursor: pointer;
        margin: 10px; }
        .modal-coach .main-container .container-coach button:hover {
          transform: scale(1.05); }
    .modal-coach .main-container .container-contact {
      background: #FFFFFF;
      border-radius: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative; }
      .modal-coach .main-container .container-contact h1 {
        width: 100%;
        color: #000;
        font-size: 1.1rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #000000; }
      .modal-coach .main-container .container-contact .container-form-contact {
        display: flex;
        flex-direction: column; }
        .modal-coach .main-container .container-contact .container-form-contact .form-item {
          display: flex;
          justify-content: space-between;
          flex-direction: row; }
          .modal-coach .main-container .container-contact .container-form-contact .form-item .input-contanct label {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 0.7rem;
            text-align: start;
            color: rgba(0, 0, 0, 0.4); }
          .modal-coach .main-container .container-contact .container-form-contact .form-item .input-contanct.double-item {
            width: 48%; }
          .modal-coach .main-container .container-contact .container-form-contact .form-item .input-contanct input, .modal-coach .main-container .container-contact .container-form-contact .form-item .input-contanct textarea {
            background: #EEEEEE;
            border: none;
            padding: 10px;
            border-radius: 5px;
            outline: none; }
          .modal-coach .main-container .container-contact .container-form-contact .form-item .input-contanct textarea {
            height: 115px; }
      .modal-coach .main-container .container-contact .button-contact {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px; }
        .modal-coach .main-container .container-contact .button-contact button {
          background: #FE7800;
          border-radius: 10px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 42px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #FFFFFF;
          width: 90%;
          text-align: center;
          justify-content: center;
          cursor: pointer; }
          .modal-coach .main-container .container-contact .button-contact button.disable {
            cursor: not-allowed;
            background-color: #bbbbbb; }
  @media (min-width: 1240px) {
    .modal-coach .main-container {
      flex-direction: row;
      min-width: 65%;
      min-width: 900px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .modal-coach .main-container .div-btn {
        height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 1.1rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        color: #000000; }
        .modal-coach .main-container .div-btn .tab_item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          height: 51px;
          border-radius: 10px 10px 0 0;
          margin-bottom: 10px;
          border-bottom: 3px solid white;
          cursor: pointer;
          transition: border-color 0.2s; }
          .modal-coach .main-container .div-btn .tab_item .item {
            font-size: clamp(14px, 1.3vw, 18px);
            font-weight: bold;
            color: #c8cfd6; }
          .modal-coach .main-container .div-btn .tab_item:hover {
            border-color: #c8cfd6; }
        .modal-coach .main-container .div-btn .tab_item_selected {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          height: 51px;
          border-radius: 10px 10px 0 0;
          margin-bottom: 10px;
          border-bottom: 3px solid white;
          cursor: pointer;
          transition: border-color 0.2s;
          border-color: #FE7800; }
          .modal-coach .main-container .div-btn .tab_item_selected .item {
            color: #000000; }
      .modal-coach .main-container .container-close {
        display: none !important; }
      .modal-coach .main-container .container-close-dark {
        display: flex !important;
        position: absolute;
        top: 0px;
        right: 10px;
        cursor: pointer; }
      .modal-coach .main-container .container-coach {
        width: 50%;
        margin: 0 54px 23px 40px;
        max-width: 400px; }
        .modal-coach .main-container .container-coach .modal-coach-icon-ok {
          width: min-content;
          left: 380px; }
        .modal-coach .main-container .container-coach .container-flag {
          font-size: 0.8rem; }
      .modal-coach .main-container .container-contact {
        width: 70%;
        padding: 15px 40px 40px 40px;
        height: 585px; }
        .modal-coach .main-container .container-contact .container-form-contact .form-item {
          margin-bottom: 5px; }
      .modal-coach .main-container .phone-input {
        margin-top: 10px; } }

.container-wrapper-success {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  top: 0px;
  left: 0px; }
  .container-wrapper-success .wrapper-success {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 19px;
    background-color: rgba(0, 0, 0, 0.69);
    cursor: pointer; }
  .container-wrapper-success .confirm {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    z-index: 3;
    max-width: 200px;
    padding: 5px;
    align-items: center; }
    .container-wrapper-success .confirm.request {
      max-width: 265px;
      padding: 20px; }
    .container-wrapper-success .confirm .confirm-icon {
      position: relative; }
      .container-wrapper-success .confirm .confirm-icon.icon-error {
        left: 10px;
        top: 5px; }
      .container-wrapper-success .confirm .confirm-icon.icon-success {
        left: 5%; }
    .container-wrapper-success .confirm p {
      font-size: 0.8rem;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      text-align: center;
      color: #15161B; }

.phone-input {
  text-align: start;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600; }
  .phone-input .form-control {
    width: 100%;
    background-color: #EEEEEE;
    color: #202020;
    border: none; }
  .phone-input .flag-dropdown {
    background-color: #EEEEEE;
    border: none; }
    .phone-input .flag-dropdown .selected-flag {
      background-color: #EEEEEE; }
  .phone-input label {
    margin-left: 5px;
    margin-bottom: 20px;
    line-height: 20px;
    font-family: "Montserrat";
    font-size: 0.7rem;
    text-align: start;
    color: #9D9D9D; }
  .phone-input .flag {
    border-radius: 0px; }
  .phone-input .country-list {
    color: #202020;
    font-weight: 500; }
  .phone-input .country-list .country {
    display: flex;
    padding: 5px 0 0 0;
    background: #eee; }
    .phone-input .country-list .country .flag {
      margin-left: 10px;
      margin-top: 3;
      padding: 10px 10px 0 0; }
    .phone-input .country-list .country .country-name {
      padding: 0; }
    .phone-input .country-list .country .dial-code {
      color: #202020; }
    .phone-input .country-list .country:hover {
      background-color: #030303;
      color: #FFF; }
      .phone-input .country-list .country:hover .dial-code {
        color: #FFF; }
  .phone-input .react-tel-input .flag-dropdown.open .selected-flag {
    background: #eee; }
  .phone-input .country-list .country.highlight {
    background: #ffffff; }
    .phone-input .country-list .country.highlight:hover {
      background-color: #030303;
      color: #FFF; }
      .phone-input .country-list .country.highlight:hover .dial-code {
        color: #FFF; }
