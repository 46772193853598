.info-card {
  display: flex;
  justify-content: center; }
  .info-card.notShow {
    display: none; }
  .info-card .info-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding-left: 10px;
    text-align: left;
    line-height: 26px; }
  .info-card .info-title {
    font-size: 30px;
    font-weight: bold; }
  .info-card .content {
    font-size: 16px;
    font-weight: 350;
    color: #505152; }

.icon-wrapper {
  width: 35px;
  display: flex;
  align-items: center; }
  .icon-wrapper svg {
    width: 100%;
    height: 100%;
    fill: var(--c-primary); }

.info-edit {
  width: 30%;
  height: auto;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #fff;
  font-weight: inherit;
  font-size: inherit;
  text-align: center;
  color: inherit; }
  .info-edit:focus {
    outline: none;
    border: 1px solid var(--c-primary); }
