.toast-close {
  cursor: pointer; }
  .toast-close svg > g {
    fill: #757575; }

.toast-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.toast-message {
  padding-left: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #757575;
  text-transform: uppercase; }
