@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--biography .biography-info {
  padding: 40px 0;
  color: #696969; }
  @media (min-width: 990px) {
    .template--5--biography .biography-info {
      padding: 30px; } }

.template--5--biography .biography-box-1 h4 {
  margin: 0px 0 10px 0;
  font-size: 26px;
  font-weight: 700; }

.template--5--biography .biography-box-1 p {
  margin: 0;
  font-size: 20px;
  font-weight: normal; }

.template--5--biography .biography-box-1:after {
  content: '';
  width: 60%;
  height: 1px;
  display: block;
  margin: 20px auto 0;
  background: var(--c-primary);
  filter: brightness(0.8); }

.template--5--biography .biography-box-2 {
  padding-top: 20px; }
  .template--5--biography .biography-box-2 h5 {
    margin: 0px;
    font-size: 20px;
    font-weight: 700; }
  .template--5--biography .biography-box-2 p {
    margin: 0;
    font-size: 20px;
    font-weight: normal; }
