.post-main-content {

    .blog-header {
        background-color: white;
        width: 100%;
        padding: 0 15%;

        .logo-oseu-coach {
            display: flex;
    
            svg path{
                fill: black;
            }
    
            .highlight {
                fill: #FE7800
            }
        }

        .icon-burger {
            rect {
                fill: black;
            }
        }

        .mobile-menu-open {
            li {
                margin-left: 0;
            }

            .icon-burger {
                rect {
                    fill: white;
                }
            }

            .navs-group ul {
                .menu-item {
                    &.active {
                        font-weight: 400;
                        color: white;
                    }
                }
            }

            .icon-menu-item {
                path {
                    stroke: #000;
                }
            }
        }

        @media (max-width: 750px) {
            height: 66px;
            padding: 16px 13px;
            display: flex;
            align-items: center;

            .new-header-container-image {
                align-items: center;

                .logo-oseu-coach {
                    width: 156px;
                    height: 27px;
                }
            }

            .menu-hamburger, .icon-burger {
                top: 3px;
            }
        }
    }

    .post-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        padding: 0 15% 100px;
    
        .category-title {
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 32px;
            font-weight: 700;
            margin-top: 0;
        }      
    
        @media (max-width: 900px) {
            padding: 0 10% 100px;
        }
    
        @media (max-width: 660px) {
            padding: 0 5% 100px;
    
            .category-title {
                font-size: 28px;
            }
        }
        
        @media (max-width: 460px) {
            .blog-header {
                height: 110px;
            }
    
            padding: 0 0 100px;
        }
    
        .infinite-scroll-component__outerdiv {
            width: 100%;
        }
    }
}

