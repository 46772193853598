@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--navbar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  z-index: 2;
  color: white; }
  .template--5--navbar h2 {
    margin: 0;
    font-size: 26px;
    font-weight: 400; }
    .template--5--navbar h2.phone {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      font-size: 16px; }
      .template--5--navbar h2.phone svg {
        margin-right: 10px; }
  .template--5--navbar .left-header {
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: space-between; }
  .template--5--navbar .select-language-hamburguer-template-5 {
    width: 90px; }
  .template--5--navbar .header--menu-burguer {
    opacity: 1;
    visibility: visible;
    height: 30px;
    display: grid;
    align-items: center; }
  .template--5--navbar .line {
    width: 30px;
    display: flex;
    height: 5px;
    background-color: white;
    border-radius: 5px; }
    @media (max-width: 860px) {
      .template--5--navbar .line {
        display: flex;
        background-color: bisque; } }

.header--menu-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center; }
  .header--menu-list li {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }
    @media (max-width: 980px) {
      .header--menu-list li {
        padding: 10px 15px; } }
    @media (max-width: 920px) {
      .header--menu-list li {
        padding: 10px 10px; } }
    .header--menu-list li:hover {
      cursor: pointer;
      color: var(--c-primary); }
  .header--menu-list .select-language {
    min-width: 140px;
    margin-left: -10px;
    margin-bottom: 3px; }
