@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.navbar-menu {
  width: 100%;
  position: relative;
  margin-top: 20px;
  color: #fff; }
  @media (max-width: 1010px) {
    .navbar-menu {
      padding: 0 20px; } }
  @media (max-width: 990px) {
    .navbar-menu {
      padding: 0 0; } }
  @media (max-width: 860px) {
    .navbar-menu {
      margin-top: 20px;
      padding: 9px 20px; } }

.navbar-menu .home-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0px 50px; }

.navbar-menu .header-logo {
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
  line-height: .9em; }

.navbar-menu .header-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center; }
  .navbar-menu .header-menu .select-language {
    width: 90px;
    margin-left: -10px; }
    @media (max-width: 860px) {
      .navbar-menu .header-menu .select-language {
        margin-right: 20px; } }

.header-item-menu {
  margin-right: 30px; }
  .header-item-menu:last-child {
    margin-right: 0; }
  @media (max-width: 860px) {
    .header-item-menu {
      display: none; } }
  .header-item-menu a {
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    text-decoration: none; }
    .header-item-menu a:after {
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      visibility: hidden;
      margin: 0 auto;
      border-bottom: solid 2px var(--c-primary);
      opacity: 0;
      transition: width .4s ease-in-out; }
    .header-item-menu a:hover:after {
      width: 100%;
      visibility: visible;
      opacity: 1;
      transition: width .4s ease-in-out; }

.navbar-menu .header-whatsapp i,
.navbar-menu .header-whatsapp span {
  display: inline-table;
  padding: 0 0 0 5px;
  font-weight: 100;
  font-size: 14px;
  color: #fff; }

@media (max-width: 860px) {
  .navbar-menu .header-whatsapp {
    display: none; } }

.select-language-hamburguer {
  width: 100px;
  margin-right: 10px; }
  @media (min-width: 860px) {
    .select-language-hamburguer {
      display: none; } }
