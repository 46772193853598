.group-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.background-container {
  position: relative;
  z-index: 10; }
  .background-container .flags-infos {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    padding: 5px 8px;
    width: max-content;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.7rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    color: #ffff; }

.icon-flag {
  margin-right: 5px; }
