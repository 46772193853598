.coaches-list-container-search {
  display: none;
  position: sticky;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #15161B;
  max-width: 380px;
  min-height: 100vh; }
  .coaches-list-container-search .container-image {
    padding: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
    display: flex; }
    .coaches-list-container-search .container-image .logo-oseu-coach {
      cursor: pointer; }
  .coaches-list-container-search .search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 10px; }
  @media (min-width: 800px) {
    .coaches-list-container-search .search {
      width: 103%; } }
  @media (min-width: 750px) {
    .coaches-list-container-search {
      display: flex; } }
