@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5-photo-card {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
  position: relative;
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .template--5-photo-card .photo-subtitle {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 40px;
    z-index: 2;
    font-size: 18px;
    font-weight: 400;
    color: #fff; }
  .template--5-photo-card .icon-play-float {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2; }
  .template--5-photo-card .background-effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .template--5-photo-card .photo-icon-zoom {
    width: 53px;
    height: 56px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    background-color: var(--c-primary);
    transition: all .5s; }
  .template--5-photo-card svg {
    transition: all .5s; }
  .template--5-photo-card:hover {
    cursor: pointer; }
    .template--5-photo-card:hover .photo-icon-zoom {
      width: 100%;
      height: 100%; }
      .template--5-photo-card:hover .photo-icon-zoom svg {
        width: 60px;
        height: 60px; }
