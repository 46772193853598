.new-home-footer {
  display: flex;
  align-items: center;
  height: 180px;
  width: 100%;
  padding: 0 12vw; }
  @media (max-width: 768px) {
    .new-home-footer {
      flex-direction: column;
      justify-content: center;
      padding: 5vw; }
      .new-home-footer .logo-febracis-footer {
        order: 1;
        flex: auto; }
      .new-home-footer .copyright-info, .new-home-footer .terms-info {
        font-size: 12px;
        flex: auto; }
      .new-home-footer .copyright-info .copyright-text span {
        text-align: center;
        white-space: nowrap;
        display: inline;
        width: auto; } }

.copyright-info, .terms-info {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  flex: 4; }

.logo-febracis-footer {
  flex: 1; }

.copyright-info {
  width: 100%; }
  .copyright-info .copyright-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .copyright-info .copyright-text span:first-child {
      margin-right: 5px; }
    .copyright-info .copyright-text span {
      display: block;
      width: 100%; }

.terms-info {
  margin: 0;
  text-align: right;
  font-weight: bold; }
