.benefits-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 370px;
  background-color: #15161B; }
  .benefits-section::after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: 5%;
    width: 100px;
    height: 100px;
    background: linear-gradient(90deg, #FE7800, #FE4000);
    border-radius: 50%;
    filter: blur(3px); }
  @media (max-width: 750px) {
    .benefits-section {
      height: 100%;
      padding: 80px 20px; }
      .benefits-section::after {
        display: none; }
      .benefits-section .benefits-title {
        display: none; }
      .benefits-section .benefits-list {
        flex-wrap: wrap; }
      .benefits-section .benefit-item {
        width: auto; }
      .benefits-section .benefit-icon {
        margin: 0 auto;
        width: 70px;
        height: 70px; } }

.benefits-title {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 32px; }
  .benefits-title span {
    color: #FE7800; }

.benefits-list {
  display: flex;
  align-items: center;
  justify-content: center; }

.benefit-item {
  margin: 20px;
  width: 100%; }
  .benefit-item .benefit-text {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: white; }
  @media (max-width: 750px) {
    .benefit-item .benefit-text {
      font-size: 12px;
      width: 130px; } }
