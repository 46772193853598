.post-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2px;
    width: 270px;
    height: 380px;
    margin: 0 10px;

    &:hover {
        .card-img::after {
            border: solid 1px #FE7800;
            background: #FE7800;
        }
    }

    .card-img {
        display: flex;
        flex-direction: column;
        color: #FBFBFB;

        width: 268px;
        height: 214px;
        gap: 14px;
    }

    .card-img::after {
        align-self: center;
        content: ' ';
        width: 100%;
        height: 1px;
        border-radius: 100px;
        background: #FBFBFB;
    }

    .card-photo {
        border-radius: 6px;
        width: 270px;
        height: 194px;
        object-fit: cover;
    }

    .card-title {
        margin: 15px 0px 24px 0px;

        font-family: Montserrat, sans-serif;
        font-size: 18px;
        color: #FE7800;
        font-weight: 700;
        width: 100%;
        height: 45px;
        
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
    }

    .name-and-date {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 10px;
        width: 100%;
        height: 45px;

        .card-coach-name, .card-date {
            display: block !important;
            color: #FBFBFB;
            font-family: Montserrat, sans-serif;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
            height: 15px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: pre-wrap;
        } 
    }
    
    .card-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        cursor: pointer;

        border-radius: 14.5px;
        background: #FE7800;

        color: #FBFBFB;
        font-family: Montserrat, sans-serif;
        font-size: 11px;
        font-weight: 600;

        width: 126px;
        height: 29px;

        span {
            display: flex;
            justify-content: center;
            width: 60px;
            height: 12px;
            font-size: 11px;
        }

        &:hover {
            transition: all 0.5s;
            background: #B24500;
        }

        .link {
            display: flex;
        }
    }

    .lazy-placeholder {
        border-radius: 6px;
    }

    @media (max-width: 300px) {
        width: 200px;

        .card-img {
            width: 200px;

            img {
                width: 100%;
            }
        }
    }
}
