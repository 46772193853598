@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7-photo-card {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  overflow: hidden; }
  .template--7-photo-card figure {
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
    margin: 0; }
  .template--7-photo-card img {
    height: 245px;
    width: 100%;
    object-fit: cover;
    filter: brightness(60%); }
  @media (max-width: 750px) {
    .template--7-photo-card {
      width: 100%; } }
  .template--7-photo-card .photo-icon-zoom {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-color: var(--c-primary);
    transition: all .5s;
    z-index: 3; }
  .template--7-photo-card svg {
    transition: all .5s; }
  .template--7-photo-card:hover {
    cursor: pointer; }
    .template--7-photo-card:hover .photo-icon-zoom {
      width: 100%;
      height: 100%;
      opacity: 1; }
      .template--7-photo-card:hover .photo-icon-zoom svg {
        width: 60px;
        height: 60px; }
  .template--7-photo-card figcaption {
    margin-top: 15px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: var(--c-secondary);
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #fff; }
    .template--7-photo-card figcaption span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; }
