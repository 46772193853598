@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--banner {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  background: #fff; }
  @media (min-width: 750px) {
    .template--6--banner {
      height: 600px; } }
  .template--6--banner::before {
    content: '';
    width: 70vh;
    height: 100%;
    position: absolute;
    background-color: var(--c-primary);
    border-radius: 100%;
    top: -20vh;
    right: -10vh;
    z-index: 2;
    opacity: 0.1; }
  .template--6--banner .background-image {
    width: 100%;
    height: 100%;
    margin-top: 22px;
    position: relative; }
    @media (min-width: 750px) {
      .template--6--banner .background-image {
        height: 500px; } }
    .template--6--banner .background-image .background-image-blur {
      width: 92%;
      height: 200px;
      margin: 0 auto;
      position: relative;
      background-size: cover;
      background-position: center; }
      @media (min-width: 750px) {
        .template--6--banner .background-image .background-image-blur {
          height: 100%; } }
      .template--6--banner .background-image .background-image-blur:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        mix-blend-mode: multiply;
        background: var(--c-primary);
        opacity: .5; }
  .template--6--banner .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
    padding: 3vh 3vw;
    z-index: 2; }
