@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.modal-active {
  overflow: hidden; }

.modal-overlay-div {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

.modal-content-div {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: visible;
  padding: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-align: center; }
  .modal-content-div:after {
    content: '';
    height: 100%;
    display: inline-block;
    margin-left: -.05em;
    vertical-align: middle; }

.modal-dialog-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  overflow: hidden;
  outline: 0;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box; }

.modal-add-image,
.modal-edit-gallery,
.modal-add-video,
.modal-edit-video,
.modal-confirmation,
.modal-edit-avatar,
.modal-edit-testimonies {
  width: 550px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  background: #fff; }
  .modal-add-image .item-modal,
  .modal-edit-gallery .item-modal,
  .modal-add-video .item-modal,
  .modal-edit-video .item-modal,
  .modal-confirmation .item-modal,
  .modal-edit-avatar .item-modal,
  .modal-edit-testimonies .item-modal {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    margin-top: 20px;
    font-weight: bold;
    color: #616161; }
  .modal-add-image input, .modal-add-image textarea,
  .modal-edit-gallery input,
  .modal-edit-gallery textarea,
  .modal-add-video input,
  .modal-add-video textarea,
  .modal-edit-video input,
  .modal-edit-video textarea,
  .modal-confirmation input,
  .modal-confirmation textarea,
  .modal-edit-avatar input,
  .modal-edit-avatar textarea,
  .modal-edit-testimonies input,
  .modal-edit-testimonies textarea {
    width: 90%;
    padding: 10px;
    margin: 10px auto 0;
    border: 1px solid rgba(242, 242, 242, 0.9); }
    .modal-add-image input:active, .modal-add-image input:focus, .modal-add-image textarea:active, .modal-add-image textarea:focus,
    .modal-edit-gallery input:active,
    .modal-edit-gallery input:focus,
    .modal-edit-gallery textarea:active,
    .modal-edit-gallery textarea:focus,
    .modal-add-video input:active,
    .modal-add-video input:focus,
    .modal-add-video textarea:active,
    .modal-add-video textarea:focus,
    .modal-edit-video input:active,
    .modal-edit-video input:focus,
    .modal-edit-video textarea:active,
    .modal-edit-video textarea:focus,
    .modal-confirmation input:active,
    .modal-confirmation input:focus,
    .modal-confirmation textarea:active,
    .modal-confirmation textarea:focus,
    .modal-edit-avatar input:active,
    .modal-edit-avatar input:focus,
    .modal-edit-avatar textarea:active,
    .modal-edit-avatar textarea:focus,
    .modal-edit-testimonies input:active,
    .modal-edit-testimonies input:focus,
    .modal-edit-testimonies textarea:active,
    .modal-edit-testimonies textarea:focus {
      outline: none; }
  .modal-add-image input[type="file"],
  .modal-edit-gallery input[type="file"],
  .modal-add-video input[type="file"],
  .modal-edit-video input[type="file"],
  .modal-confirmation input[type="file"],
  .modal-edit-avatar input[type="file"],
  .modal-edit-testimonies input[type="file"] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0; }
  .modal-add-image label,
  .modal-edit-gallery label,
  .modal-add-video label,
  .modal-edit-video label,
  .modal-confirmation label,
  .modal-edit-avatar label,
  .modal-edit-testimonies label {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    color: #616161; }
  .modal-add-image .modal-footer,
  .modal-edit-gallery .modal-footer,
  .modal-add-video .modal-footer,
  .modal-edit-video .modal-footer,
  .modal-confirmation .modal-footer,
  .modal-edit-avatar .modal-footer,
  .modal-edit-testimonies .modal-footer {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin: 24px auto; }
    .modal-add-image .modal-footer .button,
    .modal-edit-gallery .modal-footer .button,
    .modal-add-video .modal-footer .button,
    .modal-edit-video .modal-footer .button,
    .modal-confirmation .modal-footer .button,
    .modal-edit-avatar .modal-footer .button,
    .modal-edit-testimonies .modal-footer .button {
      background: #f57010;
      border-color: #f57010; }
      .template-2 .modal-add-image .modal-footer .button, .template-2
      .modal-edit-gallery .modal-footer .button, .template-2
      .modal-add-video .modal-footer .button, .template-2
      .modal-edit-video .modal-footer .button, .template-2
      .modal-confirmation .modal-footer .button, .template-2
      .modal-edit-avatar .modal-footer .button, .template-2
      .modal-edit-testimonies .modal-footer .button {
        background: #0071a5 !important;
        border-color: #0071a5 !important; }
      .template-3 .modal-add-image .modal-footer .button, .template-3
      .modal-edit-gallery .modal-footer .button, .template-3
      .modal-add-video .modal-footer .button, .template-3
      .modal-edit-video .modal-footer .button, .template-3
      .modal-confirmation .modal-footer .button, .template-3
      .modal-edit-avatar .modal-footer .button, .template-3
      .modal-edit-testimonies .modal-footer .button {
        background: #ab1f24;
        border-color: #ab1f24; }
      .template-4 .modal-add-image .modal-footer .button, .template-4
      .modal-edit-gallery .modal-footer .button, .template-4
      .modal-add-video .modal-footer .button, .template-4
      .modal-edit-video .modal-footer .button, .template-4
      .modal-confirmation .modal-footer .button, .template-4
      .modal-edit-avatar .modal-footer .button, .template-4
      .modal-edit-testimonies .modal-footer .button {
        background: #098846;
        border-color: #098846; }

.modal-edit-avatar input[type="file"] {
  display: none; }

.modal-edit-avatar .ReactCrop {
  background-color: transparent; }

.box-title-modal {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #f57010; }
  .box-title-modal h4 {
    margin: 0 0 20px;
    color: #fff;
    font-size: 30px; }
  .template-2 .box-title-modal {
    background: #0071a5; }
  .template-3 .box-title-modal {
    background: #ab1f24; }
  .template-4 .box-title-modal {
    background: #098846; }

.box-alert-modal {
  width: 60%;
  margin: 40px auto;
  position: relative;
  padding: 20px 10px 10px;
  border: 1px solid #f57010;
  border-radius: 5px; }
  .box-alert-modal p {
    margin: 0;
    color: #616161; }
  .template-2 .box-alert-modal {
    border: 1px solid #0071a5; }
  .template-3 .box-alert-modal {
    border: 1px solid #ab1f24; }
  .template-4 .box-alert-modal {
    border: 1px solid #098846; }
  .box-alert-modal .icon-alert {
    width: 36px;
    height: 36px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background: #fff; }
    .box-alert-modal .icon-alert path {
      fill: #f57010; }
      .template-2 .box-alert-modal .icon-alert path {
        fill: #0071a5; }
      .template-3 .box-alert-modal .icon-alert path {
        fill: #ab1f24; }
      .template-4 .box-alert-modal .icon-alert path {
        fill: #098846; }

.modal-add-video .modal-footer button {
  background: #f57010;
  border: 1px solid #f57010; }
  .template-2 .modal-add-video .modal-footer button {
    background: #0071a5;
    border: 1px solid #0071a5; }
  .template-3 .modal-add-video .modal-footer button {
    background: #ab1f24;
    border: 1px solid #ab1f24; }
  .template-4 .modal-add-video .modal-footer button {
    background: #098846;
    border: 1px solid #098846; }
