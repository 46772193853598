@import '../../../../assets/css/index';

:root {
    --c-primary: #f57010;
}

.old-template-contact-section {
    width: 100%;
    @include displayFlex(column, wrap, space-around, center, center);
    padding: 1rem;
    margin: 0 auto;
}