@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--banner {
  width: 100%;
  height: 450px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  background: #000; }
  @media (min-width: 650px) {
    .template--5--banner {
      height: 600px; } }
  .template--5--banner .background-image-blur {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top; }
  .template--5--banner .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7); }
    @media (min-width: 990px) {
      .template--5--banner .content::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -57vh;
        z-index: 2;
        border-right: 45vh solid transparent;
        border-left: 55vh solid transparent;
        border-bottom: 58vh solid var(--c-primary);
        border-top: 55vh solid transparent; } }
  .template--5--banner .list-of-social-medias svg #border {
    display: none; }
