.home-intro {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
  padding: 0 12vw;
  width: 100%;
  min-height: 400px;
  z-index: 1; }
  .home-intro .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 35%; }
    .home-intro .left-content p {
      color: white;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
      margin: 0; }
    .home-intro .left-content .main-paragraph {
      font-size: clamp(38px, 2.5vw, 48px);
      margin: 20px 0; }
    .home-intro .left-content .sub-paragraph {
      font-size: clamp(18px, 1.3vw, 24px);
      line-height: 30px; }
    .home-intro .left-content span {
      font-weight: bold;
      color: #FE7800; }
    .home-intro .left-content .btn-find-your-coach {
      display: none;
      margin-top: 20px;
      height: 45px;
      width: 70%;
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      background-color: #FE7800;
      border-radius: 9px;
      z-index: 2;
      cursor: pointer; }
      .home-intro .left-content .btn-find-your-coach:hover {
        background-color: #eE6800; }
  .home-intro .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    height: 100%; }
    .home-intro .right-content .people-img {
      position: absolute;
      top: -70px; }
  @media (max-width: 1000px) {
    .home-intro {
      padding: 0 5vw; } }
  @media (max-width: 900px) {
    .home-intro .right-content .people-img {
      top: -5vw; } }
  @media (max-width: 750px) {
    .home-intro {
      position: relative;
      flex-direction: column;
      margin-top: 0; }
      .home-intro .left-content {
        width: 100%;
        padding: 0 12vw; }
        .home-intro .left-content .icon-oseucoach {
          width: 70px;
          height: 70px; }
        .home-intro .left-content .main-paragraph {
          font-size: 28px; }
        .home-intro .left-content .sub-paragraph {
          width: 75%;
          font-size: 14px;
          line-height: 20px; }
        .home-intro .left-content span {
          font-weight: bold;
          color: #FE7800; }
        .home-intro .left-content .btn-find-your-coach {
          display: block; }
      .home-intro .right-content {
        height: 400px;
        width: 100%; }
        .home-intro .right-content .people-img {
          width: 100%;
          height: 800px;
          object-fit: cover;
          transform: translate(-50%, -50%);
          top: 75%;
          left: 50%;
          max-width: unset;
          z-index: -1; } }
