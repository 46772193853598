.button {
  height: fit-content;
  min-height: 40px;
  max-height: 45px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  padding: 12px 35px;
  margin: 0px;
  background-color: #505152;
  border-radius: 46px;
  border-width: 1px;
  border: 1px solid #505152;
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  outline: none !important;
  font-size: .8em;
  color: #fff;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.5) -1px 1px 3px; }
  .button .text {
    width: fit-content !important; }
  .button .fa {
    margin-left: 5px;
    font-size: 14px; }
  .button:hover {
    color: #505152;
    background-color: #fff; }
  @media (max-width: 320px) {
    .button {
      font-size: .5em; } }

.small {
  font-size: 0.8em !important; }
  @media (max-width: 320px) {
    .small {
      font-size: 0.5em !important; } }

.medium {
  font-size: 1em !important; }
  @media (max-width: 320px) {
    .medium {
      font-size: 0.8em !important; } }

.large {
  font-size: 1.5em !important; }
  @media (max-width: 320px) {
    .large {
      font-size: 1em !important; } }

.button.warning {
  background-color: var(--c-primary);
  border-color: var(--c-primary);
  color: #fff; }
  .button.warning:hover {
    box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.7);
    background-color: var(--c-primary);
    border-color: var(--c-primary);
    text-shadow: rgba(0, 0, 0, 0.5) -1px 1px 1px;
    filter: brightness(1.2); }
  .button.warning:active {
    background-color: var(--c-primary);
    outline: none;
    filter: brightness(0.95); }

.button.outline {
  border: 1px solid #fff;
  color: #fff; }
  .button.outline:hover {
    background-color: #fff;
    color: var(--c-primary);
    filter: brightness(0.9); }

.button.outline.warning {
  margin-right: 26px;
  border: 1px solid var(--c-primary);
  color: white; }
  .button.outline.warning:hover {
    border: 1px solid var(--c-primary);
    background-color: var(--c-primary);
    color: white; }

.button.disabled:hover {
  cursor: not-allowed;
  background-color: #505152 !important;
  color: #fff !important;
  border: 1px solid #505152; }
