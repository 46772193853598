@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.biography-contact--7 {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  padding: 20px 0;
  color: #4E4E4E; }
  .biography-contact--7 .expertises {
    margin-bottom: 10px;
    text-align: center; }
    .biography-contact--7 .expertises h4 {
      margin: 0;
      font-size: 18px;
      font-family: "Nunito Sans", sans-serif;
      font-weight: bold;
      color: #4E4E4E; }
    .biography-contact--7 .expertises p {
      margin: 5px 0;
      font-size: 15px;
      font-family: "Nunito Sans", sans-serif;
      font-weight: normal;
      color: #4E4E4E; }
