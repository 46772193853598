.modal-confirmation {
  border-top: 10px solid #f57010; }
  .template-2 .modal-confirmation {
    border-top: 10px solid #0071a5; }
  .template-3 .modal-confirmation {
    border-top: 10px solid #ab1f24; }
  .template-4 .modal-confirmation {
    border-top: 10px solid #098846; }

.modal-content-all {
  padding: 20px 30px 10px; }
  .modal-content-all h4 {
    padding: 20px;
    font-weight: normal;
    font-size: 20px;
    color: #616161; }

.question-modal-confirmation {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ececec; }
  .question-modal-confirmation .icon-attention {
    width: 30px;
    height: 30px;
    margin-right: 10px; }
    .question-modal-confirmation .icon-attention svg {
      width: 100%;
      height: 100%; }
      .question-modal-confirmation .icon-attention svg path {
        fill: #f57010; }
        .template-2 .question-modal-confirmation .icon-attention svg path {
          fill: #0071a5; }
        .template-3 .question-modal-confirmation .icon-attention svg path {
          fill: #ab1f24; }
        .template-4 .question-modal-confirmation .icon-attention svg path {
          fill: #098846; }
  .question-modal-confirmation p {
    margin: 0;
    font-weight: bold;
    font-size: 25px;
    color: #505152;
    text-transform: uppercase; }
