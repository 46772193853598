.select-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: fit-content;
  height: 37px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: row; }
  .select-wrapper .select-template {
    width: 37.5px;
    height: 37.5px;
    border: 2px solid white;
    cursor: pointer; }
    .select-wrapper .select-template .select-tooltip {
      display: none;
      position: absolute;
      top: 50px;
      width: fit-content;
      padding: 3px 5px;
      border-radius: 2px;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5); }
      .select-wrapper .select-template .select-tooltip:before {
        content: '';
        width: 1px;
        height: 1px;
        display: block;
        background-color: inherit;
        position: absolute;
        transform: rotate(45deg);
        top: -3px;
        right: 38px;
        border-left: 6px solid;
        border-top: 6px solid; }
    .select-wrapper .select-template.first {
      background-color: #f57010;
      border-radius: 5px 0px 0px 5px; }
      .select-wrapper .select-template.first:hover .select-tooltip {
        background-color: #f57010;
        right: 85px;
        display: block; }
        .select-wrapper .select-template.first:hover .select-tooltip:before {
          border-color: #f57010; }
    .select-wrapper .select-template.second {
      background-color: #0071a5; }
      .select-wrapper .select-template.second:hover .select-tooltip {
        background-color: #0071a5;
        right: 50px;
        display: block; }
        .select-wrapper .select-template.second:hover .select-tooltip:before {
          border-color: #0071a5; }
    .select-wrapper .select-template.third {
      background-color: #ab1f24; }
      .select-wrapper .select-template.third:hover .select-tooltip {
        background-color: #ab1f24;
        right: 15px;
        display: block; }
        .select-wrapper .select-template.third:hover .select-tooltip:before {
          border-color: #ab1f24; }
    .select-wrapper .select-template.fourth {
      background-color: green;
      border-radius: 0px 5px 5px 0px; }
      .select-wrapper .select-template.fourth:hover .select-tooltip {
        background-color: green;
        right: 0px;
        display: block; }
        .select-wrapper .select-template.fourth:hover .select-tooltip:before {
          border-color: green;
          right: 20px; }
    .select-wrapper .select-template.selected {
      border: 2px solid yellow !important; }
  @media (max-width: 768px) {
    .select-wrapper {
      top: 97px; } }
