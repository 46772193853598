@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.footer-wrapper {
  width: 100%;
  background: #414243; }

.section-up {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0%;
  margin: auto;
  background-color: #414243;
  color: #fff; }
  @media (max-width: 1000px) {
    .section-up {
      width: 90%; } }
  @media (max-width: 768px) {
    .section-up {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

.section-up .coach-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px; }
  .section-up .coach-name div:first-child {
    font-weight: bold; }
  .section-up .coach-name div:last-child {
    font-weight: 300; }
  @media (max-width: 768px) {
    .section-up .coach-name {
      padding: 10px;
      text-align: center; } }

.section-up .coach-tel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  cursor: pointer; }
  .section-up .coach-tel a {
    cursor: pointer;
    color: inherit;
    text-decoration: inherit; }
    .section-up .coach-tel a label {
      cursor: pointer; }
  .section-up .coach-tel .fa {
    padding: 0px;
    line-height: 2;
    font-size: 22px; }

.coach-tel-number {
  padding: 8px;
  font-size: 18px; }

.section-up .social-line {
  display: flex;
  align-items: center;
  align-content: center; }
  .section-up .social-line p {
    margin: 0 5px 0 0;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 16px; }
    @media (max-width: 768px) {
      .section-up .social-line p {
        width: 100%;
        margin: 0 0px 0 0; } }
  .section-up .social-line .fa {
    font-size: 20px;
    padding-left: 10px; }
  .section-up .social-line .social-whatsapp {
    display: none; }
  @media (max-width: 768px) {
    .section-up .social-line {
      margin-bottom: 18px;
      justify-content: center; }
      .section-up .social-line .home-container {
        justify-content: center; } }

.section-up .coach-social-net {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px; }
  .section-up .coach-social-net a {
    font-size: 20px;
    color: #fff;
    text-transform: none; }
  .section-up .coach-social-net .fa {
    font-size: 18px; }
  @media (max-width: 768px) {
    .section-up .coach-social-net {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; } }
  @media (max-width: 768px) {
    .section-up .coach-social-net > div {
      width: 100%;
      padding-bottom: 0px; } }

.section-up .anchor-top {
  cursor: pointer; }
  .section-up .anchor-top a {
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    background: #383737;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 2px; }
    .section-up .anchor-top a .fa {
      padding: 0; }
      @media (max-width: 768px) {
        .section-up .anchor-top a .fa {
          font-size: 28px; } }

.page-coach-container .footer-up .content {
  padding: 30px 0; }
  .page-coach-container .footer-up .content .logo-main {
    margin: auto;
    cursor: pointer !important; }
  .page-coach-container .footer-up .content div {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0; }
  .page-coach-container .footer-up .content a div {
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: visible;
    margin: 0; }
