.fab-wrapper {
  padding: 0px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 100%;
  color: #fff; }
  .fab-wrapper.red {
    background-color: #ab1f24; }
    .fab-wrapper.red .fab-tooltip {
      background-color: inherit; }
  .fab-wrapper.yellow {
    background-color: #edc03d; }
    .fab-wrapper.yellow .fab-tooltip {
      background-color: inherit; }
  .fab-wrapper.green {
    background-color: #098846; }
    .fab-wrapper.green .fab-tooltip {
      background-color: inherit; }
  .fab-wrapper.orange {
    background-color: #F17127; }
    .fab-wrapper.orange .fab-tooltip {
      background-color: inherit; }
  .fab-wrapper.blue {
    background-color: #0071a5; }
    .fab-wrapper.blue .fab-tooltip {
      background-color: inherit; }
  .fab-wrapper a .fa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .fab-wrapper .fab-tooltip {
    background-color: #505152;
    position: absolute;
    top: 25%;
    right: 0px;
    font-size: 20px;
    padding: 5px 10px;
    width: auto;
    height: auto;
    border-radius: 5px;
    font-family: arial;
    opacity: 0;
    z-index: 0;
    transition: all 0.5s;
    box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.5); }
  .fab-wrapper:hover {
    box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.8); }
    .fab-wrapper:hover .fab-tooltip {
      right: 70px;
      opacity: 1;
      transition: all 0.5s; }
  .fab-wrapper .fa {
    font-size: 2em;
    color: #fff;
    text-shadow: -3px 1px 5px rgba(0, 0, 0, 0.5); }
    .fab-wrapper .fa:hover {
      text-shadow: -3px 1px 5px rgba(0, 0, 0, 0.8); }
  .fab-wrapper:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    background: #000;
    border-radius: 100%;
    opacity: .6;
    visibility: hidden;
    transform: scale(0); }
    @media (max-width: 320px) {
      .fab-wrapper:after {
        margin: -25px 0px 0px -25px; } }
    .fab-wrapper:after:focus {
      visibility: hidden; }
    .fab-wrapper:after:focus:not(:active) {
      animation: ripple 1s ease-out; }
  .fab-wrapper.disable {
    background-color: #505152 !important;
    cursor: not-allowed !important; }
    .fab-wrapper.disable .fab-tooltip {
      background-color: #505152 !important; }
  @media (max-width: 768px) {
    .fab-wrapper {
      bottom: 10px;
      right: 10px; } }
  @media (max-width: 320px) {
    .fab-wrapper {
      width: 50px;
      height: 50px; } }

.template-1 .fab-wrapper {
  background-color: #F17127; }
  .template-1 .fab-wrapper .fab-tooltip {
    background-color: #F17127; }

.template-2 .fab-wrapper {
  background-color: #0071a5; }
  .template-2 .fab-wrapper .fab-tooltip {
    background-color: #0071a5; }

.template-3.fab-wrapper {
  background-color: red; }
  .template-3.fab-wrapper .fab-tooltip {
    background-color: red; }

.template-4 .fab-wrapper {
  background-color: #098846; }
  .template-4 .fab-wrapper .fab-tooltip {
    background-color: #098846; }

@keyframes ripple {
  0% {
    transform: scale(0); }
  20% {
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1); } }
