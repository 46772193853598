@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.header--menu-mobile {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  overflow-y: auto;
  background: var(--c-primary); }
  .header--menu-mobile .list-of-social-medias:after {
    display: none; }
  .header--menu-mobile .header--menu-list {
    flex-flow: column wrap; }
    .header--menu-mobile .header--menu-list li:hover {
      transform: scale(1.1);
      color: white; }
  .header--menu-mobile .phone {
    padding: 30px 0; }

.header--menu-mobile-container {
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: initial; }
  .header--menu-mobile-container .close-btn {
    width: 35px;
    height: 5px;
    border-radius: 5px;
    background-color: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: flex;
    position: relative;
    left: 40%;
    margin-bottom: 10%;
    margin-top: 20px; }
    .header--menu-mobile-container .close-btn:before, .header--menu-mobile-container .close-btn:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: #fff;
      -webkit-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      transform: rotate(90deg); }
    .header--menu-mobile-container .close-btn:before {
      border-radius: 5px; }
    .header--menu-mobile-container .close-btn:after {
      border-radius: 5px; }
