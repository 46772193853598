.graduation-container {
  width: auto; }
  @media (min-width: 900px) {
    .graduation-container {
      width: 600px;
      flex-wrap: wrap; } }

.line-1-formation {
  width: 100%; }

.form-select {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 8px;
  height: 85px; }
  .form-select .error {
    color: #e60808;
    font-size: 12px;
    font-family: 'Montserrat';
    font-style: 'normal';
    font-size: '0.8REM';
    padding: 8px 0px 0px 0px; }

.select-acting-area {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  left: 0px;
  padding: 5px 0px 6px 0px;
  color: #FFFF; }

.year-area-select {
  width: 270px; }
  @media (min-width: 900px) {
    .year-area-select {
      width: 160px; } }
  @media (max-width: 363px) {
    .year-area-select {
      width: auto; } }

.line-2-formation {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end; }
  @media (min-width: 900px) {
    .line-2-formation {
      display: flex;
      align-items: center;
      width: 100%;
      height: 120px;
      justify-content: space-between; } }
  @media (max-width: 363px) {
    .line-2-formation {
      display: block;
      margin-top: 30px; } }

.line-2-formation-inputs {
  display: block;
  width: 100%; }
  @media (min-width: 900px) {
    .line-2-formation-inputs {
      display: flex;
      height: 100%;
      justify-content: space-between;
      margin-top: 10px;
      align-items: baseline; } }

.inputs-form-items {
  width: calc(100% + 44px); }
  @media (min-width: 900px) {
    .inputs-form-items {
      display: contents; } }
  @media (max-width: 363px) {
    .inputs-form-items {
      width: 100%; } }
  .inputs-form-items .form-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 30px; }
    .inputs-form-items .form-item .input-contact label, .inputs-form-items .form-item .input-contact span, .inputs-form-items .form-item .phone-input label, .inputs-form-items .form-item .phone-input span {
      color: #FFFFFF;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: start;
      left: 0px;
      padding: 5px 0px; }
    .inputs-form-items .form-item .input-contact span, .inputs-form-items .form-item .phone-input span {
      padding: 15px 0px; }
    .inputs-form-items .form-item .input-contact input, .inputs-form-items .form-item .phone-input input {
      background: transparent;
      border: 1px solid #FE7800;
      padding: 10px;
      border-radius: 5px;
      outline: none;
      color: #FFFFFF;
      font-family: 'Montserrat';
      font-style: 'normal';
      font-size: '0.8REM'; }
    .inputs-form-items .form-item .input-contact .error, .inputs-form-items .form-item .phone-input .error {
      color: #e60808;
      font-size: 12px;
      display: block; }
    .inputs-form-items .form-item .error input {
      border: 1px solid #ff0000; }
      .inputs-form-items .form-item .error input:hover {
        border-color: #ff0000; }

.div-btn-remove {
  position: relative;
  height: 95px;
  display: flex;
  align-items: center; }
  @media (min-width: 900px) {
    .div-btn-remove {
      position: absolute;
      right: -50px;
      display: flex; } }
  @media (max-width: 363px) {
    .div-btn-remove {
      margin-top: 10px; } }
  .div-btn-remove:hover {
    transform: scale(1.1); }

.btn-add-div {
  display: flex;
  justify-content: center; }
  @media (min-width: 900px) {
    .btn-add-div {
      display: flex; } }

.btn-add {
  color: #FE7800;
  display: flex;
  padding: 20px 0px 0px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  left: 0px;
  cursor: pointer; }
  .btn-add:hover {
    color: #EEEEEE; }

.btn-add-disabled {
  color: gray;
  display: flex;
  padding: 20px 0px 0px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  left: 0px; }
