@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.videos-container {
  width: 100%;
  padding: 0;
  background: #fff;
  color: #505152; }
  .videos-container .home-container {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto; }
  .videos-container .flex-carousel-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch; }
    .all-slider-container .videos-container .flex-carousel-container {
      justify-content: center; }
  .videos-container .title-wrapper {
    width: 100%;
    margin: 100px 0 3.5% 0;
    text-align: center; }
    @media (max-width: 768px) {
      .videos-container .title-wrapper {
        padding: 20px;
        margin: 50px 0 3.5% 0; } }
  .videos-container .title {
    width: 100%;
    padding-top: 20px;
    margin-top: 0px;
    border-top: none;
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    text-align: center;
    display: grid; }
    .videos-container .title:before {
      content: '';
      width: 50px;
      height: 3px;
      margin: 0 auto 20px;
      display: block;
      background-color: var(--c-primary); }

/* Init Slider */
.carrousel-videos-all .carousel,
.carrousel-videos-all .all-slider-container {
  flex-flow: column wrap; }

@media (max-width: 768px) {
  .all-slider-container .carousel {
    padding-bottom: 50px; } }

.carrousel-videos-wrapper {
  max-width: 100%;
  margin-bottom: 80px;
  padding: 0 10px; }
  .carrousel-videos-wrapper .slider-wrapper {
    width: 80%; }

.video-wrapper {
  width: calc(98%/2);
  position: relative;
  cursor: pointer; }
  @media (max-width: 768px) {
    .video-wrapper {
      width: 100%; } }
  @media (max-width: 768px) {
    .video-wrapper {
      margin-bottom: 0; } }
  .all-slider-container .video-wrapper {
    width: 100%; }

/* Setas slider */
.carrousel-videos-all .carousel-slider .control-arrow {
  height: auto;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-64%);
  background-color: transparent !important; }
  .carrousel-videos-all .carousel-slider .control-arrow:before {
    content: '';
    width: 20px;
    height: 40px;
    display: block;
    margin: 0;
    padding: 0;
    background-image: url("../../../../img/arrow-icon.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0 !important;
    font-family: "Nunito Sans", sans-serif;
    font-size: 80px;
    font-weight: lighter;
    color: #000; }
  .carrousel-videos-all .carousel-slider .control-arrow.control-next {
    right: 0px; }
    @media (max-width: 400px) {
      .carrousel-videos-all .carousel-slider .control-arrow.control-next {
        display: none; } }
  .carrousel-videos-all .carousel-slider .control-arrow.control-prev {
    left: 0px; }
    .carrousel-videos-all .carousel-slider .control-arrow.control-prev:before {
      transform: rotate(180deg); }
    @media (max-width: 400px) {
      .carrousel-videos-all .carousel-slider .control-arrow.control-prev {
        display: none; } }

.carrousel-videos-all .all-slider-container .control-arrow.control-next {
  right: 150px; }
  @media (max-width: 1090px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-next {
      right: 50px; } }
  @media (max-width: 768px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-next {
      right: 20px; } }
  @media (max-width: 550px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-next {
      right: 0px; } }

.carrousel-videos-all .all-slider-container .control-arrow.control-prev {
  left: 150px; }
  @media (max-width: 1090px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-prev {
      left: 50px; } }
  @media (max-width: 768px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-prev {
      left: 20px; } }
  @media (max-width: 550px) {
    .carrousel-videos-all .all-slider-container .control-arrow.control-prev {
      left: 0px; } }

/* Bullets */
.carrousel-videos-all .carousel-slider .control-dots {
  position: relative;
  padding-left: 0;
  bottom: 0px;
  left: 0;
  margin: 0;
  border: 0; }
  .carrousel-videos-all .carousel-slider .control-dots .dot {
    width: 12px;
    height: 12px;
    margin: 10px 5px 0 0;
    background-color: #939598 !important;
    box-shadow: 0 0 0 #fff;
    border: 0; }
    .carrousel-videos-all .carousel-slider .control-dots .dot:last-child {
      margin: 10px 0px 0 0; }

.videos-wrapper {
  width: calc(98% / 2);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center; }

.video-container {
  height: 250px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: black;
  text-align: left; }
  .video-container img {
    max-width: 100%;
    height: auto;
    display: block; }
  @media (max-width: 768px) {
    .video-container {
      width: 100%; } }
  .all-slider-container .video-container {
    height: 300px; }

.video-caption {
  width: 89%;
  margin: 10px;
  overflow: hidden;
  font-weight: bold;
  color: #000;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap; }
  @media (max-width: 768px) {
    .video-caption {
      width: 100%; } }
  .all-slider-container .video-caption {
    text-align: center; }

.fa-huge {
  font-size: 10vw; }

.play-button-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s; }
  .play-button-icon .play-img {
    width: 10% !important;
    height: 25%;
    margin-left: 0px;
    background: transparent;
    transition: 1s all; }
  .play-button-icon:hover {
    background-color: rgba(0, 0, 0, 0.6); }
    .play-button-icon:hover .play-img {
      transform: scale(1.25); }

.zoomed-video {
  width: 97vw;
  height: calc(9 * 97vw / 16);
  display: flex;
  flex-flow: column wrap;
  justify-content: center; }
  .zoomed-video iframe {
    width: 60%;
    height: 60%;
    margin: auto; }
    @media (max-width: 768px) {
      .zoomed-video iframe {
        width: 100%;
        height: 80%; } }
  @media (max-width: 768px) {
    .zoomed-video {
      width: 95%;
      height: 95%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: auto; } }

.modal-exit-btn {
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
  font-size: 46px;
  color: #fff;
  text-align: right; }
  .modal-exit-btn:hover, .modal-exit-btn:visited, .modal-exit-btn:focus {
    outline: none; }

.add-video {
  height: 250px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 2px dashed #D0D2D1;
  border-radius: 5px; }
  .add-video div:last-child {
    width: 99%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1%;
    border: 1px solid #d0d2d1; }
  .add-video span {
    margin-top: 5px;
    color: #d6d6d6;
    font-size: 14px; }
  @media (max-width: 768px) {
    .add-video {
      width: 50%;
      margin: 0 auto; } }
  .all-slider-container .add-video {
    width: 50%;
    height: 300px;
    margin: 0 auto; }
    @media (max-width: 650px) {
      .all-slider-container .add-video {
        width: 80%; } }
    @media (max-width: 400px) {
      .all-slider-container .add-video {
        width: 100%; } }

.icon-upload-video {
  width: 51px;
  height: 47px;
  display: block;
  background: url("../../../../img/icon-upload-video.png"); }

.wide-input {
  width: 90%; }

.error input {
  outline: none;
  background-color: #fce4e4;
  border: 1px solid #cc0033; }

.url-invalid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 600;
  color: var(--c-primary); }

.icon-url-invalid {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: url("../../../../img/icon-attention.png");
  background-size: cover; }

.videos-img-toolbar {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  color: #fff;
  background-color: #505152;
  border-radius: 3px; }

.video-and-caption-container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; }

.video-container-all {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

/* Modal Add Video */
.box-title-modal .icon-video {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: url("../../../../img/icon-video.png");
  background-size: cover; }

.modal-content h4 {
  margin: 0; }
