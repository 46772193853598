@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6-initial-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  overflow: hidden;
  background-color: #4e4d4e;
  font-size: 40px;
  color: #fff; }
  .template--6-initial-card p {
    margin: 0;
    font-size: 30px; }
  .template--6-initial-card h4 {
    margin: 0; }
