.new-header {
  background-color: #15161B;
  display: flex;
  height: 135px;
  justify-content: space-between;
  z-index: 2;
  padding: 20px 20px 40px; }
  .new-header .new-header-container-image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 55px;
    width: 170px;
    cursor: pointer; }
    .new-header .new-header-container-image .logo-render {
      width: 200px;
      cursor: pointer; }
  .new-header .menu-container.mobile-menu-open {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    background-color: #00000055; }
  .new-header .menu-hamburger {
    position: absolute;
    top: 20px;
    right: 0;
    height: 60px;
    width: 60px;
    visibility: visible;
    cursor: pointer; }
    .new-header .menu-hamburger .icon-burger {
      width: 60px;
      height: 60px; }
    .new-header .menu-hamburger.mobile-menu-open {
      position: absolute;
      transform: translateX(100%);
      z-index: 3;
      right: 100%;
      transition: all 0.3s ease-out; }
      .new-header .menu-hamburger.mobile-menu-open svg rect:first-child {
        width: 70%; }
      .new-header .menu-hamburger.mobile-menu-open svg rect:last-child {
        width: 70%; }
      .new-header .menu-hamburger.mobile-menu-open svg rect {
        transition: width 0.8s ease-out; }
  .new-header .navs-group {
    visibility: hidden;
    width: 0;
    transition: width 0.2s ease-out; }
    .new-header .navs-group.mobile-menu-open {
      visibility: visible;
      position: absolute;
      right: 0;
      display: flex;
      justify-content: center;
      height: 100vh;
      width: 80%;
      border-radius: 8px 0 0 8px;
      background-color: #FE7800;
      z-index: 4; }
      .new-header .navs-group.mobile-menu-open ul {
        display: flex;
        flex-direction: column;
        margin-top: 200px; }
        .new-header .navs-group.mobile-menu-open ul .menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          color: #fff;
          width: fit-content;
          position: relative;
          font-size: clamp(16px, 4vw, 24px);
          cursor: pointer; }
        .new-header .navs-group.mobile-menu-open ul .icon-menu-item {
          margin-right: 10px; }
        .new-header .navs-group.mobile-menu-open ul .login-button {
          margin-top: 30px; }
          .new-header .navs-group.mobile-menu-open ul .login-button button {
            background: black;
            border-radius: 50px;
            color: #fff;
            padding: 12px;
            width: 100%;
            cursor: pointer;
            font-weight: 700; }
            .new-header .navs-group.mobile-menu-open ul .login-button button:hover {
              transform: scale(1.03); }
        .new-header .navs-group.mobile-menu-open ul .select-language-container {
          position: absolute;
          top: 26px;
          right: 6px;
          transition: right 0.3s ease-out; }
          .new-header .navs-group.mobile-menu-open ul .select-language-container div[class*=singleValue] {
            color: white; }
    .new-header .navs-group .select-language-container {
      position: absolute;
      top: 26px;
      right: -150px;
      width: 92px;
      min-width: 90px;
      margin-left: 4px;
      margin-bottom: 3px; }
    .new-header .navs-group .menu-item {
      margin-left: 12px; }
  @media (min-width: 750px) {
    .new-header {
      background-color: transparent;
      height: 100px;
      align-items: center; }
      .new-header .new-header-container-image {
        padding: 0; }
        .new-header .new-header-container-image.logo-hidden {
          width: 0; }
          .new-header .new-header-container-image.logo-hidden .logo-oseu-coach {
            display: none !important; }
      .new-header .menu-container {
        display: flex; }
      .new-header .menu-hamburger {
        padding: 0;
        visibility: hidden; }
      .new-header .navs-group {
        visibility: visible;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        transition: width 0.2s ease-out; }
        .new-header .navs-group ul {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center; }
          .new-header .navs-group ul li {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 0.82rem;
            line-height: 20px;
            text-align: center;
            color: #000000;
            cursor: pointer; }
          .new-header .navs-group ul .menu-item {
            position: relative; }
            .new-header .navs-group ul .menu-item .icon-menu-item {
              display: none !important; }
            .new-header .navs-group ul .menu-item.active {
              font-weight: bold;
              color: #FE7800; }
          .new-header .navs-group ul .login-button button {
            background: #FE7800;
            border-radius: 50px;
            color: #fff;
            padding: 12px;
            max-width: 125px;
            width: 100%;
            cursor: pointer;
            font-weight: 700; }
            .new-header .navs-group ul .login-button button:hover {
              transform: scale(1.03); }
          .new-header .navs-group ul .select-language-container {
            position: static; } }
  @media (min-width: 800px) {
    .new-header .navs-group .menu-item {
      margin-left: 20px; }
    .new-header .navs-group .select-language-container {
      position: static;
      margin-left: 12px; } }
  @media (min-width: 1000px) {
    .new-header .login-button {
      margin-right: 20px;
      min-width: 163px; } }

.new-home-header {
  width: 100%;
  padding: 20px 12vw 40px;
  background-color: transparent; }
  .new-home-header .new-header-container-image {
    display: flex;
    align-items: center;
    justify-content: center; }
  .new-home-header .navs-group ul .menu-item {
    color: white; }
  @media (max-width: 1000px) {
    .new-home-header {
      padding: 20px 5vw 40px; } }
