@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.header-biography {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  background-position: center;
  background-size: cover; }
  @media (max-width: 800px) {
    .header-biography {
      clip-path: none; } }
  .header-biography .title-wrapper {
    margin-top: 50px; }
  .header-biography .title {
    font-size: 32px;
    font-weight: 700;
    color: #484848; }
  .header-biography .main-container {
    margin: 40px auto 40px; }
    @media (max-width: 820px) {
      .header-biography .main-container {
        margin-bottom: 0; } }

.header-biography-content {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 40px 100px 60px; }
  @media (max-width: 950px) {
    .header-biography-content {
      padding: 60px 50px; } }
  @media (max-width: 800px) {
    .header-biography-content {
      flex-flow: column wrap;
      align-items: center; } }
  @media (max-width: 500px) {
    .header-biography-content {
      padding: 60px 20px; } }
  .header-biography-content .tooltip p {
    text-align: left; }
    @media (max-width: 800px) {
      .header-biography-content .tooltip p {
        text-align: center; } }

.container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: flex-start;
  align-items: flex-start; }
  .container .avatar {
    width: 150px;
    height: 150px;
    padding: 0px;
    margin-right: 35px; }
    @media (max-width: 800px) {
      .container .avatar {
        margin-right: 0; } }
    @media (max-width: 320px) {
      .container .avatar .avatar-photo {
        max-width: 100%;
        height: auto; } }

.container .info {
  max-width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding: 0px;
  flex: 1 1;
  color: #505152;
  text-align: left; }
  @media (max-width: 800px) {
    .container .info {
      margin-top: 20px;
      text-align: center; } }
  .container .info .title {
    margin-bottom: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
    color: inherit; }
    @media (max-width: 800px) {
      .container .info .title {
        text-align: center; } }
  .container .info .info-content > div {
    display: block;
    word-break: normal;
    line-height: 1.8;
    font-size: 14px;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    color: #505152; }

.info-contact {
  display: flex;
  margin-top: 10px; }
  @media (max-width: 800px) {
    .info-contact {
      flex-direction: column;
      align-items: center;
      text-align: center; } }
  .info-contact .item-contacts {
    display: flex;
    margin-top: 5px; }
  .info-contact .fa {
    padding: 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 17px;
    color: var(--c-primary);
    text-align: center; }
  .info-contact .text {
    margin-top: 0; }
  .info-contact .contacts {
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-flow: column wrap;
    flex: 1;
    overflow: hidden;
    font-weight: bolder; }
    @media (max-width: 800px) {
      .info-contact .contacts {
        flex-direction: column;
        align-items: center;
        text-align: center; } }
    .info-contact .contacts div div {
      display: table-cell;
      overflow: hidden;
      padding: 0px;
      margin: 0px;
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-overflow: ellipsis; }

.item-email {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.edit-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center; }
  .edit-input input {
    width: calc(98% / 2);
    height: 30px;
    padding: 1px 5px;
    text-align: left;
    font-weight: bolder;
    color: #505152;
    border: 1px solid #fff;
    border-radius: 4px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2); }
    .edit-input input:focus {
      outline: none;
      border: 1px solid var(--c-primary);
      box-shadow: -1px 1px 4px rgba(var(--c-primary), 0.2); }
    .edit-input input:hover {
      outline: none;
      border: 1px solid var(--c-primary); }

.tooltip.info-content {
  margin-bottom: 10px; }

.info-content-input {
  height: 92px;
  padding: 0px;
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px;
  -ms-flex-direction: column;
  flex-direction: column; }
  .info-content-input textarea {
    width: 100%;
    height: 70px;
    padding: 10px;
    color: #505152;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2); }
    .info-content-input textarea:focus {
      outline: none;
      border: 1px solid var(--c-primary); }
    .info-content-input textarea:hover {
      outline: none;
      border: 1px solid var(--c-primary);
      box-shadow: -1px 1px 4px rgba(var(--c-primary), 0.2); }
    @media (max-width: 320px) {
      .info-content-input textarea {
        font-size: .7em; } }
  .info-content-input .character-count {
    width: 100%;
    margin: 8px 0px 0px 0px;
    font-size: 12px;
    text-align: right; }

.toedit-input {
  width: 340px;
  height: 20px;
  padding: 2px 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2); }
  .toedit-input:focus {
    outline: none;
    border: 1px solid var(--c-primary);
    box-shadow: -1px 1px 4px rgba(var(--c-primary), 0.2); }
  .toedit-input:hover {
    outline: none;
    border: 1px solid var(--c-primary); }

.expertises-list {
  height: fit-content; }
  .expertises-list h4 {
    margin: 4px 0px 4px 0px;
    font-size: 16px;
    font-weight: bold; }
  .expertises-list ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin: 0;
    padding: 0px;
    list-style-type: none; }
    @media (max-width: 800px) {
      .expertises-list ul {
        justify-content: center; } }
  .expertises-list li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 400; }
    .expertises-list li:last-child {
      margin-right: 0px; }
    .expertises-list li:before {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      margin-right: 5px;
      background: #939598;
      border-radius: 100%; }

.button-container {
  display: flex;
  align-items: center; }
  .button-container button {
    cursor: pointer; }
  @media (max-width: 800px) {
    .button-container {
      margin-top: 20px; } }

.areas-expertise-container {
  width: 100%;
  margin: 0px !important; }
  .areas-expertise-container .checkbox-input-group {
    width: 100%;
    margin: 0px !important;
    justify-content: space-around !important; }

.checkbox-input-group .checkbox-input {
  width: unset !important;
  margin: 5px 0px !important; }
  .checkbox-input-group .checkbox-input label {
    margin-left: 3px !important;
    font-size: 12px !important; }
  .checkbox-input-group .checkbox-input input[type='checkbox'] {
    width: 12px !important;
    height: 12px !important;
    margin: 0px !important;
    cursor: pointer; }
