@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--banner .background-image {
  position: relative; }

.template--7--banner .background-image-content img {
  width: 100%;
  height: 300px;
  object-fit: cover; }
  @media (min-width: 850px) {
    .template--7--banner .background-image-content img {
      height: 450px; } }

.template--7--banner .background-image-content:before {
  content: '';
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply; }
  @media (min-width: 850px) {
    .template--7--banner .background-image-content:before {
      height: 450px; } }

.template--7--banner .background-informations {
  width: 70%;
  padding: 1rem;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  @media (min-width: 990px) {
    .template--7--banner .background-informations {
      width: 76%; } }
  .template--7--banner .background-informations .list-of-social-medias {
    padding-right: 10vw;
    z-index: auto; }
    .template--7--banner .background-informations .list-of-social-medias:after {
      width: 10vw;
      height: 1px;
      position: absolute;
      right: 2px;
      background: var(--c-secondary); }

.template--7--banner .background-location {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  color: var(--c-secondary); }
  .template--7--banner .background-location svg {
    margin-right: 5px; }
