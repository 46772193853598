@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--biography {
  width: 100%;
  padding-top: 40px;
  background: linear-gradient(to top right, #fff 0%, #fff 40%, #FCFDFF 80%, #FCFDFF 100%); }
  .template--7--biography .biography-content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media (min-width: 750px) {
      .template--7--biography .biography-content {
        flex-flow: row wrap; } }
    .template--7--biography .biography-content img {
      width: 300px;
      height: 300px;
      object-fit: cover; }
      @media (max-width: 750px) {
        .template--7--biography .biography-content img {
          margin: 0 auto; } }
      @media (min-width: 990px) {
        .template--7--biography .biography-content img {
          width: 400px;
          height: 400px; } }
  .template--7--biography .biography-content-divisor {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    @media (min-width: 750px) {
      .template--7--biography .biography-content-divisor {
        width: calc(100% - 350px); } }
    @media (min-width: 990px) {
      .template--7--biography .biography-content-divisor {
        width: calc(100% - 500px); } }
