@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif; }
  html input[type=number]::-webkit-inner-spin-button,
  html input[type=number]::-webkit-outer-spin-button,
  body input[type=number]::-webkit-inner-spin-button,
  body input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
