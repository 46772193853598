@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.resume-container {
  width: 100%;
  padding: 0px;
  background: #fff;
  color: #505152; }
  .resume-container .title-wrapper {
    width: 100%;
    margin: 55px 0px 45px 0px;
    display: flex;
    text-align: center;
    justify-content: center; }
    @media (max-width: 768px) {
      .resume-container .title-wrapper {
        padding: 20px;
        margin: 30px 0 0 0; } }
  .resume-container .title {
    margin-top: 20px;
    border-top: none;
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    text-align: center;
    display: grid; }
    @media (max-width: 768px) {
      .resume-container .title {
        margin-top: 15px; } }
    .resume-container .title:before {
      content: '';
      width: 50px;
      height: 3px;
      margin: 0 auto 20px;
      display: block;
      background-color: var(--c-primary); }

.courses-container {
  max-width: 100%;
  align-self: center;
  align-items: center; }
  @media (max-width: 768px) {
    .courses-container {
      z-index: 3; } }

.tooltip p {
  margin: 0;
  display: block;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  line-height: 1.8;
  text-align: center;
  word-wrap: break-word; }

.courses-container .feedEdit {
  width: 100%;
  height: 100%;
  padding: 2%;
  border: 1px solid #505152;
  border-radius: 4px;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);
  font-size: 1em !important;
  font-weight: bold;
  color: #505152;
  line-height: 1.8em !important; }
  .courses-container .feedEdit:focus {
    outline: none;
    border: 1px solid var(--c-primary) !important;
    box-shadow: -1px 1px 4px rgba(var(--c-primary), 0.2); }
  .courses-container .feedEdit:hover {
    outline: none;
    border: 1px solid var(--c-primary) !important; }
  @media (max-width: 768px) {
    .courses-container .feedEdit {
      padding: 3%;
      font-size: .8em !important; } }

.column-course {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 30px;
  font-size: 18px;
  text-align: left;
  line-height: 1.5; }
  @media (max-width: 768px) {
    .column-course {
      padding: 20px; } }

/* Slider */
.resume-footer .carousel-slider .slider {
  padding-bottom: 20px; }

/* Setas slider */
.resume-footer .carousel-slider .control-arrow {
  height: auto;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent !important; }
  .resume-footer .carousel-slider .control-arrow:before {
    content: '';
    width: 20px;
    height: 40px;
    display: block;
    margin: 0;
    padding: 0;
    background-image: url("../../../../img/arrow-icon.png");
    background-size: cover;
    border: 0;
    color: #000;
    font-size: 80px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: lighter; }
  .resume-footer .carousel-slider .control-arrow.control-next {
    right: 0px; }
  .resume-footer .carousel-slider .control-arrow.control-prev {
    left: 0px;
    transform: translateY(-50%); }
    .resume-footer .carousel-slider .control-arrow.control-prev:before {
      transform: rotate(180deg); }

/* Bullets */
.resume-footer .carousel-slider .control-dots {
  padding-left: 0;
  bottom: -11px;
  left: 0;
  border: 0; }

.resume-footer .carousel-slider .dot {
  width: 12px;
  height: 12px;
  margin: 10px 5px 0 0;
  background-color: #939598 !important;
  box-shadow: 0 0 0 #fff;
  border: 0; }
  .resume-footer .carousel-slider .dot:last-child {
    margin: 10px 0 0 0; }
