@keyframes lazyImageAnimation {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: #ccc;
    }
    100% {
        background-color: #fff;
    }
}

.lazy-img-container {
    width: 100%;
    height: 100%;

    .placeholder {
        width: 100%;
        height: 100%;
        animation: lazyImageAnimation 1s infinite;
    }

    .loaded-img {
        display: initial;
    }

    .unloaded-img {
        display: none;
    }
}