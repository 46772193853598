@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6-testimonial-card {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  padding-top: 15px;
  padding-left: 10px;
  color: #696969; }
  .template--6-testimonial-card .testimonial-text {
    z-index: 1; }
  .template--6-testimonial-card .author {
    font-weight: bold;
    color: var(--c-primary);
    margin: 40px 0 5px 0; }
  .template--6-testimonial-card .author-work {
    color: #4e4e4e; }
