@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.template-1 {
  display: contents; }

.template-2 {
  display: contents; }

.template-3 {
  display: contents; }

.template-4 {
  display: contents; }

.template-6 {
  display: contents;
  font-style: italic;
  font-weight: 700;
  font-size: 18px; }
  @media (min-width: 1167px) {
    .template-6 {
      font-size: 20px; } }

.template-7 {
  display: contents;
  font-size: 18px;
  font-family: "Cabin", sans-serif; }
