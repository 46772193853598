@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--coach-numbers {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  padding: 7vh 3%; }
  .template--7--coach-numbers .item-info {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    position: relative;
    padding: 20px;
    margin: 0 10px;
    color: #4e4e4e; }
    .template--7--coach-numbers .item-info:nth-child(odd) {
      background: var(--c-secondary); }
    .template--7--coach-numbers .item-info:nth-child(2) .coach-infos .number-info,
    .template--7--coach-numbers .item-info:nth-child(2) .coach-infos .title-info {
      color: var(--c-secondary); }
    .template--7--coach-numbers .item-info:nth-child(2) .icon-item svg g {
      fill: var(--c-secondary); }
    @media (min-width: 600px) {
      .template--7--coach-numbers .item-info {
        width: calc((100% / 2) - 20px); } }
    @media (min-width: 930px) {
      .template--7--coach-numbers .item-info {
        width: calc((100% / 3) - 20px);
        justify-content: space-between; } }
    .template--7--coach-numbers .item-info .icon-item {
      width: 80px;
      height: 80px; }
      .template--7--coach-numbers .item-info .icon-item svg {
        z-index: 2; }
    .template--7--coach-numbers .item-info .coach-infos {
      margin-left: 20px;
      text-align: left; }
      @media (min-width: 990px) {
        .template--7--coach-numbers .item-info .coach-infos {
          padding: 10px 20px; } }
      .template--7--coach-numbers .item-info .coach-infos p {
        margin: 0; }
      .template--7--coach-numbers .item-info .coach-infos .number-info {
        font-size: 35px;
        font-weight: 700;
        color: #fff; }
        @media (min-width: 990px) {
          .template--7--coach-numbers .item-info .coach-infos .number-info {
            font-size: 46px; } }
      .template--7--coach-numbers .item-info .coach-infos .title-info {
        width: 170px;
        font-size: 14px;
        line-height: 14px;
        color: #fff; }
        @media (min-width: 990px) {
          .template--7--coach-numbers .item-info .coach-infos .title-info {
            font-size: 18px;
            line-height: 18px; } }
  @media (max-width: 768px) {
    .template--7--coach-numbers {
      width: 100%;
      margin-top: 15px; } }
  .template--7--coach-numbers .item-info-content {
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
