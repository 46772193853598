.post-suggestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .slick-list {
        padding: 0 0 20px;
        max-width: 80vw;
        width: 100%;

        .slick-track {
            width: 100%;
        }
    }

    .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
            width: 100%;
        }
    }

    .slick-dots {
        bottom: 0px !important;

        li {
            font-size: 64px;

            button {
                &:before {
                    font-size: 20px;
                    color: #ccc;
                }
            }
    
            &.slick-active {
                button {
                    &:before {
                        color: #FE7800;
                    }
                }
            }
        }   
    }

    .see-more-title {
        margin: 10px 0 20px;
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
    }

    .blog-post-card {
        flex-direction: column;
        margin-bottom: 70px;
        padding: 0;

        .card-info {
            width: 100%;

            header {
                margin-top: 10px;
            }

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 12px;
                -webkit-line-clamp: 6;
            }

            footer {
                span, .category {
                    font-size: 10px;
                }
            }
        }

        .thumbnail {
            width: 100%;
            max-width: unset;
            height: 220px;
            margin-bottom: 15px;
        }
    }
}