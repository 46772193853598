@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5-testimonial-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  color: #696969; }
  .template--5-testimonial-card .author {
    margin: 20px 0 0;
    font-weight: bold;
    font-size: 18px;
    color: var(--c-primary);
    text-align: center; }
  .template--5-testimonial-card .testimonial-text {
    margin: 0;
    text-align: center; }
  .template--5-testimonial-card .author-work {
    margin: 0;
    font-size: 18px; }
