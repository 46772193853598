@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--biography {
  width: 1440px;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto; }
  .template--5--biography .biography-content {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    @media (min-width: 990px) {
      .template--5--biography .biography-content {
        flex-flow: row wrap; } }
    .template--5--biography .biography-content .biography-info {
      width: 100%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center; }
      @media (min-width: 990px) {
        .template--5--biography .biography-content .biography-info {
          width: calc(100% - 600px);
          text-align: center; } }
    .template--5--biography .biography-content .biography-contact {
      width: 100%; }
      @media (min-width: 990px) {
        .template--5--biography .biography-content .biography-contact {
          width: 300px; } }
      .template--5--biography .biography-content .biography-contact li {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-bottom: 20px; }
        .template--5--biography .biography-content .biography-contact li:last-child {
          margin-bottom: 0; }
        @media (min-width: 650px) {
          .template--5--biography .biography-content .biography-contact li {
            justify-content: flex-start;
            align-content: center;
            margin-bottom: 0px; } }

.biography-image {
  position: relative; }
  .biography-image img {
    width: 240px;
    height: 304px;
    position: relative;
    z-index: 1;
    object-fit: cover; }
  .biography-image .bg-pontilhado {
    width: 108%;
    height: 108%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover; }
    .biography-image .bg-pontilhado:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 304px solid var(--c-primary);
      border-right: 240px solid transparent; }
