.select-wrapper-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; }
  .select-wrapper-container label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 20px;
    color: #FFFFFF;
    position: relative;
    left: 5px;
    cursor: pointer;
    margin-top: 5px; }
  .select-wrapper-container .disabled {
    color: #FFFFFF7D; }
  .select-wrapper-container .container-helper {
    display: flex;
    right: 5px;
    justify-content: flex-end;
    position: absolute;
    bottom: -17px; }
    .select-wrapper-container .container-helper span {
      color: #FFFFFF;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 15px; }
  .select-wrapper-container > div > div {
    cursor: pointer; }

.language-template-1 {
  display: contents; }

@media (max-height: 700px) {
  .container-all-form .select-wrapper-container .css-26l3qy-menu {
    margin-top: -50px;
    transform: translateY(-100%); } }
