.new-home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #0c101a; }
  @media (max-width: 750px) {
    .new-home-container {
      padding: 0; } }
