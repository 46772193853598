.avatar {
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  position: relative; }
  .avatar .avatar-photo {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 100%; }

.edit-avatar {
  width: 100%;
  height: 40px;
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: var(--c-primary);
  border-radius: 50px;
  font-size: 12px;
  color: #fff; }
  .edit-avatar .fa {
    font-size: 12px; }

.icon-edit-avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: url("../../../../../../img/icon-edit-avatar.png");
  background-size: cover; }

.modal-edit-avatar .box-title-modal {
  margin-bottom: 32px; }

@media (max-width: 550px) {
  .modal-edit-avatar .modal-footer button {
    margin-bottom: 20px; } }

@media (max-width: 860px) {
  .modal-edit-avatar .modal-footer .button-block {
    padding-bottom: 0; } }

.edit-icon {
  display: table;
  position: relative;
  right: -50px;
  bottom: -9px;
  color: #505152;
  text-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8); }

@media (max-width: 768px) {
  .modal-footer .button-block {
    padding: 5%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center; } }
