@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

textarea {
  resize: none; }

.gallery-container {
  width: 100%;
  padding: 20px 0 100px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  background: #fff;
  color: #505152; }
  @media (max-width: 768px) {
    .gallery-container {
      padding: 50px 0 100px; } }
  .gallery-container .home-container {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto; }
  .gallery-container .title-wrapper {
    width: 100%;
    margin: 6% 0 3.5% 0;
    align-self: flex-start;
    text-align: center; }
    @media (max-width: 768px) {
      .gallery-container .title-wrapper {
        height: 20%;
        min-height: 90px;
        padding: 20px;
        margin: 0px;
        padding: 10px; } }
  .gallery-container .title {
    width: 100%;
    padding-top: 20px;
    margin-top: 0px;
    border-top: none;
    font-size: 32px;
    font-weight: 700;
    color: #484848;
    text-align: center;
    display: grid; }
    .gallery-container .title:before {
      content: '';
      width: 50px;
      height: 3px;
      margin: 0 auto 20px;
      display: block;
      background-color: var(--c-primary); }

/* Init Slider */
.gallery-carousel-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.gallery-container .slider-wrapper {
  width: 92%; }
  @media (max-width: 768px) {
    .gallery-container .slider-wrapper {
      width: 82%;
      height: 54%;
      padding-bottom: 50px; } }

.gallery-container .carousel-slider {
  flex-flow: column wrap;
  position: relative;
  padding-bottom: 0px; }
  @media (max-width: 768px) {
    .gallery-container .carousel-slider {
      width: 100%;
      padding: 0px;
      height: 100% !important; } }

/* Setas slider */
.gallery-container .carousel-container .control-arrow,
.carousel-modal-container .carousel .control-arrow {
  height: auto;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent !important; }
  .gallery-container .carousel-container .control-arrow:before,
  .carousel-modal-container .carousel .control-arrow:before {
    content: '';
    width: 20px;
    height: 40px;
    display: block;
    margin: 0;
    padding: 0;
    background-image: url("../../../../img/arrow-icon.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    color: #000;
    font-size: 80px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: lighter; }
  .gallery-container .carousel-container .control-arrow.control-prev:before,
  .carousel-modal-container .carousel .control-arrow.control-prev:before {
    transform: rotate(180deg); }

.gallery-container .carousel-container .control-arrow.control-next {
  right: 0px; }

.gallery-container .carousel-container .control-arrow.control-prev {
  left: 0px; }

@media (max-width: 400px) {
  .gallery-container .carousel-container .control-arrow.control-next, .gallery-container .carousel-container .control-arrow.control-prev {
    display: none; } }

@media (max-width: 768px) {
  .gallery-container .carousel-container .control-arrow {
    transform: translateY(0%);
    top: 0%; } }

.gallery-container .all-slider-container .control-arrow.control-next {
  right: 290px; }
  @media (max-width: 1070px) {
    .gallery-container .all-slider-container .control-arrow.control-next {
      right: 200px; } }
  @media (max-width: 900px) {
    .gallery-container .all-slider-container .control-arrow.control-next {
      right: 150px; } }
  @media (max-width: 800px) {
    .gallery-container .all-slider-container .control-arrow.control-next {
      right: 0px; } }

.gallery-container .all-slider-container .control-arrow.control-prev {
  left: 290px; }
  @media (max-width: 1070px) {
    .gallery-container .all-slider-container .control-arrow.control-prev {
      left: 200px; } }
  @media (max-width: 900px) {
    .gallery-container .all-slider-container .control-arrow.control-prev {
      left: 150px; } }
  @media (max-width: 800px) {
    .gallery-container .all-slider-container .control-arrow.control-prev {
      left: 00px; } }

.modal-zoom-image .carousel-slider {
  flex-flow: column wrap; }

.modal-zoom-image .carousel-modal-container .carousel .control-arrow {
  width: 40px;
  height: 40px;
  display: block;
  background-color: rgba(0, 0, 0, 0.8) !important; }
  .modal-zoom-image .carousel-modal-container .carousel .control-arrow:before {
    width: 32px !important;
    height: 32px !important;
    margin: auto !important;
    border: 0 !important;
    background-image: url("../../../../img/left-arrow.png") !important;
    background-size: contain; }
  .modal-zoom-image .carousel-modal-container .carousel .control-arrow.control-next:before {
    margin-right: 11px;
    transform: rotate(180deg); }
  .modal-zoom-image .carousel-modal-container .carousel .control-arrow.control-prev:before {
    margin-left: 11px;
    transform: translateY(-50%) rotate(0deg) !important; }

/* Bullets */
.gallery-container .carousel-container .control-dots,
.carousel-modal-container .carousel .control-dots {
  position: relative;
  padding-left: 0;
  bottom: 0px;
  left: 0;
  margin: 0;
  border: 0; }
  .gallery-container .carousel-container .control-dots .dot,
  .carousel-modal-container .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
    margin: 10px 5px 0 0;
    background-color: #939598 !important;
    box-shadow: 0 0 0 #fff;
    border: 0; }
    .gallery-container .carousel-container .control-dots .dot:last-child,
    .carousel-modal-container .carousel .control-dots .dot:last-child {
      margin: 10px 0px 0 0; }

@media (max-width: 768px) {
  .gallery-container .carousel .control-dots {
    display: block; } }

.modal-zoom-image .carousel-modal-container .carousel .control-dots .dot {
  background-color: #fff !important; }

.gallery-container .carousel-container {
  max-width: 100%;
  min-height: 0;
  min-width: 0; }

.gallery-container .carousel-container-wrapper {
  width: 100%; }
  @media (max-width: 768px) {
    .gallery-container .carousel-container-wrapper {
      height: 80%;
      margin-bottom: 0px; } }

.carousel-modal-wrapper {
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  margin: auto; }

.carousel-modal-container {
  width: 100%;
  min-height: 0;
  min-width: 0; }
  @media (max-width: 768px) {
    .carousel-modal-container {
      height: 100%; } }

.img-container {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-align: left; }

.gallery-container .carrousel-gallery-all .img-gallery {
  height: 100%;
  overflow: hidden; }
  .gallery-container .carrousel-gallery-all .img-gallery img {
    width: 100% !important;
    height: 100% !important;
    max-width: 100%;
    transition: all .5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    object-fit: cover; }
  .gallery-container .carrousel-gallery-all .img-gallery:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    mix-blend-mode: multiply; }
  .gallery-container .carrousel-gallery-all .img-gallery:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all .5s ease-in-out; }

.img-container-wrapper {
  width: calc(100% / 4);
  height: 200px;
  position: relative;
  padding: 4px; }
  @media (max-width: 768px) {
    .img-container-wrapper {
      width: 100%;
      height: auto; } }
  .all-slider-container .img-container-wrapper {
    width: 400px;
    height: 300px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .all-slider-container .img-container-wrapper {
        width: 100%; } }

.modal-zoom-image {
  height: 100%; }
  .modal-zoom-image .carousel-modal-container {
    height: 100%;
    flex-flow: column nowrap; }
    .modal-zoom-image .carousel-modal-container > div {
      height: 100%; }

.modal-zoom-image .zoomed-img {
  width: 100%;
  height: auto;
  position: relative;
  max-height: calc(95vh - 50px);
  overflow: auto; }
  .modal-zoom-image .zoomed-img img {
    width: auto;
    max-width: 100%;
    height: auto; }

.img-caption {
  max-width: 22vw;
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  color: #fff;
  text-align: left;
  z-index: 2; }
  @media (max-width: 768px) {
    .img-caption {
      max-width: 100%;
      padding: 10px;
      position: relative;
      bottom: 0;
      left: 0;
      display: block;
      text-align: center;
      color: #505152; } }
  .modal-zoom-image .zoomed-img .img-caption {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center; }

.gallery-img-toolbar {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  cursor: pointer;
  background-color: #505152;
  border-radius: 3px; }

.gallery-container .flex-carousel-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: .1%; }

.picture-placeholder {
  width: 100%;
  height: 98%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1%;
  overflow: hidden;
  border: 2px dashed #D0D2D1;
  border-radius: 4px;
  text-align: left;
  cursor: pointer; }
  @media (max-width: 768px) {
    .picture-placeholder {
      width: 100%;
      height: 100%; } }
  .picture-placeholder span {
    margin-top: 5px;
    color: #d6d6d6;
    font-size: 14px; }

.icon-add-image {
  width: 51px;
  height: 47px;
  display: block;
  background: url("../../../../img/icon-add-image.png"); }

.add-file {
  width: 90%;
  position: relative;
  margin: 10px auto 0; }

.input-file-text {
  padding: 30px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 2px dashed #f2f2f2; }
  .input-file-text span {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    color: #d6d6d6; }

.icon-upload-image {
  width: 51px;
  height: 47px;
  display: block;
  background: url("../../../../img/icon-upload-image.png"); }

.box-title-modal .icon-gallery {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: url("../../../../img/icon-gallery.png");
  background-size: cover; }

.modal-dialog-div .modal-edit-gallery label {
  margin-bottom: 10px;
  display: block; }

.modal-dialog-div .modal-edit-gallery .picture-show {
  margin-top: 30px;
  margin-bottom: 20px; }

.picture-show img {
  width: 200px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover; }

.add-another-picture,
.picture-hide {
  position: relative; }

.button-another-picture {
  width: 250px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
  margin: 10px auto 0;
  cursor: pointer;
  border: 2px dashed #f2f2f2; }
  .button-another-picture .icon-upload-image {
    width: 25px;
    height: 25px;
    margin-right: 6px;
    background-size: contain;
    background-repeat: no-repeat; }
  .button-another-picture span {
    font-size: 15px;
    font-weight: normal;
    color: #d6d6d6; }
