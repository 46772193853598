@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--navbar {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding: 1rem;
  z-index: 2; }
  @media (min-width: 1750px) {
    .template--7--navbar {
      width: 70%; } }
  .template--7--navbar .left-header {
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: space-between; }
  .template--7--navbar .select-language-hamburguer-template-7 {
    width: 100px; }
  .template--7--navbar .header--menu-burguer {
    opacity: 1;
    visibility: visible;
    height: 30px;
    display: grid;
    align-items: center; }
  .template--7--navbar .line {
    width: 30px;
    display: flex;
    height: 5px;
    background-color: white;
    border-radius: 5px; }
    @media (max-width: 860px) {
      .template--7--navbar .line {
        display: flex;
        background-color: bisque; } }
  .template--7--navbar .items--nav {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    padding: 0 1rem; }
    .template--7--navbar .items--nav ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center; }
      .template--7--navbar .items--nav ul li {
        margin-right: 30px;
        cursor: pointer; }
        @media (min-width: 1300px) {
          .template--7--navbar .items--nav ul li {
            margin-right: 60px; } }
        .template--7--navbar .items--nav ul li:last-child {
          margin-right: 0; }
        .template--7--navbar .items--nav ul li p {
          position: relative;
          font-family: "Cabin", sans-serif;
          font-size: 18px;
          color: var(--c-secondary); }
          @media (min-width: 1200px) {
            .template--7--navbar .items--nav ul li p {
              font-size: 20px; } }
          .template--7--navbar .items--nav ul li p:after {
            content: '';
            width: 1px;
            height: 2px;
            position: absolute;
            bottom: -4px;
            left: 50%;
            visibility: hidden;
            transform: translateX(-50%);
            transition: all .5s ease-in-out;
            opacity: 0;
            background: var(--c-secondary); }
        .template--7--navbar .items--nav ul li:hover p:after {
          width: 25px;
          opacity: 1;
          visibility: visible; }
  .template--7--navbar .header-tel {
    font-weight: bold;
    font-size: 16px;
    color: var(--c-secondary); }
  .template--7--navbar .header--menu-burguer {
    z-index: 150; }
    .template--7--navbar .header--menu-burguer .line {
      background: var(--c-secondary); }

.logo-name-coach {
  margin: 0;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: var(--c-secondary); }
  @media (min-width: 1300px) {
    .logo-name-coach {
      font-size: 26px; } }
  .logo-name-coach:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    right: -12px;
    bottom: 10px;
    background: var(--c-primary);
    border-radius: 100%; }

.select-language {
  min-width: 100px; }
