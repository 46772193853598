.template--7-footer {
  margin-top: 40px;
  padding: 15px;
  background-color: #4e4e4e;
  text-align: center; }
  .template--7-footer h4 {
    margin: 0;
    color: #fff;
    font-size: 26px;
    font-weight: 800; }
