@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--coach-numbers {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fbf9f9; }
  .template--6--coach-numbers .template--6--coach-numbers-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1rem; }
    @media (min-width: 990px) {
      .template--6--coach-numbers .template--6--coach-numbers-content {
        padding: 1rem;
        margin-top: -2vh; } }
    @media (min-width: 1169px) {
      .template--6--coach-numbers .template--6--coach-numbers-content {
        padding: 4vh 10%; } }
  .template--6--coach-numbers .item-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    margin: 0 10px 20px 10px;
    color: #4e4e4e; }
    .template--6--coach-numbers .item-info:last-child {
      margin-bottom: 0; }
    @media (min-width: 820px) {
      .template--6--coach-numbers .item-info {
        margin-bottom: 0; } }
    .template--6--coach-numbers .item-info .icon-item {
      width: 80px;
      height: 80px; }
      .template--6--coach-numbers .item-info .icon-item svg {
        z-index: 2; }
    .template--6--coach-numbers .item-info .coach-infos {
      text-align: left; }
      @media (min-width: 990px) {
        .template--6--coach-numbers .item-info .coach-infos {
          padding: 10px 20px; } }
      .template--6--coach-numbers .item-info .coach-infos p {
        margin: 0; }
      .template--6--coach-numbers .item-info .coach-infos .number-info {
        font-size: 35px;
        font-weight: 700; }
        @media (min-width: 990px) {
          .template--6--coach-numbers .item-info .coach-infos .number-info {
            font-size: 46px; } }
      .template--6--coach-numbers .item-info .coach-infos .title-info {
        width: 170px;
        font-size: 14px;
        line-height: 14px; }
        @media (min-width: 990px) {
          .template--6--coach-numbers .item-info .coach-infos .title-info {
            font-size: 18px;
            line-height: 18px; } }
  @media (max-width: 768px) {
    .template--6--coach-numbers {
      width: 100%;
      margin-top: 15px; } }
