.react-responsive-modal-modal .input-group .input-group-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #f2f2f2;
  border-radius: 4px; }
  .react-responsive-modal-modal .input-group .input-group-box:focus, .react-responsive-modal-modal .input-group .input-group-box:visited {
    outline: none;
    box-shadow: -2px 1px 1px rgba(0, 0, 0, 0.3); }
  .react-responsive-modal-modal .input-group .input-group-box label {
    font-size: 12px;
    text-transform: lowercase; }
  .react-responsive-modal-modal .input-group .input-group-box input {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: 12px; }
    .react-responsive-modal-modal .input-group .input-group-box input:hover, .react-responsive-modal-modal .input-group .input-group-box input:active, .react-responsive-modal-modal .input-group .input-group-box input:focus {
      box-shadow: 0 0 0 0 transparent; }
