@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.list-of-social-medias {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 2; }
  .list-of-social-medias:after {
    content: '';
    width: 1px;
    height: 80px;
    display: block;
    background: var(--c-primary);
    filter: brightness(0.8); }
  .list-of-social-medias li {
    margin: 5px; }
    .list-of-social-medias li:hover {
      cursor: pointer;
      transform: scale(1.4); }
