.transformation-areas-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: clamp(450px, 50vw, 700px);
  z-index: 1; }
  .transformation-areas-section .tranformation-areas-title {
    margin: 0 0 38px 0;
    padding-left: 12vw;
    width: 48vw;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(34px, 2.5vw, 48px);
    color: white; }
    .transformation-areas-section .tranformation-areas-title span {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      color: #FE7800; }
  @media (max-width: 1000px) {
    .transformation-areas-section .tranformation-areas-title {
      padding-left: 5vw; }
    .transformation-areas-section .pillar-list {
      padding-left: 5vw; } }
  @media (max-width: 750px) {
    .transformation-areas-section {
      margin: 50px 0;
      align-items: center; }
      .transformation-areas-section .bg-transformation-areas {
        display: none; }
      .transformation-areas-section .tranformation-areas-title {
        text-align: center;
        width: 75%;
        padding: 0;
        font-size: 28px; }
      .transformation-areas-section .pillar-list {
        padding: 0;
        width: 75%; }
      .transformation-areas-section .pillar-item span {
        margin-left: 15px;
        font-size: 16px; } }

.bg-transformation-areas {
  position: absolute;
  right: 0;
  height: clamp(450px, 50vw, 700px);
  z-index: -1; }

.pillar-list {
  display: flex;
  flex-direction: column;
  width: 42vw;
  padding-left: 12vw; }

.pillar-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  border-left: 5px solid #FE7800;
  padding: 20px;
  background: linear-gradient(90deg, #4F4F4F77, #4F4F4F11); }
  .pillar-item span {
    margin-left: 30px;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(20px, 1.5vw, 24px);
    font-weight: 600;
    color: white; }
  .pillar-item svg {
    width: clamp(28px, 2vw, 38px);
    height: clamp(26px, 2vw, 36px); }
