@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.slick-slider {
  display: flex; }

.slick-list {
  padding: 20px 0; }

.slick-dots li button:before {
  font-size: 20px;
  color: #ECC22E; }

.slick-dots li.slick-active button:before {
  color: #ECC22E; }
