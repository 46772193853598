@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.character-count {
  width: 100%;
  margin: 8px 0px 10px 0px;
  text-align: right;
  font-size: 12px; }

.max-container {
  max-width: 1200px;
  margin: auto; }

.error-message {
  color: red;
  font-size: 0.6em; }

.page-coach {
  width: 100%; }

.page-coach-container {
  width: 100%;
  display: block;
  margin: 0 auto; }

.no--content {
  width: 100%;
  display: flex;
  justify-content: center; }

a {
  text-decoration: none;
  color: inherit; }

.template-1 .social-whatsapp {
  display: none; }

:root {
  --c-primary: #0071a5; }

.template-2 { }
  .template-2 .is-mobile {
    height: calc(100vh - 38%) !important; }
  .template-2 .header {
    position: relative;
    background: #fff; }
  .template-2 .header-firsthalf {
    border-bottom: 4px solid var(--c-primary);
    background-color: #fff !important;
    clip-path: none; }
    .template-2 .header-firsthalf .content-container {
      border: none; }
  .template-2 .header-content-main {
    border-bottom: 2px solid var(--c-primary); }
    .template-2 .header-content-main .social-line {
      max-width: 1300px;
      margin: auto; }
      .template-2 .header-content-main .social-line .fa {
        color: #636363;
        text-shadow: none; }
      .template-2 .header-content-main .social-line .phone-social-line {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #fff;
        font-family: "Nunito Sans", sans-serif; }
        .template-2 .header-content-main .social-line .phone-social-line .header-whatsapp span {
          color: #fff;
          font-weight: lighter;
          font-size: 14px; }
    @media (max-width: 850px) {
      .template-2 .header-content-main {
        padding-bottom: 20px;
        padding-top: 20px; } }
  .template-2 .social-whatsapp {
    display: none; }
  .template-2 .header-image {
    display: none; }
  .template-2 .main-phrase-container {
    display: none; }
  .template-2 .is-mobile {
    height: calc(100vh - 38%) !important; }
  .template-2 .header-menu a {
    color: #636363;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300; }
    .template-2 .header-menu a:after {
      border-bottom: 2px solid var(--c-primary); }
  .template-2 .navbar-menu {
    max-width: 1300px;
    margin: auto;
    margin-top: 0;
    padding-bottom: 30px; }
    @media (max-width: 860px) {
      .template-2 .navbar-menu {
        padding: 20px; } }
  @media (max-width: 860px) {
    .template-2 .wrapper .menu {
      bottom: -10px; } }
  .template-2 .hamburger {
    background: var(--c-primary); }
    .template-2 .hamburger:before, .template-2 .hamburger:after {
      background: var(--c-primary); }
  @media (max-width: 850px) {
    .template-2 .navbar-menu a {
      color: var(--c-primary); } }
  @media (max-width: 850px) {
    .template-2 .wrapper input:checked + .menu .hamburger {
      background: var(--c-primary) !important; }
      .template-2 .wrapper input:checked + .menu .hamburger:before, .template-2 .wrapper input:checked + .menu .hamburger:after {
        background: var(--c-primary) !important; } }
  .template-2 .header-logo {
    color: #636363; }
    .template-2 .header-logo b {
      color: var(--c-primary); }
  .template-2 .header-whatsapp .fa {
    color: #636363;
    font-size: 18px; }
  .template-2 .header-whatsapp span {
    color: #636363;
    font-size: 16px; }
  .template-2 .header-biography {
    position: relative;
    background: transparent; }
    .template-2 .header-biography .container {
      background: transparent;
      clip-path: none; }
    .template-2 .header-biography .header-biography-content {
      align-items: center;
      padding: 40px 0px 60px; }
    .template-2 .header-biography .avatar {
      width: 160px;
      height: 160px; }
      @media (min-width: 800px) {
        .template-2 .header-biography .avatar {
          width: 280px;
          height: 280px; } }
    .template-2 .header-biography .info .title {
      color: #fff; }
    .template-2 .header-biography .info .info-content > div {
      color: #fff; }
    .template-2 .header-biography .info .expertises-list h4 {
      color: #fff; }
    .template-2 .header-biography .info .expertises-list ul {
      color: #fff; }
    .template-2 .header-biography .info .expertises-list li:before {
      background: #fff; }
    .template-2 .header-biography .info .info-contact {
      flex-flow: column wrap; }
    .template-2 .header-biography .info .contacts {
      flex-flow: row wrap; }
      .template-2 .header-biography .info .contacts .fa {
        color: var(--c-primary); }
      .template-2 .header-biography .info .contacts div {
        margin-right: 10px;
        color: #fff; }
      @media (max-width: 600px) {
        .template-2 .header-biography .info .contacts {
          flex-flow: column wrap; } }
    .template-2 .header-biography .info .button-container {
      position: absolute;
      bottom: -20px;
      margin-top: 20px; }
    .template-2 .header-biography .info .button.warning {
      background: var(--c-primary);
      border-color: var(--c-primary);
      z-index: 1; }
    .template-2 .header-biography .tooltip p {
      color: #fff; }
  .template-2 .header-biography-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center !important; }
    .template-2 .header-biography-bg:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5); }
  .template-2 .edit-avatar {
    background: var(--c-primary); }
  .template-2 .edit-input input:hover, .template-2 .edit-input input:active, .template-2 .edit-input input:focus,
  .template-2 .info-content-input textarea:hover,
  .template-2 .info-content-input textarea:active,
  .template-2 .info-content-input textarea:focus,
  .template-2 .toedit-input:hover,
  .template-2 .toedit-input:active,
  .template-2 .toedit-input:focus {
    border: 1px solid var(--c-primary); }
  .template-2 .areas-expertise-container small,
  .template-2 .areas-expertise-container .checkbox-input-group {
    color: #fff !important; }
  .template-2 .character-count {
    color: #fff !important; }
  .template-2 .main-container {
    background: var(--c-primary); }
    .template-2 .main-container.disable {
      background: #B21227; }
  .template-2 .resume-container .title-wrapper {
    text-align: left; }
  .template-2 .resume-container .title:before {
    margin: 0 0 20px;
    background-color: var(--c-primary); }
  .template-2 .resume-container .home-container {
    max-width: 1300px;
    margin: auto; }
  .template-2 .courses-container .tooltip p {
    text-align: left; }
  .template-2 .feedEdit:hover, .template-2 .feedEdit:focus, .template-2 .feedEdit:active {
    border: 1px solid var(--c-primary) !important; }
  .template-2 .resume-footer {
    background: var(--c-primary); }
    .template-2 .resume-footer .main-container.resume-disable {
      background: #fff;
      border: 2px solid var(--c-primary);
      color: var(--c-primary); }
      .template-2 .resume-footer .main-container.resume-disable.disable {
        color: #B21227; }
    .template-2 .resume-footer .info-title {
      color: #fff; }
    .template-2 .resume-footer .content {
      color: #fff; }
    .template-2 .resume-footer .icon-wrapper svg {
      fill: #fff; }
    .template-2 .resume-footer .control-arrow:before {
      display: none; }
  .template-2 .resume-footer-mobile .dot {
    background-color: var(--c-primary) !important;
    filter: brightness(0.7); }
  .template-2 .resume-footer-desk input {
    color: var(--c-primary); }
    .template-2 .resume-footer-desk input:hover {
      border: 1px solid var(--c-primary); }
  .template-2 .title:before {
    background-color: var(--c-primary); }
  .template-2 .video-wrapper {
    width: 100%; }
  .template-2 .video-container {
    height: 300px; }
  .template-2 .video-container-all {
    width: auto;
    position: relative; }
  .template-2 .video-caption {
    width: 100%;
    display: block;
    position: relative;
    margin: 0;
    white-space: initial; }
    @media (min-width: 500px) {
      .template-2 .video-caption {
        position: absolute;
        bottom: 10px;
        padding: 5px 10px;
        color: #fff; }
        .template-2 .video-caption:before {
          content: '';
          width: 100%;
          display: block;
          padding-top: 10px;
          border-top: 1px solid #fff; } }
  .template-2 .carrousel-videos-all .carousel-slider .control-dots {
    margin-top: 22px; }
  .template-2 .effect-contact,
  .template-2 .effect-contact-2 {
    display: none; }
  .template-2 .contact-content {
    background: var(--c-primary);
    clip-path: none; }
  .template-2 .contact-form .fa {
    color: var(--c-primary); }
  .template-2 .contact-form .button:hover {
    color: var(--c-primary) !important; }
  .template-2 .footer-wrapper {
    background: #424242; }
    .template-2 .footer-wrapper .social-line {
      color: #a3a3a3;
      text-shadow: 0 0 0 transparent; }
  .template-2 .home-footer {
    background: #fff; }
    .template-2 .home-footer a div {
      color: #626262; }
  .template-2 .box-title-modal {
    background: var(--c-primary); }
  .template-2 .posts--home .title-wrapper .title:before {
    background-color: var(--c-primary); }
  .template-2 .posts--home .btn-more {
    border: 1px solid var(--c-primary);
    background: var(--c-primary);
    color: #fff; }
    .template-2 .posts--home .btn-more:hover {
      background: transparent;
      color: var(--c-primary); }
  .template-2 .posts---content button {
    border: 1px solid var(--c-primary);
    color: var(--c-primary); }
    .template-2 .posts---content button:hover {
      background: var(--c-primary); }

:root {
  --c-primary: #ab1f24; }

.template-3 .testimonies-container .title:before, .template-3 .gallery-container .title:before {
  background-color: var(--c-primary); }

.is-mobile {
  height: calc(100vh - 38%) !important; }

.template-3 .header {
  position: relative;
  background: #fff; }

.template-3 .header-firsthalf {
  background-color: #fff !important;
  clip-path: none; }

.template-3 .content-container {
  border: none; }

.template-3 .header-content {
  padding-bottom: 30px;
  border-bottom: 2px solid var(--c-primary); }
  @media (max-width: 850px) {
    .template-3 .header-content {
      padding-bottom: 20px;
      padding-top: 20px; } }

.template-3 .social-line .fa {
  color: #636363;
  text-shadow: none; }
  .template-3 .social-line .fa:hover {
    transform: scale(1.2);
    color: #636363; }

.template-3 .social-line .phone-social-line {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #fff;
  font-family: "Nunito Sans", sans-serif; }
  .template-3 .social-line .phone-social-line .header-whatsapp span {
    color: #fff;
    font-weight: lighter;
    font-size: 14px; }

.template-3 .header-image {
  height: 0px;
  position: relative;
  opacity: 0; }

.template-3 .main-phrase-container {
  display: none; }

.template-3 .header-logo {
  position: absolute; }
  @media (min-width: 860px) {
    .template-3 .header-logo {
      transform: translateX(-50%); } }
  @media (max-width: 860px) {
    .template-3 .header-logo b {
      margin-right: 5px; } }

*, *:before, *:after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding-left: 0;
  margin: 0;
  list-style: none; }

li {
  list-style: none; }

button {
  background: transparent;
  border: 0; }
  button:hover, button:active, button:focus {
    outline: none; }

.container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DCDCDC;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DCDCDC; }

.template-3 .header-menu {
  width: 100%;
  justify-content: center; }
  .template-3 .header-menu a {
    color: #636363;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300; }
    .template-3 .header-menu a:after {
      border-bottom: 2px solid var(--c-primary); }
  .template-3 .header-menu li {
    margin-right: 50px; }
    @media (max-width: 1000px) {
      .template-3 .header-menu li {
        margin-right: 40px; } }
    @media (max-width: 900px) {
      .template-3 .header-menu li {
        margin-right: 30px; } }
    .template-3 .header-menu li:last-child {
      margin-right: 0; }
    @media (max-width: 1000px) {
      .template-3 .header-menu li:nth-child(3) {
        margin-right: 20px; } }
    @media (max-width: 900px) {
      .template-3 .header-menu li:nth-child(3) {
        margin-right: 30px; } }
  @media (max-width: 860px) {
    .template-3 .header-menu {
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden; } }

.template-3 .navbar-menu {
  margin-top: 0;
  background-color: #e9e9e9;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0; }
  .template-3 .navbar-menu .header-whatsapp {
    display: none; }

.template-3 .hamburger {
  background: var(--c-primary); }
  .template-3 .hamburger:before, .template-3 .hamburger:after {
    background: var(--c-primary); }

@media (max-width: 850px) {
  .template-3 .navbar-menu a {
    color: var(--c-primary); } }

@media (max-width: 850px) {
  .template-3 .wrapper input:checked + .menu .hamburger {
    background: var(--c-primary) !important; }
    .template-3 .wrapper input:checked + .menu .hamburger:before, .template-3 .wrapper input:checked + .menu .hamburger:after {
      background: var(--c-primary) !important; } }

.template-3 .wrapper .menu {
  bottom: -15px;
  top: 50%; }

.template-3 .header-logo {
  display: none;
  color: var(--c-primary);
  font-weight: 500; }
  .template-3 .header-logo.item {
    display: block;
    width: auto;
    position: relative;
    left: 0%;
    transform: translateX(0%);
    max-width: 370px;
    text-align: center;
    margin-right: 20px; }
    .template-3 .header-logo.item b {
      color: #636363;
      font-weight: 300; }
    .template-3 .header-logo.item br {
      display: none; }
  @media (max-width: 860px) {
    .template-3 .header-logo {
      display: block; }
      .template-3 .header-logo b {
        color: #636363;
        font-weight: 300; }
      .template-3 .header-logo.item {
        display: none; } }

.template-3 .header-whatsapp .fa {
  color: #636363;
  font-size: 18px; }

.template-3 .header-whatsapp span {
  color: #636363;
  font-size: 16px; }

.template-3 .header-biography {
  bottom: 0;
  background: transparent; }
  .template-3 .header-biography .container {
    background: transparent;
    clip-path: none; }
    .template-3 .header-biography .container .home-container {
      flex-direction: row;
      display: flex;
      justify-content: center; }
      .template-3 .header-biography .container .home-container.edit {
        flex-direction: column; }
        .template-3 .header-biography .container .home-container.edit .header-biography-content .info {
          max-width: fit-content;
          width: 100%; }
  .template-3 .header-biography .header-biography-content {
    align-items: center;
    padding: 40px 0px 60px; }
  .template-3 .header-biography .avatar {
    width: 160px;
    height: 160px; }
    @media (min-width: 800px) {
      .template-3 .header-biography .avatar {
        width: 280px;
        height: 280px; } }
  .template-3 .header-biography .info .title {
    color: #636363; }
  .template-3 .header-biography .info .info-content > div {
    color: #636363; }
  .template-3 .header-biography .info .expertises-list h4 {
    color: #636363; }
  .template-3 .header-biography .info .expertises-list ul {
    color: #636363; }
  .template-3 .header-biography .info .expertises-list li:before {
    background: #636363 !important; }
  .template-3 .header-biography .info .info-contact {
    flex-flow: column wrap; }
  .template-3 .header-biography .info .contacts {
    flex-flow: row wrap; }
    @media (max-width: 800px) {
      .template-3 .header-biography .info .contacts {
        justify-content: center; } }
    @media (max-width: 600px) {
      .template-3 .header-biography .info .contacts {
        flex-flow: column wrap; } }
    .template-3 .header-biography .info .contacts .fa {
      color: var(--c-primary); }
    .template-3 .header-biography .info .contacts div {
      margin-right: 10px;
      color: #636363; }
  .template-3 .header-biography .info .button-container {
    margin-top: 30px; }
  .template-3 .header-biography .info .button.warning {
    border-radius: 0px;
    background: var(--c-primary);
    border-color: var(--c-primary); }

.template-3 .edit-avatar {
  background: var(--c-primary); }

.template-3 .edit-input input:hover,
.template-3 .info-content-input textarea:hover,
.template-3 .toedit-input:hover {
  border: 1px solid var(--c-primary); }

.template-3 .areas-expertise-container small,
.template-3 .areas-expertise-container .checkbox-input-group {
  color: var(--c-primary) !important; }

.template-3 .character-count {
  color: #fff !important; }

.template-3 .main-container {
  background: var(--c-primary); }
  .template-3 .main-container.disable {
    background: #000; }

*, *:before, *:after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding-left: 0;
  margin: 0;
  list-style: none; }

li {
  list-style: none; }

button {
  background: transparent;
  border: 0; }
  button:hover, button:active, button:focus {
    outline: none; }

.container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DCDCDC;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DCDCDC; }

.template-3 .resume-container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important; }
  .template-3 .resume-container .resume-content {
    padding-bottom: 40px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.5); }
    .template-3 .resume-container .resume-content .title-wrapper {
      text-align: center;
      margin: 0px 0px 45px 0px; }
      .template-3 .resume-container .resume-content .title-wrapper .title {
        color: #fff;
        text-align: center;
        margin-top: 0px;
        padding-top: 47px;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 900; }
        .template-3 .resume-container .resume-content .title-wrapper .title:before {
          margin: 0 0 20px;
          background-color: transparent; }

.template-3 .courses-container div > div {
  text-align: left; }

.template-3 .feedEdit:hover {
  border: 1px solid var(--c-primary) !important; }

.template-3 .resume-footer {
  margin-top: 0px;
  background-color: #fff; }
  .template-3 .resume-footer .info-title {
    color: var(--c-primary);
    text-align: center;
    margin-top: 10px;
    font-family: "Nunito Sans", sans-serif; }
  .template-3 .resume-footer .content {
    color: #636363;
    font-family: "Nunito Sans", sans-serif; }
  .template-3 .resume-footer .icon-wrapper svg {
    fill: var(--c-primary); }
  .template-3 .resume-footer .control-arrow:before {
    display: none; }

.template-3 .resume-footer-mobile .dot {
  background-color: var(--c-primary) !important; }

.template-3 .resume-footer-desk .info-card {
  flex-direction: column;
  align-items: center; }

.template-3 .resume-footer-desk input {
  color: var(--c-primary);
  border: 1px solid var(--c-primary); }
  .template-3 .resume-footer-desk input:hover {
    border: 1px solid var(--c-primary); }

.template-3 .content {
  text-align: center; }

*, *:before, *:after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding-left: 0;
  margin: 0;
  list-style: none; }

li {
  list-style: none; }

button {
  background: transparent;
  border: 0; }
  button:hover, button:active, button:focus {
    outline: none; }

.container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DCDCDC;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DCDCDC; }

.template-3 .videos-container {
  background: #ecedf0; }
  .template-3 .videos-container .home-container {
    height: fit-content; }
    .template-3 .videos-container .home-container .title-wrapper {
      margin: 0px 0 3.5% 0; }
      .template-3 .videos-container .home-container .title-wrapper .title {
        padding-top: 90px;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 900; }
        .template-3 .videos-container .home-container .title-wrapper .title:before {
          background-color: var(--c-primary); }
    .template-3 .videos-container .home-container .video-wrapper {
      max-width: 33%;
      padding: 3px; }
      @media (max-width: 768px) {
        .template-3 .videos-container .home-container .video-wrapper {
          max-width: 100%; } }
    .template-3 .videos-container .home-container .video-container {
      max-height: auto; }
    .template-3 .videos-container .home-container .video-container-all {
      width: auto;
      position: relative; }
    .template-3 .videos-container .home-container .video-caption {
      width: 100%;
      display: block;
      position: relative;
      margin: 0;
      white-space: initial; }
      @media (min-width: 500px) {
        .template-3 .videos-container .home-container .video-caption {
          bottom: 0px;
          padding: 5px 10px;
          color: #636363; }
          .template-3 .videos-container .home-container .video-caption:before {
            content: '';
            width: 100%;
            display: block;
            padding-top: 10px;
            border-top: 1px solid transparent; } }
    .template-3 .videos-container .home-container .carrousel-videos-all .carousel-slider .control-dots {
      margin-top: 22px; }
  .template-3 .videos-container .flex-carousel-container {
    align-items: flex-start;
    justify-content: flex-start; }
  .template-3 .videos-container .all-slider-container .video-wrapper {
    max-width: 100%; }

.is-mobile {
  height: calc(100vh - 38%) !important; }

.template-3 .gallery-container .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 900; }

.template-3 .gallery-container .slider-wrapper {
  width: 70%; }

.template-3 .img-container-wrapper {
  width: 60%; }
  .template-3 .img-container-wrapper:nth-child(2), .template-3 .img-container-wrapper:nth-child(3) {
    width: 40%; }
  @media (max-width: 768px) {
    .template-3 .img-container-wrapper {
      width: 100%; }
      .template-3 .img-container-wrapper:nth-child(1), .template-3 .img-container-wrapper:nth-child(3), .template-3 .img-container-wrapper:nth-child(5), .template-3 .img-container-wrapper:nth-child(6) {
        width: 100%; } }

.template-3 .effect-contact,
.template-3 .effect-contact-2 {
  display: none; }

.template-3 .contact-content {
  background: var(--c-primary);
  clip-path: none; }

.template-3 .contact-form .fa {
  color: var(--c-primary); }

.template-3 .contact-form .button:hover {
  color: var(--c-primary) !important; }

.template-3 .footer-wrapper {
  background: #424242; }
  .template-3 .footer-wrapper .social-line {
    color: #a3a3a3; }

.template-3 .home-footer {
  background: #fff; }
  .template-3 .home-footer a div {
    color: #626262; }

.template-3 .box-title-modal {
  background: var(--c-primary); }

.template-3 .header-firsthalf .social-line {
  background-color: var(--c-primary);
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding: 5px calc( 50% - 571px); }
  .template-3 .header-firsthalf .social-line .fa {
    color: #fff; }
    .template-3 .header-firsthalf .social-line .fa:hover {
      transform: scale(1.2); }

.template-3 .contact-wrapper {
  background: url("../../../img/background-contact.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .template-3 .contact-wrapper .contact-content {
    background-color: rgba(0, 0, 0, 0.5); }
    .template-3 .contact-wrapper .contact-content .contact-form .fa {
      color: #636363; }
    .template-3 .contact-wrapper .contact-content .contact-form .button {
      background: var(--c-primary);
      border: 1px solid var(--c-primary);
      border-radius: 1px; }

.header-modal.template-3, .body-modal.template-3 {
  border: 1px solid var(--c-primary); }

.header-modal.template-3 {
  background-color: var(--c-primary);
  border-radius: 4px 4px 0px 0px; }

.body-modal.template-3 {
  border-radius: 0px 0px 4px 4px; }
  .body-modal.template-3 .modal-configurations-content .menu-configurations li:after {
    background: var(--c-primary) !important; }
  .body-modal.template-3 .modal-configurations-content .edit-changeGround {
    background: var(--c-primary) !important; }
  .body-modal.template-3 .modal-configurations-content .configurations-buttons .button {
    border: 1px solid var(--c-primary) !important;
    background-color: var(--c-primary) !important; }

.posts--home .title-wrapper .title:before {
  background-color: var(--c-primary); }

.posts--home .btn-more {
  border: 1px solid var(--c-primary);
  background: var(--c-primary);
  color: #fff; }
  .posts--home .btn-more:hover {
    background: transparent;
    color: var(--c-primary); }

.post---content button {
  border: 1px solid var(--c-primary);
  color: var(--c-primary); }
  .post---content button:hover {
    background: var(--c-primary); }

:root {
  --c-primary: #098846; }

.template-4 .header {
  position: relative; }

.template-4 .content-container {
  border-top: 0px solid transparent; }

.template-4 .header-content-main .social-line {
  margin-top: 0;
  padding: 10px 50px;
  background: #a5a5a5; }
  .template-4 .header-content-main .social-line i {
    text-shadow: 0 0 0 transparent; }
  .template-4 .header-content-main .social-line .home-container {
    max-width: 1300px;
    margin: auto;
    padding: 0; }

.template-4 .header-content-main .header-whatsapp {
  display: none; }

.template-4 .header-content-main .header-logo {
  padding: 10px;
  border-left: 1px solid #a5a5a5;
  border-right: 1px solid #a5a5a5;
  font-weight: 400;
  color: var(--c-primary); }
  .template-4 .header-content-main .header-logo b {
    font-weight: 300;
    color: #a5a5a5; }

.template-4 .header-firsthalf {
  clip-path: none; }

.template-4 .header-image {
  height: 500px;
  position: relative;
  top: 0; }

.template-4 .button-block {
  display: none; }

.template-4 .main-phrase {
  margin: 0 0 0 auto;
  align-items: flex-end;
  text-align: left; }
  @media (max-width: 860px) {
    .template-4 .main-phrase {
      margin: auto;
      align-items: center; } }

.template-4 .main-phrase-container {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0; }

.template-4 #mainPhrase:hover, .template-4 #mainPhrase:active, .template-4 #mainPhrase:focus,
.template-4 .toedit-input:hover,
.template-4 .toedit-input:active,
.template-4 .toedit-input:focus {
  border: 1px solid var(--c-primary); }

.template-4 .main-phrase-author {
  display: none; }

.template-4 .navbar-menu {
  margin-top: 0;
  background: #e9e9e9; }
  .template-4 .navbar-menu .home-container {
    padding: 0;
    position: relative;
    max-width: 1300px;
    margin: auto; }

.template-4 .header-menu {
  margin: 0;
  padding-right: 10px; }
  .template-4 .header-menu a {
    color: #636363; }
    .template-4 .header-menu a:after {
      border-bottom: 2px solid var(--c-primary); }

.template-4 .hamburger,
.template-4 .wrapper input:checked + .menu .hamburger {
  background: var(--c-primary); }
  .template-4 .hamburger:before, .template-4 .hamburger:after,
  .template-4 .wrapper input:checked + .menu .hamburger:before,
  .template-4 .wrapper input:checked + .menu .hamburger:after {
    background: var(--c-primary); }

.template-4 .wrapper .menu {
  right: 0;
  top: 50%; }

.template-4 .wrapper ul li a {
  color: var(--c-primary); }

.template-4 .wrapper input:checked + .menu + ul {
  z-index: 1000; }

.template-4 .header-biography .container {
  clip-path: none;
  background: #eeeeee; }
  @media (max-width: 860px) {
    .template-4 .header-biography .container {
      background: var(--c-primary); } }

.template-4 .header-biography .header-biography-bg {
  display: none; }

.template-4 .header-biography .main-container {
  margin: 40px auto 80px; }

.template-4 .header-biography-content {
  width: 90%;
  z-index: 2;
  padding: 30px;
  align-items: center;
  position: relative;
  margin: -40px auto 0;
  background: var(--c-primary);
  box-shadow: 1px 6px 20px 0px rgba(0, 0, 0, 0.4); }
  @media (max-width: 860px) {
    .template-4 .header-biography-content {
      width: 100%;
      position: relative;
      left: 0;
      bottom: 0px;
      margin: 0 auto;
      transform: translateX(0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.4); } }
  .template-4 .header-biography-content .info {
    color: #fff; }
  .template-4 .header-biography-content .info-contact {
    flex-flow: column wrap; }
  .template-4 .header-biography-content .contacts {
    flex-flow: row wrap;
    justify-content: flex-start; }
    .template-4 .header-biography-content .contacts .fa {
      color: #fff; }
    @media (max-width: 860px) {
      .template-4 .header-biography-content .contacts {
        flex-flow: column wrap; } }
    .template-4 .header-biography-content .contacts div {
      margin-right: 20px; }
      @media (max-width: 600px) {
        .template-4 .header-biography-content .contacts div {
          margin-right: 0; } }
  .template-4 .header-biography-content .button {
    margin-top: 20px;
    background: var(--c-primary);
    border-color: var(--c-primary);
    border-radius: 5px;
    box-shadow: 0 0 0 0 transparent;
    text-shadow: 0 0 0 transparent;
    transition: all .4s ease-in-out;
    filter: brightness(1.18);
    font-weight: bold; }
    .template-4 .header-biography-content .button:hover {
      border-color: #fff;
      background: transparent; }

.template-4 .expertises-list li:before {
  background: #fff; }

.template-4 .main-container {
  background: var(--c-primary); }
  .template-4 .main-container.disable {
    background: #E8340C; }

.template-4 .edit-avatar {
  background: #fff;
  color: var(--c-primary); }
  .template-4 .edit-avatar .fa {
    color: var(--c-primary); }

.template-4 .edit-input input:hover, .template-4 .edit-input input:focus, .template-4 .edit-input input:active,
.template-4 .info-content-input textarea:hover,
.template-4 .info-content-input textarea:focus,
.template-4 .info-content-input textarea:active {
  border: 1px solid #fff; }

.template-4 .areas-expertise-container small {
  color: #fff !important; }

.template-4 .areas-expertise-container .checkbox-input {
  color: #fff; }

.template-4 .character-count {
  color: #fff !important; }

.template-4 .resume-container {
  padding-top: 50px;
  background: #eeeeee !important; }
  .template-4 .resume-container .title-wrapper {
    margin: 0 0 45px 0; }
  .template-4 .resume-container .title {
    margin-top: 0; }
    .template-4 .resume-container .title:before {
      background-color: var(--c-primary); }
  @media (max-width: 860px) {
    .template-4 .resume-container {
      padding-top: 20px; } }

.template-4 .resume-footer-content {
  background: #fff; }
  .template-4 .resume-footer-content .info-card {
    width: 250px;
    max-width: 100%;
    height: 250px;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    flex: initial;
    padding: 20px;
    margin: 0 25px;
    background: var(--c-primary);
    border-radius: 100%;
    filter: brightness(1.1); }
    @media (max-width: 768px) {
      .template-4 .resume-footer-content .info-card {
        margin: 0 auto 0 auto; } }
    .template-4 .resume-footer-content .info-card:first-child {
      background: var(--c-primary);
      filter: brightness(1); }
    .template-4 .resume-footer-content .info-card:last-child {
      background: var(--c-primary);
      filter: brightness(1.1) saturate(2); }
      @media (max-width: 768px) {
        .template-4 .resume-footer-content .info-card:last-child {
          margin: 0 auto 0 auto; } }
    .template-4 .resume-footer-content .info-card .icon-wrapper {
      margin-bottom: 20px; }
      .template-4 .resume-footer-content .info-card .icon-wrapper svg {
        width: 60px;
        fill: #fff; }
    .template-4 .resume-footer-content .info-card .info-wrapper {
      padding-left: 0;
      text-align: center;
      color: #fff;
      font-size: 30px; }
      .template-4 .resume-footer-content .info-card .info-wrapper .content {
        color: #fff;
        font-size: 20px; }

.template-4 .resume-footer-desk {
  justify-content: center; }

.template-4 .courses-container textarea:hover, .template-4 .courses-container textarea:active, .template-4 .courses-container textarea:focus {
  border: 1px solid var(--c-primary) !important; }

.template-4 .courses-container .character-count {
  color: var(--c-primary) !important; }

.template-4 .info-edit {
  color: var(--c-primary); }
  .template-4 .info-edit:hover, .template-4 .info-edit:active, .template-4 .info-edit:focus {
    border: 1px solid var(--c-primary) !important; }

.template-4 .videos-container .title:before {
  background-color: var(--c-primary); }

.template-4 .video-wrapper {
  width: calc(96% / 3); }
  .template-4 .video-wrapper:first-child {
    width: 100%;
    margin-bottom: 20px; }
    .template-4 .video-wrapper:first-child .video-container-all {
      width: auto;
      position: relative;
      align-items: center; }
    .template-4 .video-wrapper:first-child .video-caption {
      margin: 0;
      position: absolute;
      bottom: 10px;
      left: 10px;
      text-align: left;
      color: #fff; }

.template-4 .video-container {
  border-radius: 5px; }

.template-4 .video-caption {
  margin: 10px 0;
  color: #484848; }

.template-4 .video-container-all {
  align-items: flex-start; }

.template-4 .video-and-caption-container {
  justify-content: flex-start; }

.template-4 .testimonies-container .title:before {
  background-color: var(--c-primary); }

.template-4 .gallery-container .title:before {
  background-color: var(--c-primary); }

.template-4 .img-container-wrapper {
  width: 20%; }
  .template-4 .img-container-wrapper:nth-child(1), .template-4 .img-container-wrapper:nth-child(3), .template-4 .img-container-wrapper:nth-child(5), .template-4 .img-container-wrapper:nth-child(6) {
    width: 40%; }
  @media (max-width: 768px) {
    .template-4 .img-container-wrapper {
      width: 100%; }
      .template-4 .img-container-wrapper:nth-child(1), .template-4 .img-container-wrapper:nth-child(3), .template-4 .img-container-wrapper:nth-child(5), .template-4 .img-container-wrapper:nth-child(6) {
        width: 100%; } }

.template-4 .contact-wrapper {
  background: #979797; }
  .template-4 .contact-wrapper .effect-contact,
  .template-4 .contact-wrapper .effect-contact-2 {
    display: none; }

.template-4 .contact-content {
  background: #979797; }
  .template-4 .contact-content .fa {
    color: #444142; }

.template-4 .contact-form .button:hover {
  color: #a5a5a5 !important; }

.template-4 .footer-wrapper {
  background: var(--c-primary); }
  .template-4 .footer-wrapper .section-up {
    background: var(--c-primary); }
    .template-4 .footer-wrapper .section-up .social-line .fa {
      text-shadow: 0 0 0 transparent; }

.template-4 .anchor-top a {
  background: var(--c-primary);
  filter: brightness(0.85); }

.template-4 .home-footer {
  background: #fff;
  border-bottom: 6px solid var(--c-primary); }

.template-4 .posts--home .title-wrapper .title:before {
  background-color: var(--c-primary); }

.template-4 .posts--home .btn-more {
  border: 1px solid var(--c-primary);
  background: var(--c-primary);
  color: #fff; }
  .template-4 .posts--home .btn-more:hover {
    background: transparent;
    color: var(--c-primary); }

.template-4 .post---content button {
  border: 1px solid var(--c-primary);
  color: var(--c-primary); }
  .template-4 .post---content button:hover {
    background: var(--c-primary); }
