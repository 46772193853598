.container-loading {
  width: 93%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .container-loading .loading-card-item {
    margin: 0 10px 30px;
    justify-self: center; }
    @media (min-width: 750px) {
      .container-loading .loading-card-item {
        margin: 0 20px 40px 0; } }
  @media (min-width: 1000px) {
    .container-loading {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; } }
