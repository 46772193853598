.carousel.carousel-slider {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center; }

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  position: relative;
  padding: 10px button;
    padding-background-color: lightgray !important; }

.control-dots .dot {
  border: #fff 1px solid;
  background-color: #000 !important; }
