.template--5-testimonial {
  width: 1440px;
  max-width: 100%;
  margin: auto;
  padding: 1rem 1rem 50px; }
  @media (min-width: 650px) {
    .template--5-testimonial {
      padding: 50px 0; } }
  @media (min-width: 990px) {
    .template--5-testimonial {
      width: 60%;
      padding: 40px 0 70px; } }
  .template--5-testimonial .slick-list {
    width: 100%; }
  .template--5-testimonial .carousel-main .slick-slide {
    padding: 15px; }
    .template--5-testimonial .carousel-main .slick-slide:first-child {
      padding: 15px; }
  .template--5-testimonial .arrowPrev,
  .template--5-testimonial .arrowNext {
    cursor: pointer;
    z-index: 3; }
    .template--5-testimonial .arrowPrev:hover,
    .template--5-testimonial .arrowNext:hover {
      transform: scale(1.05); }
  .template--5-testimonial .arrowPrev svg {
    transform: rotate(90deg); }
  .template--5-testimonial .arrowNext svg {
    transform: rotate(-90deg); }

.template--5-testimonial-container {
  width: 98%;
  margin: 0 auto; }
  @media (min-width: 750px) {
    .template--5-testimonial-container {
      width: 60%; } }
