.main-container {
  width: 180px;
  height: auto;
  position: relative;
  margin: 10px auto 40px;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  background: #F17127; }
  .main-container > div {
    padding: 10px; }
  .main-container i {
    padding: 0;
    margin-right: 5px;
    font-size: 14px; }
  .main-container.disable {
    background: #E8340C; }
