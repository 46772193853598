.blog-post-card {
    display: flex;

    margin: 0 auto 30px;
    padding: 0 20px;

    max-width: 1300px;

    cursor: pointer;

    .thumbnail {
        margin-right: 30px;

        width: 580px;
        height: 320px;
        max-width: 55%;

        img {
            border-radius: 6px;

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-info {
        width: 50%;

        header {
            .author-area {
                .author {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            h3 {
                margin: 15px 0;
                color: #000;
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 700;

                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                white-space: pre-wrap;
            }
        }

        p {
            margin: 15px 0 25px;
            color: #59575B;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 500;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            white-space: pre-wrap;
        }

        footer {
            display: flex;
            align-items: center;

            .categories-area {
                .category {
                    color: #FE7800;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    margin-right: 5px;
                }
            }

            .divider {
                margin: 0 3px;
            }

            .published-date {
                color: #282828;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .lazy-img-placeholder {
        border-radius: 6px;
    }

    @media (max-width: 760px) {
        flex-direction: column;
        margin-bottom: 70px;

        .card-info {
            width: 100%;
        }

        .thumbnail {
            width: 100%;
            max-width: unset;
            height: 220px;
            margin-bottom: 15px;
        }
    }
}
