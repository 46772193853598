@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6-video-card {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  position: relative; }
  @media (min-width: 850px) {
    .template--6-video-card {
      flex-flow: row wrap; } }
  .template--6-video-card:before {
    content: '';
    width: 1%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: var(--c-primary);
    mix-blend-mode: multiply;
    transition: all .5s ease-in-out; }
  .template--6-video-card:hover {
    cursor: pointer; }
    .template--6-video-card:hover .background-hover-effect {
      left: 0; }
    .template--6-video-card:hover:before {
      width: 100%;
      opacity: .8; }
    .template--6-video-card:hover .player-hover {
      opacity: 1;
      visibility: visible; }
  .template--6-video-card .video-infos {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1rem;
    z-index: 1;
    position: relative;
    background-color: var(--c-primary);
    color: #fff; }
    @media (min-width: 850px) {
      .template--6-video-card .video-infos {
        width: 50%; } }
    @media (min-width: 1100px) {
      .template--6-video-card .video-infos {
        width: 40%; } }
    .template--6-video-card .video-infos .video-youtube {
      width: auto;
      padding: 5px 20px;
      z-index: 1;
      border: solid 1px #fff;
      font-size: 16px; }
    .template--6-video-card .video-infos .video-time {
      width: 100%;
      display: block;
      z-index: 1;
      margin: 8px 0 0;
      font-size: 13px; }
    .template--6-video-card .video-infos .video-subtitle {
      padding: 15px 0;
      margin: 0;
      z-index: 1;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #fff;
      text-align: left; }
      @media (min-width: 850px) {
        .template--6-video-card .video-infos .video-subtitle {
          padding: 0; } }
    .template--6-video-card .video-infos .video-btn {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      z-index: 1; }
    .template--6-video-card .video-infos .icon-play-float {
      margin-right: 8px; }

.template--6-video-image {
  width: 100%;
  position: relative; }
  @media (min-width: 850px) {
    .template--6-video-image {
      width: 50%; } }
  @media (min-width: 1100px) {
    .template--6-video-image {
      width: 60%; } }
  .template--6-video-image:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    right: 50%;
    top: 95%;
    transform: translate(50%, 0);
    z-index: 2;
    border-left: 28px solid transparent;
    border-right: 28px solid transparent;
    border-bottom: 20px solid var(--c-primary); }
    @media (min-width: 850px) {
      .template--6-video-image:before {
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-right: 20px solid var(--c-primary); } }
  .template--6-video-image:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4); }
  .template--6-video-image img {
    width: 100%;
    max-height: 314px;
    display: block;
    object-fit: cover; }
  .template--6-video-image .player-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all .5s ease-in-out;
    opacity: 0;
    visibility: hidden; }
