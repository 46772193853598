@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--footer {
  width: 100%;
  position: relative; }
  .template--5--footer:before {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    top: -30px;
    left: 0; }

.template--5--footer-content {
  position: relative;
  padding: 10px;
  z-index: 1;
  background: #5A5A5A; }
  .template--5--footer-content p {
    margin: 0;
    font-size: 26px;
    text-align: center;
    color: #fff; }

.btn-scroll-top {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  right: 1rem;
  cursor: pointer;
  background: #5A5A5A;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all .5s ease-in-out; }
  .btn-scroll-top svg {
    transform: rotate(180deg); }
  .btn-scroll-top:hover {
    transform: translateY(-10px); }
