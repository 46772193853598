@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--biography {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem;
  padding-top: 40px; }
  .template--6--biography .biography-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media (min-width: 990px) {
      .template--6--biography .biography-content {
        flex-flow: row wrap; } }
    .template--6--biography .biography-content img {
      width: 250px;
      height: 300px;
      object-fit: cover; }
