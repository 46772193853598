.template-contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  padding-bottom: 45px; }
  .template-contact-form .contact-form {
    display: flex;
    gap: 15px; }
  .template-contact-form .form-group {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px; }
    .template-contact-form .form-group .input-control {
      height: fit-content;
      width: 100%; }
      .template-contact-form .form-group .input-control input {
        height: 40px;
        width: 100%;
        padding: 12px;
        border: 0;
        outline: none;
        border: 3px solid transparent;
        border-radius: 5px; }
    .template-contact-form .form-group .input-control-error input {
      border: 3px solid red;
      border-radius: 5px; }
    .template-contact-form .form-group .text-counter {
      flex: 1;
      width: 100%;
      position: relative; }
      .template-contact-form .form-group .text-counter .input-control {
        height: 100%; }
      .template-contact-form .form-group .text-counter textarea {
        max-height: max-content !important;
        height: 100% !important;
        width: 100%;
        border: 0;
        border-radius: 5px;
        outline: none;
        padding: 12px; }
      .template-contact-form .form-group .text-counter > span {
        position: absolute;
        right: 0px;
        font-size: 14px;
        bottom: -22px;
        color: #fff;
        font-weight: bold; }
    .template-contact-form .form-group .form-group--2 {
      display: flex;
      gap: 15px; }
    .template-contact-form .form-group .contact-phone-input {
      height: 40px; }
      .template-contact-form .form-group .contact-phone-input input {
        width: 100%;
        padding-left: 48px;
        height: 40px;
        border: none;
        background-color: #fff;
        color: #000;
        border-radius: 5px; }
      .template-contact-form .form-group .contact-phone-input .form-control {
        border: none; }
      .template-contact-form .form-group .contact-phone-input .flag-dropdown {
        border: none;
        background-color: #fff; }
      .template-contact-form .form-group .contact-phone-input .country-list li {
        display: flex;
        align-items: center;
        gap: 10px; }
        .template-contact-form .form-group .contact-phone-input .country-list li > div {
          margin: 0px;
          border-radius: 0; }
  .template-contact-form .contact-button {
    width: 100%;
    max-width: 300px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #fff;
    margin: 30px auto 0;
    padding: 6px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s; }
    .template-contact-form .contact-button:hover:not(:disabled) {
      transform: scale(102%); }
    .template-contact-form .contact-button:disabled {
      cursor: not-allowed;
      opacity: 0.5; }
  @media (max-width: 600px) {
    .template-contact-form .contact-form {
      flex-direction: column; }
    .template-contact-form .form-group {
      flex-direction: column; }
    .template-contact-form .form-group--2 {
      flex-direction: column; } }
  @media (max-width: 680px) {
    .template-contact-form .contact-form {
      flex-direction: column; }
    .template-contact-form .form-group {
      flex-direction: column; } }

.dialog-message {
  font-size: 14px;
  font-weight: bold;
  padding: 16px;
  border-radius: 10px;
  max-width: 400px;
  margin: 25px auto;
  color: #0d1213;
  letter-spacing: 0.1em; }

.message-success {
  background: #c2efdfd6;
  border: 6px solid #5dad90; }

.message-warning {
  background: #fef9e9;
  border: 6px solid #f5da86; }

.message-error {
  background: #efc2c2d6;
  border: 6px solid #ad5d5d; }
