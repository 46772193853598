.post-slider {
    margin-bottom: 60px;
    max-width: 1300px;
    width: 100%;

    .post-slide {
        height: 420px;
        position: relative;

        width: 100%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 23px;
            filter: brightness(0.7);
            object-fit: cover;
        }

        .post-info {
            position: absolute;
            bottom: 40px;
            left: 50px;
            width: 60%;

            h4 {
                margin: 0;
                color: #FFF;
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 800;
            }

            p {
                margin: 0;
                color: #FFF;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                white-space: pre-wrap;
            }
        }

    }

    .slick-list {
        padding: 0 0 20px;
    }

    .slick-dots {
        bottom: -30px !important;

        li {
            font-size: 64px;

            button {
                &:before {
                    font-size: 20px;
                    color: #ccc;
                }
            }
    
            &.slick-active {
                button {
                    &:before {
                        color: #FE7800;
                    }
                }
            }
        }   
    }

    @media(max-width: 750px) {
        padding: 0;

        .post-slide {
            height: 320px;

            .post-info {
                left: 25px;
                bottom: 20px;
                width: 80%;

                h4 {
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        .slick-dots {
            li {
                margin: 0 1px;

                button {
                    &::before {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .lazy-placeholder {
        border-radius: 23px;
    }

    @media(max-width: 450px) {
        .post-slide {
            height: 250px;
        }
    }
}
