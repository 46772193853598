@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--effect-message {
  max-width: 92%;
  max-height: 354px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  padding: 15px;
  z-index: 1;
  background-color: var(--c-primary);
  opacity: .98; }
  @media (min-width: 750px) {
    .template--6--effect-message {
      max-width: 459px;
      position: absolute;
      bottom: -30px;
      left: 0px;
      margin: 0 0;
      padding: 35px; } }
  .template--6--effect-message .subtitle {
    font-size: 13px;
    font-weight: 900;
    color: white;
    text-align: start;
    text-transform: uppercase;
    line-height: 23px; }
    @media (min-width: 750px) {
      .template--6--effect-message .subtitle {
        font-size: 16px;
        line-height: 23px; } }
  .template--6--effect-message .autor {
    margin: 18px 0 0; }
    .template--6--effect-message .autor span {
      position: relative;
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: white; }
      .template--6--effect-message .autor span:before {
        content: '';
        width: 70px;
        height: 1px;
        position: absolute;
        right: -80px;
        top: 50%;
        background: var(--c-primary);
        filter: brightness(0.6);
        transform: translateY(-50%); }
        @media (min-width: 750px) {
          .template--6--effect-message .autor span:before {
            right: -100px; } }
