@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--biography .biography-info {
  width: 100%;
  display: flex;
  padding: 30px 0;
  color: #696969; }
  @media (min-width: 990px) {
    .template--6--biography .biography-info {
      width: calc(100% - 700px);
      min-width: 400px;
      padding: 30px; } }
  .template--6--biography .biography-info p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    text-align: left; }
    @media (max-width: 990px) {
      .template--6--biography .biography-info p {
        text-align: center; } }
