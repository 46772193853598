@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--videos {
  padding: 40px 0 100px;
  background: linear-gradient(to bottom, #fbf9f9 40%, white 60%); }
  .template--6--videos .flex-carousel-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }

.template--6--videos-container {
  width: 1440px;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto; }
  .template--6--videos-container .carousel-main .slick-slide {
    padding: 0; }
    .template--6--videos-container .carousel-main .slick-slide:hover, .template--6--videos-container .carousel-main .slick-slide:focus, .template--6--videos-container .carousel-main .slick-slide:active {
      outline: none; }

/* Slider */
.generic--slider {
  width: 100%;
  padding: 0; }
  .generic--slider .slider-wrapper {
    width: 90%; }
  .generic--slider .slick-list {
    width: 100%; }
  .generic--slider .generic--slider-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center; }
    @media (max-width: 850px) {
      .generic--slider .generic--slider-item {
        flex-flow: column wrap; } }
  .generic--slider .slick-dots li button:before {
    color: var(--c-primary);
    filter: brightness(0.8); }
