@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.header-firsthalf {
  width: 100%;
  position: relative;
  clip-path: ellipse(1920px 63% at 50% 163px);
  z-index: 1; }
  @media (max-width: 860px) {
    .header-firsthalf {
      clip-path: none;
      z-index: unset; } }
  .header-firsthalf .social-line {
    width: 100%;
    margin-top: 30px;
    padding: 0px 50px; }
    @media (max-width: 860px) {
      .header-firsthalf .social-line {
        display: none; } }
    .header-firsthalf .social-line .fa {
      padding: 0px 0px 0px 15px;
      font-size: 16px; }
      .header-firsthalf .social-line .fa:hover {
        color: #fff; }
    .header-firsthalf .social-line p {
      display: none; }

.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 5px solid var(--c-primary); }

.header-content-main {
  z-index: 1000; }

.header-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important; }
  .header-image:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }

.button-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  cursor: pointer; }
  @media (max-width: 860px) {
    .button-block {
      padding-bottom: 60px; } }

.main-phrase-container {
  position: relative;
  padding: 100px 0 120px;
  z-index: 1; }

.main-phrase {
  width: 60%;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: #fff; }
  @media (max-width: 860px) {
    .main-phrase {
      width: 90%; } }
  .main-phrase small {
    background-color: var(--c-primary) !important;
    text-transform: capitalize; }
  .main-phrase .character-count {
    width: 100%; }
  .main-phrase blockquote {
    max-width: 100%;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    word-break: break-word; }
    @media (max-width: 860px) {
      .main-phrase blockquote {
        -webkit-margin-after: 0.3em;
        -webkit-margin-before: 0.3em;
        -webkit-margin-start: 10px;
        -webkit-margin-end: 10px;
        font-size: 1.3em;
        line-height: 1.5em;
        text-overflow: ellipsis; } }
    @media (max-width: 320px) {
      .main-phrase blockquote {
        font-size: 1.2em;
        line-height: 1.5em; } }

#mainPhrase {
  width: 800px;
  max-width: 100%;
  margin: 0px;
  height: 90px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1em;
  color: #fff;
  transition: all .5s; }
  #mainPhrase:focus {
    outline: none;
    border: 1px solid var(--c-primary); }
  #mainPhrase:hover {
    border-radius: 0px;
    border: 1px solid #f57010;
    transition: all .5s; }
  @media (max-width: 860px) {
    #mainPhrase {
      width: 100%;
      margin: 0%;
      font-size: 1em; } }

.main-phrase-author input {
  width: 50%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-weight: bold; }
  .main-phrase-author input:hover, .main-phrase-author input:visited {
    outline: 1px solid var(--c-primary);
    border: 1px solid var(--c-primary); }

.main-phrase-name {
  bottom: 30px;
  margin-bottom: 40px;
  margin-top: 30px;
  display: block;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #fff; }
