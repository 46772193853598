.site-example {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 50px;
  padding: 50px 20px 35px;
  border: 3px solid #15161B;
  border-bottom: none;
  border-radius: 28px 28px 0 0;
  width: 90%;
  max-width: 400px;
  flex-grow: 1; }
  .site-example .coach-img {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%; }
  .site-example .burger-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25px;
    height: 20px; }
    .site-example .burger-menu .burger-layer {
      width: 100%;
      border: 2px solid #15161B;
      border-radius: 8px; }
  .site-example .site-logo {
    width: 30px;
    height: 30px; }
    .site-example .site-logo rect {
      fill: white; }
    .site-example .site-logo path {
      fill: #FE7800; }
  .site-example .left-info, .site-example .right-info {
    z-index: 1; }
  .site-example .left-info {
    width: 50%; }
    .site-example .left-info .welcome-paragraph {
      margin-top: 10px;
      width: 65%;
      font-size: 11px;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif; }
    .site-example .left-info .transformation-paragraph {
      margin-top: 5px;
      width: 90%;
      font-size: 16px;
      font-weight: 800;
      font-family: 'Montserrat', sans-serif; }
  .site-example .right-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%; }
    .site-example .right-info .coach-name {
      font-size: 12px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif; }
    .site-example .right-info .black-stripe {
      margin-top: 5px;
      height: 10px;
      width: 30%;
      background-color: #15161B;
      border-radius: 5px; }
    .site-example .right-info .fake-btn {
      margin: 10px 0 4px;
      height: 20px;
      width: 60%;
      border-radius: 2px;
      background: linear-gradient(211.41deg, #3D85D5 18.95%, #3D85D5 18.96%, #545BD9 52.93%); }
  @media (min-width: 768px) {
    .site-example {
      align-items: start;
      max-width: unset;
      padding-top: 20%; }
      .site-example .burger-menu {
        top: 30px;
        right: 35px;
        width: 30px;
        height: 25px; }
      .site-example .coach-img {
        width: 90%;
        max-width: 550px; }
      .site-example .left-info, .site-example .right-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 35%; }
      .site-example .left-info .welcome-paragraph {
        font-size: clamp(12px, 0.8vw, 16px); }
      .site-example .left-info .transformation-paragraph {
        font-size: clamp(18px, 1.1vw, 22px); }
      .site-example .right-info .coach-name {
        font-size: 16px; }
      .site-example .right-info .black-stripe {
        height: 15px;
        width: 40%;
        max-width: 120px; }
      .site-example .right-info .fake-btn {
        height: 35px;
        max-width: 150px; }
      .site-example .site-logo {
        width: 60px;
        height: 60px; }
        .site-example .site-logo rect {
          fill: white; }
        .site-example .site-logo path {
          fill: #FE7800; } }
  @media (min-width: 860px) {
    .site-example {
      width: 80%;
      margin-top: 120px; } }
