@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.main-content {
  width: 100%; }
  .main-content iframe {
    max-width: 100%; }
  .main-content .help-main {
    height: 100%; }

.header-content {
  width: 100%;
  height: 200px;
  position: relative;
  background: url("../../img/bg-help.jpg") no-repeat center;
  background-size: cover;
  border-bottom: 5px solid #b78e45; }
  .header-content .container {
    height: 100%; }
  .header-content h1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0%;
    padding: 20px 40px;
    margin: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
    color: #fff;
    text-align: center;
    text-transform: uppercase; }
    .header-content h1 svg {
      width: 40px;
      height: 40px;
      margin-bottom: 8px; }
      @media (min-width: 600px) {
        .header-content h1 svg {
          margin-right: 17px;
          margin-bottom: 0; } }
    @media (min-width: 600px) {
      .header-content h1 {
        flex-flow: row wrap;
        font-size: 40px; } }

.content-all {
  margin-top: 40px; }

.video-content,
.pdf-content {
  margin-top: 50px; }
  .video-content .container,
  .pdf-content .container {
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center; }
  .video-content h1,
  .pdf-content h1 {
    margin: 0 0 0px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
    color: #424242;
    text-align: center;
    text-transform: uppercase; }

.video-all {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
  @media (max-width: 800px) {
    .video-all {
      flex-flow: column nowrap; } }

.video-content {
  position: relative; }
  .video-content h1 {
    width: 400px;
    height: 430px;
    padding: 0 40px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: rgba(183, 142, 69, 0.5); }
    @media (max-width: 800px) {
      .video-content h1 {
        width: 100%;
        height: auto;
        padding: 40px 0;
        background: transparent; } }
  .video-content em {
    display: block;
    font-size: 14px;
    font-style: normal; }
    .video-content em:after {
      content: '';
      width: 50px;
      height: 6px;
      display: block;
      margin: 20px auto 0;
      background: #b78e45; }
  .video-content iframe {
    margin-left: -40px; }
    @media (max-width: 800px) {
      .video-content iframe {
        margin-left: 0px; } }

.pdf-all {
  width: 100%;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .pdf-all iframe {
    margin-right: -40px; }
    @media (max-width: 800px) {
      .pdf-all iframe {
        margin-right: 0; } }
  @media (max-width: 800px) {
    .pdf-all {
      flex-flow: column nowrap; } }

.title-pdf {
  width: 400px;
  height: 430px;
  padding: 0 40px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba(183, 142, 69, 0.5); }
  .title-pdf h1:after {
    content: '';
    width: 50px;
    height: 6px;
    display: block;
    margin: 20px auto 0;
    background: #b78e45; }
  @media (max-width: 800px) {
    .title-pdf {
      width: 100%;
      height: auto;
      padding: 40px 0;
      background: transparent; } }

.btn-download {
  width: 250px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  transition: all .5s ease-in-out; }
  .btn-download svg {
    width: 25px;
    height: 25px;
    margin-right: 10px; }
    .btn-download svg path {
      fill: #fff;
      transition: all .5s ease-in-out; }
      @media (max-width: 800px) {
        .btn-download svg path {
          fill: #b78e45; } }
  .btn-download:hover {
    color: #b78e45; }
    .btn-download:hover svg path {
      fill: #b78e45; }
  @media (max-width: 800px) {
    .btn-download {
      color: #b78e45; } }

.pdf-content {
  padding-bottom: 100px; }
  .pdf-content .container {
    flex-flow: row-reverse wrap; }
  @media (min-width: 600px) {
    .pdf-content {
      margin-top: 80px; } }

.footer-content {
  padding: 20px;
  background: #424242;
  font-family: "Nunito Sans", sans-serif; }
  .footer-content a {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    color: #fff;
    text-align: center; }
    .footer-content a b {
      font-size: 30px; }
