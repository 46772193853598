.collapse-wrapper {
  width: 100%;
  margin-bottom: 10px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid none;
  transition: all 1s; }

.collapse-header {
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  color: #505152;
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: bold; }
  .collapse-header span, .collapse-header .fa {
    font-size: inherit; }
  .collapse-header .fa {
    transition: all 1s; }

.content-wrapper.close,
.content-wrapper.close .images-container {
  height: 0px;
  overflow: hidden;
  opacity: 0; }

.content-wrapper.close {
  transition: 1s all; }
  .content-wrapper.close .images-container {
    transition: 1.5s all; }

.content-wrapper.open {
  height: fit-content;
  min-height: 151px;
  padding: 2px;
  margin-top: 5px;
  overflow: inherit;
  border-radius: 4px;
  opacity: 1 !important; }
  .content-wrapper.open .images-container {
    width: 100%;
    margin: 10px 0px;
    overflow: inherit;
    opacity: 1 !important;
    transition: .8s all; }
