.coach-item {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 75%;
  height: 100%;
  max-width: calc(140px * 2);
  min-height: calc(140px * 3);
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  justify-content: flex-end;
  filter: brightness(0.9);
  cursor: pointer;
  margin: 0 10px 30px; }
  .coach-item .wrapper-filter {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(0deg, #000000 -5.54%, rgba(0, 0, 0, 0) 101.7%);
    border-radius: 9px; }
  .coach-item .coach-info {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 20000;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px; }
    .coach-item .coach-info .text-info {
      color: #FFFFFF;
      font-size: 1.3rem;
      font-family: 'Montserrat';
      font-style: normal;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px; }
      .coach-item .coach-info .text-info:nth-child(1), .coach-item .coach-info .text-info:nth-child(2) {
        font-size: 1rem;
        font-weight: 800; }
    .coach-item .coach-info .know-more {
      color: #fff;
      font-size: 0.9rem;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 700; }
    .coach-item .coach-info .rating-stars {
      margin-bottom: 10px; }
  @media (min-width: 750px) {
    .coach-item {
      margin: 0 20px 40px 0; } }
  @media (min-width: 170px) {
    .coach-item {
      width: 90%; }
      .coach-item .coach-info .text-info:nth-child(1), .coach-item .coach-info .text-info:nth-child(2) {
        font-size: 1.9rem; } }

.infinite-scroll-component__outerdiv {
  width: 93%; }

.infinite-scroll-coaches-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center; }
  @media (min-width: 1000px) {
    .infinite-scroll-coaches-list {
      justify-content: flex-start; } }

.container-flag {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.container-single-flag {
  display: flex; }

.flag {
  display: flex;
  align-items: center;
  background: #FE7800;
  border-radius: 50px;
  padding: 5px;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  margin-bottom: 10px; }
  .flag span {
    font-size: 0.7rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    width: 100%;
    text-align: start;
    margin: 0 10px; }
