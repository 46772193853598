.blog-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 100vh;

    .new-home-footer {
        background-color: #0C1019;
    }
}