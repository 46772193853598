.published-articles-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5%;

    .published-articles-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0px 29px 0px;

        width: 100%;
        height: 55px;

        h1 {
            font-family: Montserrat, sans-serif;
            font-size: 32px;
            font-weight: 700;
            color: #FE7800;
            
        }

        span {
            color: #fff;
        }

    }
    
   .published-articles-cards {
        width: 100%;
        
        .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slick-dots {
            li {
                font-size: 64px;
    
                button {
                    &:before {
                        font-size: 20px;
                        color: #ccc;
                    }
                }
        
                &.slick-active {
                    button {
                        &:before {
                            color: #FE7800;
                        }
                    }
                }
            }   
        }
    }

    .published-articles-see-more {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 60px;
        margin-bottom: 40px;

        span {
            display: flex;
            width: 260px;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            color: var(--cor-select-4, #FBFBFB);
            text-align: center;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .published-articles-see-more::before {
        content: ' ';
        width: 100%;
        height: 2px;
        margin-bottom: 24px;
        margin-top: 5px;

        background-color: #FE7800;
    }

    @media (max-width: 760px) {
        .published-articles-title {
            h1 {
                font-size: clamp(18px, 5.3vw, 28px);
                text-align: center;
            }
        }
    }
}