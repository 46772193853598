.check-in-header {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  background-color: transparent;
  width: 100%; }
  .check-in-header .btn-back-form {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    margin: 30px 20px;
    cursor: pointer; }
    .check-in-header .btn-back-form:hover {
      transform: scale(1.1); }
    @media (min-width: 769px) {
      .check-in-header .btn-back-form {
        display: none; } }
  .check-in-header .btn-back-form-none {
    display: none; }
  .check-in-header .logo-oseu-coach .highlight {
    fill: black; }
  .check-in-header .logo-oseu-coach-margin {
    margin-top: 10px; }
    .check-in-header .logo-oseu-coach-margin .highlight {
      fill: #FE7800; }
  .check-in-header .select-wrapper-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 92px;
    min-width: 90px;
    margin-left: 4px;
    margin-bottom: 3px; }
  @media (min-width: 769px) {
    .check-in-header {
      padding: 10px 0 0 25px;
      justify-content: start; }
      .check-in-header .logo-oseu-coach .highlight {
        fill: #FE7800; }
      .check-in-header .select-wrapper-container {
        top: 10px;
        right: 8px; } }
