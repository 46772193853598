.blog-post-content-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .blog-header {
        background-color: white;
        width: 100%;
        padding: 20px calc(15% + 20px) 40px;

        .logo-oseu-coach {
            display: flex;
    
            svg path{
                fill: black;
            }
    
            .highlight {
                fill: #FE7800
            }
        }

        .icon-burger {
            rect {
                fill: black;
            }
        }

        .mobile-menu-open {
            li {
                margin-left: 0;
            }

            .icon-burger {
                rect {
                    fill: white;
                }
            }

            .navs-group ul {
                .menu-item {
                    &.active {
                        font-weight: 400;
                        color: white;
                    }
                }
            }

            .icon-menu-item {
                path {
                    stroke: #000;
                }
            }
        }

        @media (max-width: 900px) {
            padding: 20px calc(10% + 20px) 40px;
        }
    
        @media (max-width: 750px) {
            height: 66px;
            padding: 16px 13px;
            display: flex;
            align-items: center;

            .new-header-container-image {
                align-items: center;

                .logo-oseu-coach {
                    width: 156px;
                    height: 27px;
                }
            }

            .menu-hamburger, .icon-burger {
                top: 3px;
            }
        }
    }

    .img-container {
        width: 100%;
        height: 200px;
    }

    .new-home-footer {
        background-color: #0C1019;
    }
}

.blog-post-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 675px;
    width: 100%;
    
    padding: 0 20px;
    margin: auto;
    background-color: #FFF;

    color: #59575B;
    font-family: Montserrat;

    .blog-post-content-body {
        p {
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            margin: 10px 0;
        }
    
        img {
            width: 100%;
            border-radius: 6px;
            margin: 10px 0;
            object-fit: cover;
        }
    
        a {
            font-family: Montserrat;
            font-weight: 600;
            text-align: left;
            color: #59575B;
    
            &:hover {
                color: #FE7800;
    
                &::after {
                    background-image: url('../../../../img/icon-link-hover.svg');
                }
    
            }
        }
    
        a::after {
            position: relative;
            display: inline-flex;
            content: " ";
            background-image: url('../../../../img/icon-link.svg');
            background-size: cover;
            width: 15px;
            height: 15px;
            left: 2px;
            top: 2px;
        }
    
        h1 {
            font-family: Montserrat;
            text-align: left;
            color: #000000;
            margin: 10px 0;
            padding: 0;
        }
    
        h2, h3, h4 {
            font-family: Montserrat;
            text-align: left;
            color: #000000;
            margin: 5px 0;
            padding: 0;
        }
    
        ol, ul {
            display: block;
            list-style-type: decimal;
            margin: 10px 0;
            padding-inline-start: 40px;
        }
    
        ul, ol {
            li {
                margin: 10px 0;
            }
        }
    
        ul {
            li{
                list-style-type: circle;
            } 
        }
    
        ol {
            li{
    
                list-style-type: auto;
            } 
        }
    
        .blog-post-title {
            width: 100%;
    
            h1 {
                max-width: 481px;
                width: 100%;
        
                font-family: Montserrat;
                font-size: 30px;
                font-weight: 700;
                line-height: 37px;
            }
        }
    
        .blog-post-description {
            max-width: 675px;
            width: 100%;
            flex-shrink: 0;
    
            color: #59575B;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
        }
    
        .richtext-image.left {
            align-self: flex-start;
            max-width: 80%;
        }
    
        .richtext-image.right {
            align-self: flex-end;
            max-width: 80%;
        }
    
        .richtext-image.full-width {
            align-self: flex-end;
            width: 100%;
        }
    
        blockquote {
            text-indent: 0px;
            border-left: 3px solid #FE7800;;
            padding: 0 10px;
    
            color: #59575B;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;
        }
    
        .bottom-line {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
           
            hr {
                border: solid 2px #FE7800;
            }
    
            .post-info-section {
                display: flex;
                justify-content: center;
                align-items: center;
            }
    
            .post-info {
                justify-content: center;
            }
        }
    }


    .lazy-placeholder {
        border-radius: 6px;
    }

    @media(max-width: 1000px) {
        width: 65%;
    }

    @media(max-width: 760px) {
        width: 80%;
    }

    @media(max-width: 560px) {
        width: 90%;

        blockquote {
            margin: 15px 20px;
        }
    }

    @media(max-width: 400px) {
        width: 100%;
    }
}

.skelton-loading {
    display: flex;
    max-width: 675px;
    margin: auto;
}