.category-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, #15161b 60%, transparent 60%); }
  .category-section .category-section-title {
    position: absolute;
    top: -20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    color: white;
    margin-bottom: 0; }
    .category-section .category-section-title span {
      color: #FE7800;
      font-weight: 800; }
  @media (max-width: 750px) {
    .category-section {
      padding: 30px 0; }
      .category-section .category-section-title {
        position: static;
        font-size: clamp(18px, 6vw, 28px); } }

.category-carousel {
  width: 100%;
  height: 1000px; }
  .category-carousel .slick-slider {
    height: 100%; }
  .category-carousel .slick-dots {
    top: 80%; }
    .category-carousel .slick-dots li button:before {
      font-size: 20px;
      color: #ccc; }
    .category-carousel .slick-dots li.slick-active button:before {
      color: #FE7800; }
  @media (max-width: 750px) {
    .category-carousel .slick-dots {
      top: unset;
      bottom: 10%; } }
  @media (max-width: 420px) {
    .category-carousel .slick-dots {
      bottom: 5%; } }
  @media (max-width: 320px) {
    .category-carousel .slick-dots {
      bottom: 0%; } }

.category-card {
  position: relative;
  padding: 20px 30px;
  background-color: #FE7800;
  border-radius: 20px;
  box-shadow: 0 -15px 88px 0px #fe7800;
  margin: 12% auto;
  width: 60%;
  max-height: 660px; }
  .category-card .category-name {
    margin: 0;
    font-size: clamp(80px, 6vw, 96px);
    color: white;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif; }
  .category-card .category-text {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: clamp(28px, 2vw, 32px);
    width: 60%; }
  .category-card .category-img {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translateY(-50%);
    width: clamp(240px, 15vw, 350px); }
  @media (max-width: 1000px) {
    .category-card {
      width: 75%; } }
  @media (max-width: 750px) {
    .category-card {
      width: 95%;
      max-height: 90vh;
      box-shadow: 0 32px 60px 0 #fe7800; }
      .category-card .category-name {
        font-size: 46px; }
      .category-card .category-text {
        font-size: 18px;
        width: 100%; }
      .category-card .category-img-container {
        position: relative;
        height: 300px; }
        .category-card .category-img-container .category-img {
          top: 5%;
          left: 50%;
          width: 200px;
          transform: translateX(-50%); } }
  @media (max-width: 350px) {
    .category-card {
      width: 100%; }
      .category-card .category-name {
        font-size: 48px; } }
