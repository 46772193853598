@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--6--navbar .header--menu-mobile {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: var(--c-primary); }

.template--6--navbar .close-btn {
  width: 35px;
  height: 5px;
  border-radius: 5px;
  background-color: white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: flex;
  position: relative;
  left: 40%; }
  .template--6--navbar .close-btn:before, .template--6--navbar .close-btn:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: #fff;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    transform: rotate(90deg); }
  .template--6--navbar .close-btn:before {
    border-radius: 5px; }
  .template--6--navbar .close-btn:after {
    border-radius: 5px; }

.template--6--navbar .header--menu-mobile-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }
  .template--6--navbar .header--menu-mobile-container ul li {
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
    color: white; }
    .template--6--navbar .header--menu-mobile-container ul li:hover {
      transform: scale(1.1); }

.template--6--navbar .phone {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
  color: white; }
  .template--6--navbar .phone svg {
    margin-right: 10px; }
