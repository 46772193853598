.main-container-card {
  width: calc(95% / 4);
  height: 140px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 14px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important; }
  .main-container-card:hover .transparent-wrapper {
    opacity: 1; }
    .main-container-card:hover .transparent-wrapper > .sign-container {
      width: 85% !important;
      height: 85% !important; }
      .main-container-card:hover .transparent-wrapper > .sign-container .fa {
        opacity: 1;
        transform: scale(1); }
  @media (max-width: 990px) {
    .main-container-card {
      width: calc( 95% / 3); } }
  @media (max-width: 768px) {
    .main-container-card {
      width: calc( 95% / 2); } }
  @media (max-width: 550px) {
    .main-container-card {
      width: 95%; } }

.transparent-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 1.5s all; }
  .transparent-wrapper > .sign-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    opacity: 1;
    transition: 1s all; }

.sign-container .fa {
  opacity: 0;
  color: #F17127;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  transform: scale(2);
  transition: 1s;
  transition-delay: .5s; }
