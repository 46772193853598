// VARIABLES //
$c-white: #fff;
$c-black: #000;
$c-gold: #A98446;
$c-grey: #EBEBEB;
$c-text-blue: #696969;
$c-border: #eee;

// ** Container
$widthContainer: 1400px;

// MIXINS //

// ** Display Flex
@mixin displayFlex($direction, $wrap, $justify, $item, $content) {
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $item;
    align-content: $content;
}

// ** Font

@mixin fontDisplay($family, $size, $weight,$color) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

// ** Animation 
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content; 
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    } 
  }


// ** Fonts
@import url('https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap');

$f-cabin: 'Cabin', sans-serif;
$f-nunito: 'Nunito Sans', sans-serif;

.is-mobile {
  height: calc(100vh - 38%) !important;
}