@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7--biography .biography-info {
  width: 100%;
  display: flex;
  padding: 0px 0 30px;
  color: #4E4E4E; }
  .template--7--biography .biography-info p {
    margin: 0;
    width: 100%;
    font-size: 18px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: normal;
    text-align: center; }
