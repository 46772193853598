@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.curriculum-info {
  width: 50%;
  margin: 0 auto;
  display: flex;
  padding: 0 30px;
  color: #696969; }
  .curriculum-info p {
    margin: 0;
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    color: #4E4E4E;
    text-align: center; }
