.no-posts-found {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 30px 80px;

    p {
        color: #030303;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;    
    }

    img {
        margin: 20px 0 80px;
    }

    button {
        width: 100%;
        height: 55px;

        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;

        border-radius: 23px;
        background-color: #FE7800;

        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    @media(max-width: 560px) {
        padding: 25px 30px 40px;

        img {
            margin: 10px 0 40px;
        }
    }
}