/* lib slide */
.carousel.carousel-slider {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: row wrap;
  align-items: center; }

.carousel .slide {
  background: none !important; }
  .carousel .slide img {
    width: auto !important; }

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  padding: 10px; }
