@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--5--videos {
  position: relative;
  padding-bottom: 40px; }
  .template--5--videos::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(to bottom, var(--c-primary) 40%, white 60%);
    opacity: 0.15; }
  .template--5--videos .flex-carousel-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }

.template--5-videos-container {
  padding: 20px 0;
  position: relative;
  z-index: 1; }

.template--5-videos-content {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem; }
  .template--5-videos-content .slick-list {
    width: 100%; }
  .template--5-videos-content .generic--slider .slick-dots li button:before {
    color: var(--c-primary); }

/* Slider */
.generic--slider {
  width: 100%; }
  .generic--slider .generic--slider-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center; }
    @media (max-width: 500px) {
      .generic--slider .generic--slider-item {
        flex-flow: column wrap; } }
  .generic--slider .carousel-slider {
    padding-bottom: 70px; }

.carousel-main .slick-dots {
  bottom: -40px; }

.carousel-main .slick-slide {
  padding: 0 20px; }
