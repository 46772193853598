.description-container {
  display: block;
  width: auto; }
  @media (min-width: 769px) {
    .description-container {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column; } }
  @media (min-width: 900px) {
    .description-container {
      width: 600px; } }

.description-title {
  width: auto; }
  @media (min-width: 769px) {
    .description-title {
      width: 510px; } }

h5 {
  margin: 0;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 400; }

.form-description {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px; }
  .form-description .input-description {
    border: 1px solid #FE7800;
    border-radius: 5px; }
    .form-description .input-description label {
      left: 0px;
      padding: 10px;
      margin: 0;
      color: #FFFFFF;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: start; }
      .form-description .input-description label span {
        color: #FFFFFF; }
    .form-description .input-description span.error {
      color: #e60808;
      font-size: 12px;
      font-family: "Montserrat";
      font-family: "Montserrat";
      font-weight: 500;
      position: initial; }
    .form-description .input-description textarea {
      height: 200px;
      padding: 10px;
      min-height: 130px;
      color: #FFFFFF;
      font-family: "Montserrat";
      background: transparent;
      border: 1px solid #FE7800;
      outline: none; }
  .form-description .error {
    border: 1px solid #ff0000; }
    .form-description .error:hover {
      border-color: #ff0000; }
    .form-description .error textarea {
      border: 1px solid #ff0000; }
      .form-description .error textarea:hover {
        border-color: #ff0000; }
