@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.template--7-testimonial {
  width: 1440px;
  max-width: 100%;
  padding: 1rem 1rem 10px 1rem;
  margin: auto;
  padding-bottom: 100px;
  position: relative;
  background: #FCFCFC; }
  .template--7-testimonial .testimonial-icon-1 {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -30px;
    left: 0;
    transform: rotate(22deg);
    opacity: .37; }
  .template--7-testimonial .testimonial-icon-2 {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: -10px;
    right: 0;
    transform: rotate(-199deg);
    opacity: .37; }

.template--7-testimonial-container {
  width: 85%;
  margin: 0 auto;
  position: relative; }
  @media (min-width: 650px) {
    .template--7-testimonial-container {
      width: 70%; } }
  .template--7-testimonial-container .generic--slider {
    padding: 0; }
  .template--7-testimonial-container .testimonial-text {
    font-size: 18px;
    color: #6F6F6F;
    text-align: center; }
  .template--7-testimonial-container .title-section-content-6 {
    margin-bottom: 10px; }
  .template--7-testimonial-container .author,
  .template--7-testimonial-container .author-work {
    margin: 0;
    font-family: "Cabin", sans-serif;
    font-style: italic;
    font-size: 18px; }
  .template--7-testimonial-container .carousel-main .slick-slide {
    padding: 0px; }
  .template--7-testimonial-container .slick-list {
    padding: 0; }
  .template--7-testimonial-container .arrowPrev,
  .template--7-testimonial-container .arrowNext {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
  .template--7-testimonial-container .arrowPrev {
    left: -20px; }
    @media (min-width: 500px) {
      .template--7-testimonial-container .arrowPrev {
        left: -50px; } }
    .template--7-testimonial-container .arrowPrev svg {
      transform: translateY(-50%) rotate(90deg); }
  .template--7-testimonial-container .arrowNext {
    right: -20px; }
    @media (min-width: 500px) {
      .template--7-testimonial-container .arrowNext {
        right: -50px; } }
    .template--7-testimonial-container .arrowNext svg {
      transform: translateY(-50%) rotate(-90deg); }
