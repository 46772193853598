@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

.placeholder-no-content {
  min-width: 50%;
  max-width: 90%;
  width: auto;
  height: 250px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  margin-bottom: 25px;
  border-top: 15px solid #4F4F50;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  border-radius: 5px; }
  .placeholder-no-content span {
    margin: 5px;
    text-align: center;
    color: #4F4F50;
    font-size: 22px;
    font-weight: 300;
    font-family: "Nunito Sans", sans-serif; }
  @media (max-width: 768px) {
    .placeholder-no-content {
      width: 80%; } }

.icon-alert-placeholder {
  width: 28px;
  height: 28px;
  display: block;
  background: url("../../../img/icon-alert-2.png");
  background-size: cover; }
