.input-wrapper-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; }
  .input-wrapper-container label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 20px;
    color: #FFFFFF;
    position: relative;
    left: 5px;
    cursor: pointer;
    margin-top: 5px;
    cursor: auto; }
    .input-wrapper-container label span {
      color: #FE7800; }
  .input-wrapper-container span.error {
    display: flex;
    position: absolute;
    color: #e60808;
    width: 100%;
    align-items: center;
    font-size: 0.7rem;
    justify-content: flex-end;
    padding: 5px;
    top: 65px;
    cursor: auto;
    font-weight: 700; }
