.featured-coaches-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .featured-coaches-section::before {
    content: '';
    position: absolute;
    top: 35%;
    width: 70%;
    height: 30%;
    border-radius: 200px;
    filter: blur(50px);
    box-shadow: 0 25px 50px 50px #fe7900;
    background-color: #FE7800; }
  .featured-coaches-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: #15161B;
    filter: opacity(0.6); }
  .featured-coaches-section .featured-coaches-header {
    display: flex;
    align-items: center;
    justify-content: center; }
    .featured-coaches-section .featured-coaches-header .icon-user {
      margin-right: 10px; }
    .featured-coaches-section .featured-coaches-header .featured-coaches-title {
      color: white;
      font-size: 48px;
      font-family: 'Montserrat', sans-serif; }
      .featured-coaches-section .featured-coaches-header .featured-coaches-title span {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #FE7800; }
  @media (max-width: 750px) {
    .featured-coaches-section .featured-coaches-header .icon-user {
      width: clamp(32px, 9vw, 45px); }
    .featured-coaches-section .featured-coaches-header .featured-coaches-title {
      font-size: clamp(18px, 6vw, 28px); } }

.featured-coaches-carousel {
  margin-bottom: 120px;
  margin: 120px 0;
  width: 100%;
  margin-bottom: 120px;
  width: 90%;
  z-index: 2; }
  .featured-coaches-carousel .coach-item {
    margin: 0 auto; }
  .featured-coaches-carousel .slick-dots li {
    font-size: 64px; }
    .featured-coaches-carousel .slick-dots li button:before {
      font-size: 20px;
      color: #ccc; }
    .featured-coaches-carousel .slick-dots li.slick-active button:before {
      color: #FE7800; }
  @media (max-width: 750px) {
    .featured-coaches-carousel .slick-dots li {
      margin: 0 1px; }
      .featured-coaches-carousel .slick-dots li button::before {
        font-size: 16px; } }

.stats-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 50px;
  width: 80%;
  z-index: 2; }
  .stats-list .stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .stats-list .stats-item .stats-text {
      font-family: 'Montserrat', sans-serif;
      font-size: clamp(16px, 1.4vw, 20px);
      color: white;
      margin: 0; }
    .stats-list .stats-item .stats-value {
      font-family: 'Montserrat', sans-serif;
      font-size: clamp(50px, 4vw, 64px);
      font-weight: bold;
      color: white;
      margin: 0; }
    .stats-list .stats-item span {
      font-family: 'Montserrat', sans-serif;
      font-size: 64px;
      font-weight: bold;
      color: #FE7800; }
  @media (max-width: 1000px) {
    .stats-list {
      width: 100%; } }
  @media (max-width: 750px) {
    .stats-list {
      flex-direction: column; }
      .stats-list .stats-item {
        margin-bottom: 30px; }
        .stats-list .stats-item .stats-text {
          font-size: 12px; }
        .stats-list .stats-item .stats-value {
          font-size: 46px; }
        .stats-list .stats-item span {
          font-size: 46px; } }

.slick-slider {
  display: block; }
