.answer-container {
  width: auto; }
  @media (min-width: 769px) {
    .answer-container {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column; } }
  @media (min-width: 900px) {
    .answer-container {
      width: 600px; } }

title {
  display: flex;
  flex-direction: column;
  color: #FFFFFF; }

@media (max-width: 769px) {
  .answer-title {
    width: 230px; } }

h1, h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  text-align: start;
  left: 0px;
  padding: 5px 0px;
  color: #FFFFFF; }

h1 {
  font-size: 20px;
  margin: 0; }

.answer-title h5 {
  margin: 0px 0px 20px 0px;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 400; }

.input-contat label {
  color: #FFFFFF;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  left: 0px;
  padding: 5px 0px; }
  .input-contat label span {
    color: #FFFFFF; }

.input-contat span.error {
  color: #e60808;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 500;
  position: initial; }

.input-contat textarea {
  font-family: "Montserrat";
  background: transparent;
  border: 1px solid #FE7800;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  color: #FFFFFF;
  min-height: 70px; }

.error textarea {
  border: 1px solid #ff0000; }
  .error textarea:hover {
    border-color: #ff0000; }

.btn-text-reviews {
  display: flex;
  justify-content: center; }
  @media (min-width: 769px) {
    .btn-text-reviews {
      display: flex;
      justify-content: flex-end;
      height: 50px; } }
  .btn-text-reviews .btn-review-text, .btn-text-reviews .btn-rewrite-text {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px 15px;
    margin: 8px 10px;
    border: 1px solid #FE7800;
    border-radius: 5px;
    color: #EEEEEE;
    cursor: pointer; }
    @media (min-width: 769px) {
      .btn-text-reviews .btn-review-text, .btn-text-reviews .btn-rewrite-text {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 10px 15px;
        margin-top: 10px;
        margin-left: 20px;
        border: 1px solid #FE7800;
        border-radius: 5px;
        color: #EEEEEE; } }
    .btn-text-reviews .btn-review-text:hover, .btn-text-reviews .btn-rewrite-text:hover {
      transform: scale(1.1); }

.btn-rewrite-text:disabled {
  background-color: gray;
  border-color: gray;
  cursor: not-allowed; }
  .btn-rewrite-text:disabled:hover {
    transform: scale(1); }

.description-arrow {
  color: white;
  padding: 0;
  cursor: pointer; }
  .description-arrow .chevron-icon.right {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }
  .description-arrow:disabled {
    cursor: not-allowed; }
    .description-arrow:disabled svg {
      stroke: gray; }

.version-control {
  display: flex;
  margin-top: 5px; }
  .version-control .version-number {
    display: flex;
    align-items: center;
    margin: 0 5px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px; }
