@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i&display=swap,500,600,700|Nunito+Sans:200i,300,400,600,700,800,900&display=swap");
.is-mobile {
  height: calc(100vh - 38%) !important; }

:root {
  --c-primary:#ee4569; }

* {
  font-family: "Nunito Sans", sans-serif; }

.template--6 {
  width: 100%; }
  .template--6 .btn-basic {
    width: 50%;
    margin: 20px auto;
    padding: 15px 40px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background: var(--c-primary); }
    .template--6 .btn-basic:hover {
      cursor: pointer;
      background: var(--c-primary);
      filter: brightness(0.8); }
    .template--6 .btn-basic:disabled {
      background: #ccc !important;
      cursor: not-allowed; }
  .template--6 .title-section-content {
    width: 100%;
    margin-bottom: 30px;
    color: #4e4e4e;
    font-weight: 700;
    text-align: center; }
    .template--6 .title-section-content h3 {
      margin: 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-family: "Cabin", sans-serif;
      font-weight: 600;
      font-size: 18px; }
      @media (min-width: 500px) {
        .template--6 .title-section-content h3 {
          font-size: 25px; } }
    .template--6 .title-section-content span {
      margin: 0px;
      font-family: "Nunito Sans", sans-serif;
      font-size: 30px;
      font-weight: 800;
      line-height: 30px;
      color: var(--c-primary);
      text-transform: uppercase; }
      @media (min-width: 500px) {
        .template--6 .title-section-content span {
          font-size: 45px;
          line-height: 45px; } }
  .template--6 input:-webkit-autofill,
  .template--6 input:-webkit-autofill:hover,
  .template--6 input:-webkit-autofill:focus,
  .template--6 input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important; }
