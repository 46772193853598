.container-forms {
  background-color: #15161B;
  width: 100%;
  height: 100%;
  position: fixed; }
  .container-forms .logo-oseu-coach .highlight {
    fill: #FE7800; }

.main-forms {
  display: block;
  height: calc(100% - 50px); }
  @media (min-width: 769px) {
    .main-forms {
      background-color: #15161B;
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex; } }

.step-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  min-width: 200px; }
  @media (min-width: 769px) {
    .step-form {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 60px;
      height: 100%; } }

.circle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FE7800;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  line-height: 40px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 700;
  color: #FFFF;
  cursor: pointer; }
  .circle-btn:hover {
    transform: scale(1.1); }

.central-form {
  position: relative;
  width: 100%;
  height: 90%;
  padding: 10px 20px 20px 20px;
  overflow: auto; }
  @media (min-width: 769px) {
    .central-form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 90%; } }
  .central-form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .central-form::-webkit-scrollbar {
    height: 8px;
    border-radius: 12px;
    background-color: #F5F5F5; }
  .central-form::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555; }

.forms {
  padding-bottom: 50px; }
  @media (min-width: 769px) {
    .forms {
      padding: 0;
      width: clamp(400px, 25vw, 600px); } }

.btn-back-form {
  padding: 0;
  margin: 0px 0px 30px 0px;
  cursor: pointer; }
  .btn-back-form:hover {
    transform: scale(1.1); }
  @media (max-width: 768px) {
    .btn-back-form {
      display: none; } }

.btn-final-div {
  display: flex;
  justify-content: center;
  padding-bottom: 20px; }
  @media (min-width: 769px) {
    .btn-final-div {
      display: block; } }

.btn-continue-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 30px;
  border-radius: 5px;
  margin-top: 40px;
  padding: 20px 0px;
  color: #FFFF;
  background-color: #FE7800;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 13px;
  cursor: pointer; }
  .btn-continue-form:hover {
    background-color: #d46f0a; }

.btn-final {
  margin-top: 20px; }

.btn-final:disabled, .btn-continue-form:disabled {
  background-color: gray;
  cursor: not-allowed; }
  .btn-final:disabled:hover, .btn-continue-form:disabled:hover {
    background-color: gray; }
